import React, { useState } from 'react';
import { Modal, Button, Row, Col, Image } from 'antd';
import './Modal.scss';
import PropTypes from 'prop-types';
import { BsInfoCircle } from 'react-icons/bs';

const ModalUsers = (props) => {
  const { completedprojects } = props;
  const [isModalVisible, setIsModalVisible] = useState(false);
  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <>
      <Button className="modal" type="primary" onClick={showModal} title="Подробнее">
        {/* Подробнее */}
        {/* <RiInformationLine />
        <IoIosInformationCircle /> */}
        <BsInfoCircle />
      </Button>
      <Modal
        title="Завершённый проект"
        className="admin-modal"
        open={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        cancelText="Отмена"
        okText="ОК"
      >
        <Row>
          {/* <Col span={4}>
            <div className="admin-modal-img">
              <Image
                src={completedprojects.image.replace('188.225.36.154:3500', 'апи.камень-газон.рф')}
                alt=""
              />
            </div>
          </Col> */}
          <Col span={20}>
            <div className="avatar-list">
              <ul>
                <li>
                  <b>ID:</b> {completedprojects?._id}
                </li>
                <li>
                  <b>Название:</b> {completedprojects?.name}
                </li>
                <li>
                  <b>Выполненные работы 1:</b> {completedprojects?.parametr1}
                </li>
                <li>
                  <b>Выполненные работы 2:</b> {completedprojects?.parametr2}
                </li>
                <li>
                  <b>Информация:</b> {completedprojects?.info ? completedprojects.info : '-'}
                </li>
              </ul>
            </div>
          </Col>
        </Row>
      </Modal>
    </>
  );
};

export default ModalUsers;

ModalUsers.propTypes = {
  completedprojects: PropTypes.shape({
    name: PropTypes.string.isRequired,
    categoryId: PropTypes.string.isRequired,
    parametr1: PropTypes.string.isRequired,
    parametr2: PropTypes.string.isRequired,
    image: PropTypes.string.isRequired,
  }),
};
