import React, { useEffect, useState } from "react";
import { Select } from "antd";

import "../SelectOption/SelectOption.scss";

const { Option } = Select;

const SelectOption = (props) => {
  const { data, currentId, setId, placeholder } = props;
  const [selectValue, setSelectValue] = useState(null);
  // const [categoryName, setCategoryName] = useState("");

  // useEffect(() => {
  //   if(selectValue !== null && selectValue) {
  //     setCategoryName(data.find( item => item._id === selectValue))
  //   }
  //   if(selectValue == null) {
  //     setCategoryName(data.find( item => item._id === currentId))
  //   }
  // }, [data, selectValue, currentId])

  function onChange(value) {
    setSelectValue(value);
    setId(value);
  }

  useEffect(() => {
    if (currentId) {
      setSelectValue(currentId);
    }

    if (!currentId && selectValue) {
      setSelectValue(null);
    }
  }, [currentId, selectValue]);

  // function onBlur() {}

  // function onFocus() {}

  // function onSearch(val) {}

  return (
    <div style={{ display: "flex", width: "100%" }}>
      <Select
        required={props.required}
        showSearch
        value={selectValue || currentId || placeholder}
        style={{ width: "100%" }}
        placeholder={placeholder}
        optionFilterProp="children"
        onChange={onChange}
        // onFocus={onFocus}
        // onBlur={onBlur}
        // onSearch={onSearch}
        filterOption={(input, option) =>
          option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
      >
        <Option value={""}>{placeholder}</Option>
        {data.map((item) => (
          <Option key={item._id} value={item._id}>
            {item.fullName ?? item.name ?? item.size}
          </Option>
        ))}
      </Select>
      {props.required ? <>&nbsp;✱</> : <div style={{ width: "1rem" }} />}
    </div>
  );
};

export default SelectOption;
