import React, { useState } from 'react';
import { Modal, Button, Row, Col } from 'antd';
import './Modal.scss';
import PropTypes from 'prop-types';
import { BsInfoCircle } from 'react-icons/bs';

const ModalUsers = (props) => {
  const { order } = props;
  const [isModalVisible, setIsModalVisible] = useState(false);
  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <>
      <Button className="modal" type="primary" onClick={showModal} title="Подробнее">
        {/* Подробнее */}
        {/* <RiInformationLine />
        <IoIosInformationCircle /> */}
        <BsInfoCircle />
      </Button>
      <Modal
        title="Заказ"
        className="admin-modal"
        open={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        cancelText="Отмена"
      >
        <Row>
          <Col span={24}>
            <div className="avatar-list">
              <ul>
                <li>
                  <b>ID:</b> {order.productId != null ? order.productId._id : '-'}
                </li>
                <li>
                  <b>Продукт:</b> {order.productId != null ? order.productId.name : '-'}
                </li>
                <li>
                  <b>Количество:</b> {order.count}
                </li>
                <li>
                  <b>Email:</b> {order.email}
                </li>
                <li>
                  <b>Телефон:</b> {order.phone}
                </li>
                <li>
                  <b>Упаковка:</b> {order.packaging}
                </li>
              </ul>
            </div>
          </Col>
        </Row>
      </Modal>
    </>
  );
};

export default ModalUsers;

ModalUsers.propTypes = {
  order: PropTypes.shape({
    productId: PropTypes.string.isRequired,
    count: PropTypes.number.isRequired,
    newPrice: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    phone: PropTypes.string.isRequired,
    packagingId: PropTypes.string.isRequired,
  }),
};
ModalUsers.defaultProps = {
  productId: '',
  count: '',
  newPrice: '',
  email: '',
  phone: '',
  packaging: '',
};
