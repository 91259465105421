import { Modal, Button, Space } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { deleteStock } from '../../../Api';
// import { MdDelete } from 'react-icons/md';
// import { AiOutlineDelete } from 'react-icons/ai';
import { FaTrash } from 'react-icons/fa';

const { confirm } = Modal;

export default function ModalDelet(props) {
  const { deleteData, deleteInfo, rowId } = props;

  function showDeleteConfirm() {
    confirm({
      title: 'Удалить акцию?',
      icon: <ExclamationCircleOutlined />,
      okText: 'Да',
      okType: 'danger',
      cancelText: 'Нет',
      onOk() {
        deleteStock(rowId, deleteData, deleteInfo);
      },
      onCancel() {},
    });
  }

  return (
    <Space wrap>
      <Button className="delete" onClick={showDeleteConfirm} type="dashed" title="Удалить">
        {/* Delete */}
        <FaTrash />
      </Button>
    </Space>
  );
}
