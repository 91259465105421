import React from 'react';

import { Button } from 'antd';

import { Link } from 'react-router-dom';

import { MdEdit } from 'react-icons/md';

export default function ButtonEditUser(props) {
  const { itemId, itemPath } = props;
  // const navigate = useNavigate();

  return (
    <Link to={`${itemPath}/${itemId}`}>
      <Button
        // onClick={() => {
        //   navigate(`${itemPath}/:${itemId}`);
        // }}
        className="edit"
        type="primary"
        title="Изменить"
      >
        {/* Edit */}
        <MdEdit />
      </Button>
    </Link>
  );
}
