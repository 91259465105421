import React, { useState, useEffect } from 'react';
import imginp from '../../../img/imageInp.svg';
import './AddReviews.scss';
import { addreview } from '../../../Api';
import { useNavigate } from 'react-router-dom';
import { CloseOutlined } from '@ant-design/icons';
import { Button, Image, notification } from 'antd';

export default function AddReviews() {
  const [name, setName] = useState('');
  const [info, setInfo] = useState('');
  const [img, setImg] = useState('');
  const [data, setData] = useState('');
  const navigate = useNavigate();

  const uploadImage = async (e) => {
    const file = e.target.files[0];
    const base64 = await convertBase64(file);
    setImg(base64);
  };

  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);

      fileReader.onload = () => {
        resolve(fileReader.result);
      };

      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  useEffect(() => {
    setData({ name, info, image: img });
  }, [name, info, img]);

  const openNotificationWithIcon = (type) => {
    notification[type]({
      message: type,
      description: type === 'success' ? 'Reviews has added successfully' : 'error while adding Reviews',
    });
  };

  return (
    <>
      <div className="header-cont">
        <h1>Create Reviews</h1>
        <Button
          onClick={() => {
            navigate(-1);
          }}
        >
          Назад
        </Button>
      </div>
      <div className="Product">
        <form>
          <input type="text" placeholder="Имя" onChange={(e) => setName(e.target.value)} value={name || ''} />
          <textarea
            rows="4"
            cols="50"
            type="text"
            placeholder="Описание"
            onChange={(e) => setInfo(e.target.value)}
            value={info || ''}
            style={{ padding: '.5rem', marginBottom: '1rem' }}
          />
          <div className="product-img">
            <h2>Фото</h2>
            {img ? <CloseOutlined onClick={() => setImg('')} className="deletFoto" /> : ''}
            <Image src={img} alt="" />
          </div>
          <label className="Image" htmlFor="file">
            <img src={imginp} alt="" /> Загрузить фото
          </label>
          <input
            id="file"
            type="file"
            onChange={(e) => {
              uploadImage(e);
            }}
          />
          <input
            onClick={(e) => {
              e.preventDefault();
              addreview(data, openNotificationWithIcon);
            }}
            className="Submit"
            value={'Добавить'}
            type={'submit'}
          />
        </form>
      </div>
    </>
  );
}
