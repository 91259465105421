import React, { useState, useEffect } from 'react';
import './AddPackagings.scss';
import { addPackaging } from '../../../Api';
import { useNavigate } from 'react-router-dom';
import { Button, notification } from 'antd';

export default function AddPackagings() {
  const [name, setName] = useState('');
  const [weight, setWeight] = useState('');
  const [data, setData] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    setData({ name, weight });
  }, [name, weight]);

  const openNotificationWithIcon = (type) => {
    notification[type]({
      message: type,
      description: type === 'success' ? 'Packaging has added successfully' : 'error while adding Packaging',
    });
  };

  return (
    <>
      <div className="header-cont">
        <h1>Добавить</h1>
        <Button onClick={() => navigate(-1)}>Назад</Button>
      </div>
      <div className="Product">
        <form>
          <input
            type="text"
            placeholder="Название"
            onChange={(e) => setName(e.target.value)}
            value={name || ''}
          />
          <input
            type="text"
            placeholder="Вес"
            onChange={(e) => setWeight(e.target.value)}
            value={weight || ''}
          />
          <input
            onClick={(e) => {
              e.preventDefault();
              addPackaging(data, openNotificationWithIcon);
            }}
            className="Submit"
            value={'Добавить'}
            type={'submit'}
          />
        </form>
      </div>
    </>
  );
}
