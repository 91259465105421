import { useEffect, useState, useCallback } from 'react';
import { getProducts, searchProducts, getProductsRefresh, deleteProduct } from '../../Api';
import { Image, Button, notification, Checkbox } from 'antd';
import ModalProduct from '../../Components/Product/ModalProduct/Modal';
import TableComp from '../../Components/Table/TableComp';
import ModalDelet from '../../Components/Product/ModalDeletProduct/ModalDelet';
import { Link } from 'react-router-dom';
import ButtonEdit from '../../Components/ButtonEdit/ButtonEdit';
import { CloseOutlined } from '@ant-design/icons';
import './Product.scss';
import { Loader } from '../../Components/Loader/Loader';

export default function Product() {
  const [product, setProduct] = useState([]);
  const [search, setSearch] = useState('');
  const [productsData, setProductsData] = useState();
  const [searchData, setSearchData] = useState();
  const [data, setData] = useState();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (search) getSearchData(search);
    // getProductsData();
    setData(!!search);
  }, [search]);

  const getSearchData = async (search) => {
    setIsLoading(true);
    const data = await searchProducts(search);
    setSearchData(data);
    setIsLoading(false);
  };

  const getProductsData = useCallback(async () => {
    setIsLoading(true);
    const data = await getProducts();
    setProductsData(data);
    setIsLoading(false);
  }, [setProductsData, setIsLoading]);

  useEffect(() => {
    getProductsData();
  }, [getProductsData]);

  // const getProductData = async () => {
  //   const data = await getProducts();
  //   const NewProducts = reformedProductDataForTable(data);
  //   setProduct(NewProducts);
  // };

  const openNotificationWithIcon = (type) => {
    notification[type]({
      message: type,
      description: type === 'success' ? 'Product deleted successfully' : 'Product deletion error',
    });
  };

  const [checkedIds, setCheckedIds] = useState([]);

  const deleteSelected = () => {
    if (!checkedIds.length) return;
    const res = checkedIds.map(async (id) => await deleteProduct(id));

    if (res.every(Boolean)) {
      getProductsRefresh(true)
        .then(() => getProductsData())
        .then(() => getSearchData(search))
        .catch((err) => console.log('ERROR', err));
    }

    setCheckedIds([]);
  };

  const toggleCheckbox = useCallback(
    (id) => {
      if (checkedIds.includes(id)) {
        setCheckedIds((prev) => prev.filter((el) => el !== id));
      } else {
        setCheckedIds((prev) => prev.concat(id));
      }
    },
    [checkedIds],
  );

  const reformedProductDataForTable = useCallback(
    (data) => {
      if (!data?.length) return [];
      return data.map((i, index) => ({
        ...i,
        id: index + 1,
        Название: i.name,
        Цена: i.newPrice,
        Доступно: i.isActive ? 'ДА' : 'НЕТ',
        Лимит: i.limit,
        key: index,
        Фото: (
          <div className="img-table" key={index} style={{ margin: '0 auto' }}>
            <Image src={i.image[0] ?? i.image} />
          </div>
        ),
        Действия: (
          <>
            <ModalDelet
              key={index}
              deleteInfo={openNotificationWithIcon}
              deleteData={() => {
                getProductsRefresh()
                  .then(() => getProductsData())
                  .then(() => getSearchData(search))
                  .catch((err) => console.log('ERROR', err));
              }}
              rowId={i._id}
            />
            <ButtonEdit itemPath="/edit-product" itemId={i._id} />
            <ModalProduct product={i} />
            <Checkbox checked={checkedIds.includes(i._id)} onChange={() => toggleCheckbox(i._id)} />
          </>
        ),
      }));
    },
    [checkedIds, toggleCheckbox, getProductsData, search],
  );

  useEffect(() => {
    if (data) {
      const newProducts = reformedProductDataForTable(searchData);
      setProduct(newProducts);
    } else {
      const newProducts = reformedProductDataForTable(productsData);
      setProduct(newProducts);
    }
  }, [searchData, productsData, data, reformedProductDataForTable]);

  return (
    <div>
      <div className="header-cont">
        <h2>Продукт</h2>
        <Button
          title={'Обновить кэшированные продукты на сервере. Рекомендуется после добавления новых позиций.'}
          onClick={getProductsRefresh}
        >
          Обновить кэш
        </Button>
        <Button>
          <Link to={'/add-product'}>Добавить</Link>
        </Button>
        {checkedIds.length > 0 && (
          <Button danger type="primary" onClick={deleteSelected}>
            Удалить выбранное
          </Button>
        )}
        <div className="search-cont">
          <div className="inp-wrap">
            <CloseOutlined onClick={() => setSearch('')} className={`span ${data ? 'true' : 'false'}`} />
            <input
              type="text"
              placeholder="Поиск по названию"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
          </div>
        </div>
      </div>
      {isLoading ? (
        <Loader />
      ) : (
        <TableComp
          items={[
            { title: 'id' },
            { title: 'Фото' },
            { title: 'Название' },
            { title: 'Цена' },
            { title: 'Лимит' },
            { title: 'Доступно' },
            { title: 'Действия' },
          ]}
          dataSource={product}
        />
      )}
    </div>
  );
}
