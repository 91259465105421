import React, { useState, useEffect } from 'react';
import './EditSimilarProduct.scss';
import { Button, notification, Image } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import { getProducts, editSimilarProduct, getSimilarProduct } from '../../../Api';
import SelectOption from '../../SelectOption/SelectOption';

export default function EditSimilarProduct() {
  const [products, setProducts] = useState([]);
  const [product, setProduct] = useState(null);
  const [productOne, setProductOne] = useState(null);
  const [productTwo, setProductTwo] = useState(null);
  const [productThree, setProductThree] = useState(null);
  const [productFour, setProductFour] = useState(null);

  const [data, setData] = useState();

  const { id } = useParams();
  const sproductId = id.replaceAll(':', '');

  useEffect(() => {
    getProductData();
    getCategoryData();
  }, [sproductId]);

  const getProductData = async () => {
    const product = await getSimilarProduct(sproductId);
    addProductState(product);
    console.log(product, 'datadata');
  };

  const addProductState = (product) => {
    setProduct(product.product === null ? null : product.product);
    setProductOne(product.productOne === null ? null : product.productOne);
    setProductTwo(product.productTwo === null ? null : product.productTwo);
    setProductThree(product.productThree === null ? null : product.productThree);
    setProductFour(product.productFour === null ? null : product.productFour);
  };

  console.log('datadata', productTwo);

  const getCategoryData = async () => {
    const data = await getProducts();
    reformedRoletDataForTable(data);
  };

  const reformedRoletDataForTable = (data) => {
    setProducts(data);
  };

  useEffect(() => {
    setData({
      product: product,
      productOne: productOne,
      productTwo: productTwo,
      productThree: productThree,
      productFour: productFour,
    });
  }, [product, productOne, productTwo, productThree, productFour]);

  console.log('datadatadata', data);
  const navigate = useNavigate();

  const openNotificationWithIcon = (type) => {
    notification[type]({
      message: type,
      description: type === 'success' ? 'changes entered successfully' : 'error when making a change',
    });
  };

  return (
    <>
      <div className="header-cont">
        <h1>Изменить</h1>
        <Button
          onClick={() => {
            navigate(-1);
          }}
        >
          Назад
        </Button>
      </div>
      <div className="Product">
        <form>
          <div className="roles">
            <SelectOption
              data={products}
              placeholder="Основной продукт"
              setId={setProduct}
              currentId={product}
            />
          </div>

          <div className="roles">
            <SelectOption
              data={products}
              placeholder="Продукт 1"
              setId={setProductOne}
              currentId={productOne}
            />
          </div>

          <div className="roles">
            <SelectOption
              data={products}
              placeholder="Продукт 2"
              setId={setProductTwo}
              currentId={productTwo}
            />
          </div>

          <div className="roles">
            <SelectOption
              data={products}
              placeholder="Продукт 3"
              setId={setProductThree}
              currentId={productThree}
            />
          </div>

          <div className="roles">
            <SelectOption
              data={products}
              placeholder="Продукт 4"
              setId={setProductFour}
              currentId={productFour}
            />
          </div>
          <div className="buttons">
            <button
              onClick={(e) => {
                e.preventDefault();
                navigate(-1);
              }}
            >
              Отмена
            </button>
            <button
              onClick={(e) => {
                e.preventDefault();
                editSimilarProduct(sproductId, data, openNotificationWithIcon);
              }}
            >
              Сохранить
            </button>
          </div>
        </form>
      </div>
    </>
  );
}
