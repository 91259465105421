import React, { useState, useEffect } from 'react';
import './EditPackagings.scss';
import { Button, notification } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import { getPackaging, editPackaging } from '../../../Api';

export default function EditPackaging() {
  const [name, setName] = useState();
  const [weight, setWeight] = useState('');
  const { id } = useParams();
  const packagingId = id.replaceAll(':', '');
  const [data, setData] = useState();
  useEffect(() => {
    getPackagingData();
  }, [packagingId]);

  const getPackagingData = async () => {
    const packaging = await getPackaging(packagingId);
    addPackagingState(packaging);
  };

  const addPackagingState = (packaging) => {
    setName(packaging.name);
    setWeight(packaging.weight);
  };

  useEffect(() => {
    setData({ name: name, weight: weight });
  }, [name, weight]);

  const navigate = useNavigate();

  const openNotificationWithIcon = (type) => {
    notification[type]({
      message: type,
      description: type === 'success' ? 'changes entered successfully' : 'error when making a change',
    });
  };

  return (
    <>
      <div className="header-cont">
        <h1>Изменить</h1>
        <Button
          onClick={() => {
            navigate(-1);
          }}
        >
          Назад
        </Button>
      </div>

      <div className="Product">
        <form>
          <input
            className="Name"
            placeholder="Название"
            type={'text'}
            onChange={(e) => setName(e.target.value)}
            value={name || ''}
          />
          <input
            className="Price"
            placeholder="Вес"
            type={'text'}
            onChange={(e) => setWeight(e.target.value)}
            value={weight || ''}
          />
          <div className="buttons">
            <input
              onClick={(e) => {
                e.preventDefault();
                navigate(-1);
              }}
              className="Submit"
              value={'Отмена'}
              type={'submit'}
              style={{ marginRight: '1rem' }}
            />
            <input
              onClick={(e) => {
                e.preventDefault();
                editPackaging(packagingId, data, openNotificationWithIcon);
              }}
              className="Submit"
              value={'Сохранить'}
              type={'submit'}
            />
          </div>
        </form>
      </div>
    </>
  );
}
