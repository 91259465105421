import { notification } from 'antd';

import API from './axios';

const login = async (username, password) => {
  return await API.post('/auth/login', {
    username,
    password,
  });
};

const getSubCategories = async () => {
  try {
    const res = await API.get('/subCategory/');

    if (res.data) {
      return res.data;
    }
    return [];
  } catch (error) {
    console.log(error);
  }
};
const getSubCategory = async (subCategoryId) => {
  try {
    const res = await API.get(`/subCategory/${subCategoryId}`);
    if (res.data) {
      return res.data;
    }
    return [];
  } catch (error) {
    console.log(error);
  }
};

const addSubCategory = async (data, openNotificationWithIcon) => {
  await API.post('/subCategory', { data })
    .then((res) => {
      openNotificationWithIcon('success');
    })
    .catch((e) => {
      openNotificationWithIcon('error');
    });
};
const editSubCategory = async (categoryId, data, openNotificationWithIcon) => {
  try {
    const res = await API.put(`/subCategory/${categoryId}`, data);

    if (res.data) {
      openNotificationWithIcon('success');
      return res.data;
    }
    return [];
  } catch (error) {
    openNotificationWithIcon('error');
  }
};

const deleteSubCategory = async (rowId, deleteData, deleteInfo) => {
  try {
    const res = await API.delete(`/subCategory/${rowId}`);

    if (res.data) {
      deleteInfo('success');
      deleteData();
      return res.data;
    }
    return [];
  } catch (error) {
    deleteInfo('error');
    console.log(error);
  }
};
//-----------------------------------------------
const getSubCategoryBranches = async () => {
  try {
    const res = await API.get('/subCategoryBranch/');

    if (res.data) {
      return res.data;
    }
    return [];
  } catch (error) {
    console.log(error);
  }
};
const getSubCategoryBranch = async (subCategoryId) => {
  try {
    const res = await API.get(`/subCategoryBranch/${subCategoryId}`);
    if (res.data) {
      return res.data;
    }
    return [];
  } catch (error) {
    console.log(error);
  }
};

const addSubCategoryBranches = async (data, openNotificationWithIcon) => {
  await API.post('/subCategoryBranch', { data })
    .then((res) => {
      openNotificationWithIcon('success');
    })
    .catch((e) => {
      openNotificationWithIcon('error');
      console.log(e);
    });
};

const editSubCategoryBranches = async (categoryId, data, openNotificationWithIcon) => {
  try {
    const res = await API.put(`/subCategoryBranch/${categoryId}`, data);

    if (res.data) {
      openNotificationWithIcon('success');
      return res.data;
    }
    return [];
  } catch (error) {
    openNotificationWithIcon('error');
  }
};

const deleteSubCategoryBranches = async (rowId, deleteData, deleteInfo) => {
  try {
    const res = await API.delete(`/subCategoryBranch/${rowId}`);

    if (res.data) {
      deleteInfo('success');
      deleteData();
      return res.data;
    }
    return [];
  } catch (error) {
    deleteInfo('error');
    console.log(error);
  }
};
//------------------------------------------------

//------------------------------------------------
const getSubCategoriesBranchesItem = async () => {
  try {
    const res = await API.get('/subCategoryBranchItem/');

    if (res.data) {
      return res.data;
    }
    return [];
  } catch (error) {
    console.log(error);
  }
};
const getSubCategoryBranchesItem = async (subCategoryBranchId) => {
  try {
    const res = await API.get(`/subCategoryBranchItem/${subCategoryBranchId}`);
    if (res.data) {
      return res.data;
    }
    return [];
  } catch (error) {
    console.log(error);
  }
};

const addSubCategoryBranchesItem = async (data, openNotificationWithIcon) => {
  await API.post('/subCategoryBranchItem', { data })
    .then((res) => {
      openNotificationWithIcon('success');
    })
    .catch((e) => {
      openNotificationWithIcon('error');
      console.log(e);
    });
};

const editSubCategoryBranchItem = async (categoryId, data, openNotificationWithIcon) => {
  try {
    const res = await API.put(`/subCategoryBranchItem/${categoryId}`, data);

    if (res.data) {
      openNotificationWithIcon('success');
      return res.data;
    }
    return [];
  } catch (error) {
    console.log('errorr  edit', error);
    openNotificationWithIcon('error');
  }
};

const deleteSubCategoryBranchItem = async (rowId, deleteData, deleteInfo) => {
  try {
    const res = await API.delete(`/subCategoryBranchItem/${rowId}`);

    if (res.data) {
      deleteInfo('success');
      deleteData();
      return res.data;
    }
    return [];
  } catch (error) {
    deleteInfo('error');
    console.log(error);
  }
};
//------------------------------------------------
const getCategories = async () => {
  try {
    const res = await API.get('/category/');
    return res?.data || [];
  } catch (error) {
    console.log(error);
  }
};

const getCategory = async (categoryId) => {
  try {
    const res = await API.get(`/category/${categoryId}`);
    if (res.data) {
      return res.data;
    }
    return [];
  } catch (error) {
    console.log(error);
  }
};

const addCategory = async (data, openNotificationWithIcon) => {
  await API.post('/category', { data })
    .then((res) => {
      openNotificationWithIcon('success');
    })
    .catch((e) => {
      openNotificationWithIcon('error');
      console.log(e);
    });
};

const deleteCategory = async (rowId, deleteInfo, deleteData) => {
  try {
    const res = await API.delete(`/category/${rowId}`);
    if (res.data) {
      deleteInfo('success');
      deleteData();
      return res.data;
    }
    return [];
  } catch (error) {
    deleteInfo('error');
    console.log(error);
  }
};

const editCategory = async (categoryId, data, openNotificationWithIcon) => {
  try {
    const res = await API.put(`/category/${categoryId}`, data);

    if (res.data) {
      openNotificationWithIcon('success');
      return res.data;
    }
    return [];
  } catch (error) {
    console.log('errorr category edit', error);
    openNotificationWithIcon('error');
  }
};

const getSections = async () => {
  try {
    const res = await API.get('/section/');

    if (res.data) {
      return res.data;
    }
    return [];
  } catch (error) {
    console.log(error);
  }
};

const getSection = async (sectionId) => {
  try {
    const res = await API.get(`/section/${sectionId}`);
    if (res.data) {
      return res.data;
    }
    return [];
  } catch (error) {
    console.log(error);
  }
};

const addSection = async (data, openNotificationWithIcon) => {
  await API.post('/section', { data })
    .then((res) => {
      openNotificationWithIcon('success');
    })
    .catch((e) => {
      openNotificationWithIcon('error');
      console.log(e);
    });
};

const searchProducts = async (search) => {
  try {
    const res = await API.post('/product/searchProducts', {
      search,
    });

    if (res.data) {
      return res.data;
    }
    return [];
  } catch (error) {
    console.log(error);
  }
};

const deleteSection = async (sectionId, deleteData, deleteInfo) => {
  try {
    const res = await API.delete(`/section/${sectionId}`);

    if (res.data) {
      deleteInfo('success');
      deleteData();
      return res.data;
    }
    return [];
  } catch (error) {
    deleteInfo('error');
    console.log(error);
  }
};

const editSection = async (sectionId, data, openNotificationWithIcon) => {
  try {
    const res = await API.put(`/section/${sectionId}`, data);

    if (res.data) {
      openNotificationWithIcon('success');
      return res.data;
    }
    return [];
  } catch (error) {
    console.log('errorr product edit', error);
    openNotificationWithIcon('error');
  }
};

const saveBeforeAfter = async (data, openNotificationWithIcon) => {
  await API.post('/product/before-after', { data })
    .then((res) => {
      openNotificationWithIcon('success');
    })
    .catch((e) => {
      openNotificationWithIcon('error');
      console.log(e);
    });
};

const addProduct = async (data, openNotificationWithIcon) => {
  await API.post('/product', { data })
    .then((res) => {
      openNotificationWithIcon('success');
    })
    .catch((e) => {
      openNotificationWithIcon('error');
      console.log(e);
    });
};

const saveContent = async (data, openNotificationWithIcon) => {
  await API.post('/product/content', { data })
    .then((res) => {
      openNotificationWithIcon('success');
    })
    .catch((e) => {
      openNotificationWithIcon('error');
      console.log(e);
    });
};

const getContent = async () => {
  const res = await API.get('/product/content');
  if (res.data) {
    return res.data;
  }
  return {};
};

const getProducts = async () => {
  const res = await API.get('/product');
  if (res.data) {
    return res.data;
  }
  return [];
};

const getBeforeAfter = async () => {
  const res = await API.get('/product/before-after');
  console.log('res', res);
  if (res.data) {
    return res.data;
  }
  return [];
};

const openNotificationWithIcon = (type) => {
  notification[type]({
    message: type,
    description: type === 'success' ? 'DB Cache updated' : 'error cache refresh',
  });
};

const getProductsRefresh = async (hideNotification = false) => {
  const res = await API.get('/product/refresh');

  if (res?.data && !hideNotification) {
    openNotificationWithIcon('success');
  }
  return res?.data || [];
};

const getProduct = async (productId) => {
  if (!productId) {
    console.log('no productId');
    return [];
  }
  const res = await API.get(`/product/${productId}`);
  if (res.data) {
    return res.data;
  }
  return [];
};

const getGallery = async (productId) => {
  if (!productId) {
    console.log('no productId');
    return [];
  }

  const res = await API.get(`/product/gallery/${productId}`);
  if (res.data) return res.data;
  return [];
};

const editProduct = async (productId, data, openNotificationWithIcon, imgGallery) => {
  try {
    const res = await API.put(`/product/${productId}`, data);
    console.log('imgGallery', imgGallery);
    const res2 = await API.post(`/product/gallery`, {
      data: {
        id: productId,
        imgGallery,
      },
    });

    if (res.data && res2.data) {
      openNotificationWithIcon('success');
      return res.data;
    }

    openNotificationWithIcon('error');
    return [];
  } catch (error) {
    openNotificationWithIcon('error');
  }
};

const deleteProduct = async (rowId, deleteData, deleteInfo) => {
  try {
    const res = await API.delete(`/product/${rowId}`);

    if (res.data) {
      if (deleteData) deleteData();
      if (deleteInfo) deleteInfo('success');
      return res.data;
    }

    return [];
  } catch (error) {
    if (deleteInfo) deleteInfo('error');
    console.log(error);
  }
};

//-----------Color-----------------------------
const addColor = async (data, openNotificationWithIcon) => {
  await API.post('/color', { data })
    .then((res) => {
      openNotificationWithIcon('success');
    })
    .catch((e) => {
      openNotificationWithIcon('error');
      console.log(e);
    });
};

const getColors = async () => {
  const res = await API.get('/color');
  if (res.data) {
    return res.data;
  }
  return [];
};

const getColor = async (colorId) => {
  const res = await API.get(`/color/${colorId}`);
  if (res.data) {
    return res.data;
  }
  return [];
};

const editColor = async (colorId, data, openNotificationWithIcon) => {
  try {
    const res = await API.put(`/color/${colorId}`, data);

    if (res.data) {
      openNotificationWithIcon('success');
      return res.data;
    }

    return [];
  } catch (error) {
    openNotificationWithIcon('error');
  }
};

const deleteColor = async (rowId, deleteData, deleteInfo) => {
  try {
    const res = await API.delete(`/color/${rowId}`);

    if (res.data) {
      deleteData();
      deleteInfo('success');
      return res.data;
    }

    return [];
  } catch (error) {
    deleteInfo('error');
    console.log(error);
  }
};
//---------------------------------------------

//------------fractions------------------------
const addFaction = async (data, openNotificationWithIcon) => {
  await API.post('/faction', { data })
    .then((res) => {
      openNotificationWithIcon('success');
    })
    .catch((e) => {
      openNotificationWithIcon('error');
      console.log(e);
    });
};

const getFactions = async () => {
  const res = await API.get('/faction');
  if (res.data) {
    return res.data;
  }
  return [];
};

const getFaction = async (factionId) => {
  const res = await API.get(`/faction/${factionId}`);
  if (res.data) {
    return res.data;
  }
  return [];
};

const editFaction = async (factionId, data, openNotificationWithIcon) => {
  try {
    const res = await API.put(`/faction/${factionId}`, data);

    if (res.data) {
      openNotificationWithIcon('success');
      return res.data;
    }

    return [];
  } catch (error) {
    openNotificationWithIcon('error');
  }
};

const deleteFaction = async (rowId, deleteData, deleteInfo) => {
  try {
    const res = await API.delete(`/faction/${rowId}`);

    if (res.data) {
      deleteData();
      deleteInfo('success');
      return res.data;
    }

    return [];
  } catch (error) {
    deleteInfo('error');
    console.log(error);
  }
};

//---------------------------------------------

const addThickness = async (data, openNotificationWithIcon) => {
  await API.post('/thickness', { data })
    .then((res) => {
      openNotificationWithIcon('success');
    })
    .catch((e) => {
      openNotificationWithIcon('error');
      console.log(e);
    });
};

const getThicknesses = async () => {
  const res = await API.get('/thickness');
  if (res.data) {
    return res.data;
  }
  return [];
};

const getThickness = async (thicknessId) => {
  const res = await API.get(`/thickness/${thicknessId}`);
  if (res.data) {
    return res.data;
  }
  return [];
};

const editThickness = async (thicknessId, data, openNotificationWithIcon) => {
  try {
    const res = await API.put(`/thickness/${thicknessId}`, data);

    if (res.data) {
      openNotificationWithIcon('success');
      return res.data;
    }

    return [];
  } catch (error) {
    openNotificationWithIcon('error');
  }
};

const deleteThickness = async (rowId, deleteData, deleteInfo) => {
  try {
    const res = await API.delete(`/thickness/${rowId}`);

    if (res.data) {
      deleteData();
      deleteInfo('success');
      return res.data;
    }

    return [];
  } catch (error) {
    deleteInfo('error');
    console.log(error);
  }
};

// -------------------------------------------

//------------packaging------------------------
const addPackaging = async (data, openNotificationWithIcon) => {
  await API.post('/packaging', { data })
    .then((res) => {
      openNotificationWithIcon('success');
    })
    .catch((e) => {
      openNotificationWithIcon('error');
      console.log(e);
    });
};

const getPackagings = async () => {
  const res = await API.get('/packaging');
  if (res.data) {
    return res.data;
  }
  return [];
};

const getPackaging = async (packagingId) => {
  const res = await API.get(`/packaging/${packagingId}`);
  if (res.data) {
    return res.data;
  }
  return [];
};

const editPackaging = async (packagingId, data, openNotificationWithIcon) => {
  try {
    const res = await API.put(`/packaging/${packagingId}`, data);

    if (res.data) {
      openNotificationWithIcon('success');
      return res.data;
    }

    return [];
  } catch (error) {
    openNotificationWithIcon('error');
  }
};

const deletePackaging = async (rowId, deleteData, deleteInfo) => {
  try {
    const res = await API.delete(`/packaging/${rowId}`);

    if (res.data) {
      deleteData();
      deleteInfo('success');
      return res.data;
    }

    return [];
  } catch (error) {
    deleteInfo('error');
    console.log(error);
  }
};

//---------------------------------------------

//------------stock------------------------
const addStock = async (data, openNotificationWithIcon) => {
  await API.post('/stock', { data })
    .then((res) => {
      openNotificationWithIcon('success');
    })
    .catch((e) => {
      openNotificationWithIcon('error');
      console.log(e);
    });
};

const getStocks = async () => {
  const res = await API.get('/stock');
  if (res.data) {
    return res.data;
  }
  return [];
};

const getStock = async (stockId) => {
  const res = await API.get(`/stock/${stockId}`);
  if (res.data) {
    return res.data;
  }
  return [];
};

const editStock = async (stockId, data, openNotificationWithIcon) => {
  try {
    const res = await API.put(`/stock/${stockId}`, data);

    if (res.data) {
      openNotificationWithIcon('success');
      return res.data;
    }

    return [];
  } catch (error) {
    openNotificationWithIcon('error');
  }
};

const deleteStock = async (rowId, deleteData, deleteInfo) => {
  try {
    const res = await API.delete(`/stock/${rowId}`);

    if (res.data) {
      deleteData();
      deleteInfo('success');
      return res.data;
    }

    return [];
  } catch (error) {
    deleteInfo('error');
    console.log(error);
  }
};

//------------Material------------------------
const addMaterial = async (data, openNotificationWithIcon) => {
  await API.post('/material', { data })
    .then((res) => {
      openNotificationWithIcon('success');
    })
    .catch((e) => {
      openNotificationWithIcon('error');
      console.log(e);
    });
};

const getMaterials = async () => {
  const res = await API.get('/material');
  return res?.data || [];
};

const getMaterial = async (materialId) => {
  const res = await API.get(`/material/${materialId}`);
  console.log('getMaterial', res);
  if (res.data) {
    return res.data;
  }
  return [];
};

const editMaterial = async (materialId, data, openNotificationWithIcon) => {
  try {
    const res = await API.put(`/material/${materialId}`, data);

    if (res.data) {
      openNotificationWithIcon('success');
      return res.data;
    }

    return [];
  } catch (error) {
    openNotificationWithIcon('error');
  }
};

const deleteMaterial = async (rowId, deleteData, deleteInfo) => {
  try {
    const res = await API.delete(`/material/${rowId}`);

    if (res.data) {
      deleteData();
      deleteInfo('success');
      return res.data;
    }

    return [];
  } catch (error) {
    deleteInfo('error');
    console.log(error);
  }
};

//---------------------------------------------

//------------CompletedPr------------------------
const addCompletedProject = async (data, openNotificationWithIcon) => {
  await API.post('/completedProject', { data })
    .then((res) => {
      openNotificationWithIcon('success');
    })
    .catch((e) => {
      openNotificationWithIcon('error');
      console.log(e);
    });
};

const getCompletedProjects = async () => {
  const res = await API.get('/completedProject');
  if (res.data) {
    return res.data;
  }
  return [];
};

const getCompletedProject = async (completedProjectId) => {
  const res = await API.get(`/completedProject/${completedProjectId}`);
  if (res.data) {
    return res.data;
  }
  return [];
};

const editCompletedProject = async (completedProjectId, data, openNotificationWithIcon) => {
  try {
    const res = await API.put(`/completedProject/${completedProjectId}`, data);

    if (res.data) {
      openNotificationWithIcon('success');
      return res.data;
    }

    return [];
  } catch (error) {
    openNotificationWithIcon('error');
  }
};

const deleteCompletedProject = async (rowId, deleteData, deleteInfo) => {
  try {
    const res = await API.delete(`/completedProject/${rowId}`);

    if (res.data) {
      deleteData();
      deleteInfo('success');
      return res.data;
    }

    return [];
  } catch (error) {
    deleteInfo('error');
    console.log(error);
  }
};

//---------------------------------------------

//------------Reviews------------------------
const addreview = async (data, openNotificationWithIcon) => {
  await API.post('/review', { data })
    .then((res) => {
      openNotificationWithIcon('success');
    })
    .catch((e) => {
      openNotificationWithIcon('error');
      console.log(e);
    });
};

const getreviews = async () => {
  const res = await API.get('/review');
  if (res.data) {
    return res.data;
  }
  return [];
};

const getreview = async (reviewsId) => {
  const res = await API.get(`/review/${reviewsId}`);
  if (res.data) {
    return res.data;
  }
  return [];
};

const editreview = async (reviewsId, data, openNotificationWithIcon) => {
  try {
    const res = await API.put(`/review/${reviewsId}`, data);

    if (res.data) {
      openNotificationWithIcon('success');
      return res.data;
    }

    return [];
  } catch (error) {
    openNotificationWithIcon('error');
  }
};

const deletereview = async (rowId, deleteData, deleteInfo) => {
  try {
    const res = await API.delete(`/review/${rowId}`);

    if (res.data) {
      deleteData();
      deleteInfo('success');
      return res.data;
    }

    return [];
  } catch (error) {
    deleteInfo('error');
    console.log(error);
  }
};

//---------------------------------------------
const role = async (data, openNotificationWithIcon) => {
  try {
    const res = await API.post('/role', { data });

    if (res.data) {
      openNotificationWithIcon('success');
      return res.data;
    }

    return [];
  } catch (error) {
    openNotificationWithIcon('error');
  }
};

const getRoles = async () => {
  const res = await API.get('/role');
  if (res.data) {
    return res.data;
  }
  return [];
};

const deleteRole = async (roleId, deleteData, deleteInfo) => {
  try {
    const res = await API.delete(`/role/${roleId}`);

    if (res.data) {
      deleteData();
      deleteInfo('success');
      return res.data;
    }

    return [];
  } catch (error) {
    deleteInfo('error');
    console.log(error);
  }
};

const addOrder = async (data, openNotificationWithIcon) => {
  await API.post('/order', { data })
    .then((res) => {
      openNotificationWithIcon('success');
    })
    .catch((e) => {
      openNotificationWithIcon('error');
      console.error('errrrorrr order');
      console.log(e);
    });
};

const getOrders = async () => {
  try {
    const res = await API.get('/order');

    if (res.data) {
      return res.data;
    }

    return [];
  } catch (error) {
    console.log(error);
  }
};

const getOrder = async (orderId) => {
  try {
    const res = await API.get(`/order/${orderId}`);
    if (res.data) {
      return res.data;
    }
    return [];
  } catch (error) {
    console.log(error);
  }
};

const editOrder = async (orderId, orderData, openNotificationWithIcon) => {
  try {
    const res = await API.put(`/order/${orderId}`, orderData);

    if (res.data) {
      openNotificationWithIcon('success');
      return res.data;
    }

    return [];
  } catch (error) {
    openNotificationWithIcon('error');
  }
};

const deleteOrder = async (rowId, deleteData, deleteInfo) => {
  try {
    const res = await API.delete(`/order/${rowId}`);

    if (res.data) {
      deleteData();
      deleteInfo('success');
      return res.data;
    }

    return [];
  } catch (error) {
    deleteInfo('error');
    console.log(error);
  }
};

const addSimilarProduct = async (data, openNotificationWithIcon) => {
  await API.post('/sproduct', { data })
    .then((res) => {
      openNotificationWithIcon('success');
    })
    .catch((e) => {
      openNotificationWithIcon('error');
      console.error('errrrorrr order');
      console.log(e);
    });
};

const getSimilarProducts = async () => {
  try {
    const res = await API.get('/sproduct');

    if (res.data) {
      return res.data;
    }

    console.log(res.data);

    return [];
  } catch (error) {
    console.log(error);
  }
};

const getSimilarProduct = async (sprodcutId) => {
  try {
    const res = await API.get(`/sproduct/${sprodcutId}`);
    if (res.data) {
      return res.data;
    }
    return [];
  } catch (error) {
    console.log(error);
  }
};

const getSimilarProductByProdcutId = async (productId) => {
  try {
    const res = await API.get('/sproduct/SimilarProductByProdcutId', productId);
    if (res.data) {
      return res.data;
    }
    return [];
  } catch (error) {
    console.log(error);
  }
};

const editSimilarProduct = async (prodcutId, data, openNotificationWithIcon) => {
  try {
    const res = await API.put(`/sproduct/${prodcutId}`, data);

    if (res.data) {
      openNotificationWithIcon('success');
      return res.data;
    }

    return [];
  } catch (error) {
    openNotificationWithIcon('error');
  }
};

const deleteSimilarProduct = async (rowId, deleteData, deleteInfo) => {
  try {
    const res = await API.delete(`/sproduct/${rowId}`);

    if (res.data) {
      deleteData();
      deleteInfo('success');
      return res.data;
    }

    return [];
  } catch (error) {
    deleteInfo('error');
    console.log(error);
  }
};

export {
  getThickness,
  getThicknesses,
  editThickness,
  deleteThickness,
  addThickness,
  getSimilarProductByProdcutId,
  addSimilarProduct,
  getSimilarProduct,
  getSimilarProducts,
  editSimilarProduct,
  deleteSimilarProduct,
  addSubCategory,
  getSubCategories,
  deleteSubCategory,
  getSubCategory,
  editSubCategory,
  addCategory,
  getCategories,
  addSection,
  getSections,
  searchProducts,
  deleteRole,
  deleteProduct,
  deleteOrder,
  editOrder,
  getOrders,
  getOrder,
  addOrder,
  deleteSection,
  deleteCategory,
  login,
  getRoles,
  role,
  getSection,
  getCategory,
  addProduct,
  getProducts,
  getProductsRefresh,
  getProduct,
  editProduct,
  editSection,
  editCategory,
  getSubCategoryBranches,
  getSubCategoryBranch,
  addSubCategoryBranches,
  editSubCategoryBranches,
  deleteSubCategoryBranches,
  getSubCategoriesBranchesItem,
  getSubCategoryBranchesItem,
  addSubCategoryBranchesItem,
  editSubCategoryBranchItem,
  deleteSubCategoryBranchItem,
  addColor,
  getColors,
  getColor,
  editColor,
  deleteColor,
  addFaction,
  getFactions,
  getFaction,
  editFaction,
  deleteFaction,
  addPackaging,
  getPackagings,
  getPackaging,
  editPackaging,
  deletePackaging,
  addStock,
  getStocks,
  getStock,
  editStock,
  deleteStock,
  addMaterial,
  getMaterials,
  getMaterial,
  editMaterial,
  deleteMaterial,
  addCompletedProject,
  getCompletedProjects,
  getCompletedProject,
  editCompletedProject,
  deleteCompletedProject,
  addreview,
  getreviews,
  getreview,
  editreview,
  deletereview,
  saveBeforeAfter,
  getBeforeAfter,
  getGallery,
  getContent,
  saveContent,
};
