import React, { useState, useEffect } from 'react';
import './EditThickness.scss';
import { Button, notification } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import { getFaction, editFaction, getThickness, editThickness } from '../../../Api';

export default function EditThickness() {
  const [size, setSize] = useState();
  const { id } = useParams();
  const thicknessId = id.replaceAll(':', '');
  const [data, setData] = useState();

  useEffect(() => {
    getThicknessData();
  }, [thicknessId]);

  const getThicknessData = async () => {
    const thickness = await getThickness(thicknessId);
    addThicknessState(thickness);
  };

  const addThicknessState = (thickness) => {
    setSize(thickness.size);
  };

  useEffect(() => {
    setData({ size });
  }, [size]);

  const navigate = useNavigate();

  const openNotificationWithIcon = (type) => {
    notification[type]({
      message: type,
      description: type === 'success' ? 'changes entered successfully' : 'error when making a change',
    });
  };

  return (
    <>
      <div className="header-cont">
        <h1>Изменить</h1>
        <Button onClick={() => navigate(-1)}>Назад</Button>
      </div>

      <div className="Product">
        <form>
          <input
            className="Name"
            placeholder="Толщина"
            type={'text'}
            onChange={(e) => setSize(e.target.value)}
            value={size || ''}
          />
          <div className="buttons">
            <input
              onClick={(e) => {
                e.preventDefault();
                navigate(-1);
              }}
              className="Submit"
              value={'Отмена'}
              type={'submit'}
              style={{ marginRight: '1rem' }}
            />
            <input
              onClick={(e) => {
                e.preventDefault();
                editThickness(thicknessId, data, openNotificationWithIcon);
              }}
              className="Submit"
              value={'Save'}
              type={'submit'}
            />
          </div>
        </form>
      </div>
    </>
  );
}
