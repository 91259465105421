import React, { useEffect, useState } from 'react';
import { Button, notification } from 'antd';
import ModalUsers from '../../Components/Section/ModalSections/Modal';
import './Section.scss';
import TableComp from '../../Components/Table/TableComp';
import ModalDelet from '../../Components/Section/ModalDeletSection/ModalDelet';
import ButtonEdit from '../../Components/ButtonEdit/ButtonEdit';
import { Link } from 'react-router-dom';
import { getSections } from '../../Api';
import { Loader } from '../../Components/Loader/Loader';

const Section = () => {
  const [sections, setSections] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const openNotificationWithIcon = (type) => {
    notification[type]({
      message: type,
      description: type === 'success' ? 'Section deleted successfully' : 'Section deletion error',
    });
  };

  const getSectionData = async () => {
    setIsLoading(true);
    const data = await getSections();
    const newSection = reformidUserDataForTable(data);
    setSections(newSection);
    setIsLoading(false);
  };

  const reformidUserDataForTable = (data) => {
    if (!data?.length) return [];
    return data.map((i, index) => ({
      ...i,
      id: index + 1,
      Название: i.name,
      Действия: (
        <React.Fragment key={index}>
          <ModalDelet deleteInfo={openNotificationWithIcon} deleteData={getSectionData} rowId={i._id} />
          <ButtonEdit itemPath="" itemId={i._id} />
          <ModalUsers user={i} />
        </React.Fragment>
      ),
      'post code': i.postalCode,
    }));
  };

  useEffect(() => {
    getSectionData();
  }, []);

  if (isLoading) return <Loader />;

  return (
    <>
      <div className="header-cont">
        <h2>Секции</h2>
        <Button>
          <Link to={'/add-section'}>Добавить</Link>
        </Button>
      </div>
      <TableComp
        items={[{ title: 'id' }, { title: 'Название' }, { title: 'Действия' }]}
        dataSource={sections}
      />
    </>
  );
};

export default Section;
