import axios from "axios";
const token = localStorage.getItem("token");

export function setToken(token) {
  axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
}
setToken(token);

export default axios.create({
  baseURL: process.env.REACT_APP_BASE_URL || `http://localhost:5000/`,
});
