import React, { useEffect, useState } from 'react';
import { getMaterials } from '../../Api';
import { Image, Button, notification } from 'antd';
import ModalProduct from '../../Components/Materials/ModalMaterials/Modal';
import TableComp from '../../Components/Table/TableComp';
import ModalDelet from '../../Components/Materials/ModalDeletMaterials/ModalDelet';
import { Link } from 'react-router-dom';
import ButtonEdit from '../../Components/ButtonEdit/ButtonEdit';
import './Materials.scss';
import { Loader } from '../../Components/Loader/Loader';

export default function Materials() {
  const [materials, setMaterials] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const getMaterialsData = async () => {
    setIsLoading(true);
    const data = await getMaterials();
    const NewMaterials = reformedMaterialsDataForTable(data);
    setMaterials(NewMaterials);
    setIsLoading(false);
  };

  const openNotificationWithIcon = (type) => {
    notification[type]({
      message: type,
      description: type === 'success' ? 'Materials deleted successfully' : 'Materials deletion error',
    });
  };

  const reformedMaterialsDataForTable = (data) => {
    if (!data?.length) return [];
    return data.map((i, index) => ({
      ...i,
      id: index + 1,
      Название: i.name,
      Фото: (
        <div className="img-table" key={index} style={{ margin: '0 auto' }}>
          <Image src={i.image?.replace('188.225.36.154:3500', 'апи.камень-газон.рф')} />
        </div>
      ),
      Действия: (
        <React.Fragment key={index}>
          <ModalDelet deleteInfo={openNotificationWithIcon} deleteData={getMaterialsData} rowId={i._id} />
          <ButtonEdit itemPath="/edit-materials" itemId={i._id} />
          <ModalProduct materials={i} />
        </React.Fragment>
      ),
    }));
  };

  useEffect(() => {
    getMaterialsData();
  }, []);

  if (isLoading) return <Loader />;

  return (
    <div>
      <div className="header-cont">
        <h2>Материалы</h2>
        <Button>
          <Link to="/add-materials">Добавить</Link>
        </Button>
      </div>

      <TableComp
        items={[{ title: 'id' }, { title: 'Фото' }, { title: 'Название' }, { title: 'Действия' }]}
        dataSource={materials}
      />
    </div>
  );
}
