import React, { useEffect, useState } from 'react';
import { getFactions } from '../../Api';
import { Button, notification } from 'antd';
import ModalProduct from '../../Components/Fractions/ModalFractions/Modal';
import TableComp from '../../Components/Table/TableComp';
import ModalDelet from '../../Components/Fractions/ModalDeletFractions/ModalDelet';
import { Link } from 'react-router-dom';
import ButtonEdit from '../../Components/ButtonEdit/ButtonEdit';
import './Fractions.scss';
import { Loader } from '../../Components/Loader/Loader';

export default function Fractions() {
  const [fractions, setFractions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const getFractionsData = async () => {
    setIsLoading(true);
    const data = await getFactions();
    const NewFractions = reformedFractionsDataForTable(data);
    setFractions(NewFractions);
    setIsLoading(false);
  };

  const openNotificationWithIcon = (type) => {
    notification[type]({
      message: type,
      description: type === 'success' ? 'Fractions deleted successfully' : 'Fractions deletion error',
    });
  };

  const reformedFractionsDataForTable = (data) =>
    !data?.length
      ? []
      : data.map((i, index) => ({
          ...i,
          id: index + 1,
          Размер: i.size,
          Действия: (
            <React.Fragment key={index}>
              <ModalDelet deleteInfo={openNotificationWithIcon} deleteData={getFractionsData} rowId={i._id} />
              <ButtonEdit itemPath="/edit-factions" itemId={i._id} />
              <ModalProduct faction={i} />
            </React.Fragment>
          ),
        }));

  useEffect(() => {
    getFractionsData();
  }, []);

  if (isLoading) return <Loader />;

  return (
    <div>
      <div className="header-cont">
        <h2>Фракции</h2>
        <Button>
          <Link to={'/add-factions'}>Добавить</Link>
        </Button>
      </div>

      <TableComp
        items={[{ title: 'id' }, { title: 'Размер' }, { title: 'Действия' }]}
        dataSource={fractions}
      />
    </div>
  );
}
