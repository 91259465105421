import { Select } from "antd";

import "./SelectChackbox.scss";

const { Option } = Select;

export default function SelectChackbox(props) {
  const { selecteditems, items, setItems, placeholder } = props;
  const handleChange = (value) => setItems(value);

  // if (props?.placeholder === "Id Category By Appointment Item")
  //   console.log("props", props);

  let value = selecteditems;
  if (selecteditems?.length) {
    if (typeof selecteditems[0] !== "string" && Array.isArray(selecteditems)) {
      value = selecteditems?.map((el) => el?._id);
    }
  }

  return (
    <div style={{ display: "flex", width: "100%" }}>
      <Select
        mode="multiple"
        style={{ width: "100%" }}
        placeholder={placeholder}
        value={value}
        // value={selecteditems || []}
        onChange={handleChange}
        required={props.required}
      >
        {items.map((i) => (
          <Option key={i._id} value={i._id}>
            {i?.fullName || i?.name || i?.size}
          </Option>
        ))}
      </Select>
      {props.required ? <>&nbsp;✱</> : <div style={{ width: "1rem" }} />}
    </div>
  );
}
