import React, { useContext, useEffect, useState } from "react";

import { Routes, Route, Navigate } from "react-router-dom";

import { Row, Col } from "antd";

import { observer } from "mobx-react-lite";

import { Context } from "./index";

import { Loader } from "./Components/Loader/Loader";
import MenuRight from "./Components/Menu/Menu";
import BeforeAfter from "./Pages/BeforeAfter/BeforeAfter";
import AddCategory from "./Pages/Category/AddCategory";
import Category from "./Pages/Category/Category";
import Colors from "./Pages/Colors/Colors";
import CompletedProjects from "./Pages/CompletedPr/CompletedProjects";
import Fractions from "./Pages/Fractions/Fractions";
import Login from "./Pages/Login/Login";
import Materials from "./Pages/Materials/Materials";
import Orders from "./Pages/Orders/Orders";
import Packagings from "./Pages/Packagings/Packagings";
import PagesContent from "./Pages/PagesContent/PagesContent";
import Product from "./Pages/Product/Product";
import Reviews from "./Pages/Reviews/Reviews";
import AddSection from "./Pages/Section/AddSection";
import Section from "./Pages/Section/Section";
import SimilarProducts from "./Pages/SimilarProducts/SimilarProducts";
import Stocks from "./Pages/Stocks/Stocks";
import AddSubCategory from "./Pages/SubCategory/AddSubCategory";
import SubCategory from "./Pages/SubCategory/SubCategory";
import AddSubCategoryBranches from "./Pages/SubCategoryBranches/AddSubCategoryBranches";
import SubCategoryBranches from "./Pages/SubCategoryBranches/SubCategoryBranches";
import Thickness from "./Pages/Thickness/Thickness";
import EditCategory from "./Components/Category/EditCategory/EditCategory";
import AddColor from "./Components/Colors/AddColor/AddColor";
import EditColors from "./Components/Colors/EditColors/EditColors";
import AddCompletedProject from "./Components/CompletedProjects/AddCompletedProject/AddCompletedProject";
import EditCompletedProject from "./Components/CompletedProjects/EditCompletedProject/EditCompletedProject";
import AddFractions from "./Components/Fractions/AddFractions/AddFractions";
import EditFractions from "./Components/Fractions/EditFractions/EditFractions";
import AddMaterials from "./Components/Materials/AddMaterials/AddMaterials";
import EditMaterials from "./Components/Materials/EditMaterials/EditMaterials";
import AddOrder from "./Components/Order/AddOrder/AddOrder";
import EditOrder from "./Components/Order/EditOrder/EditOrder";
import AddPackagings from "./Components/Packagings/AddPackagings/AddPackagings";
import EditPackaging from "./Components/Packagings/EditPackagings/EditPackagings";
import AddProduct from "./Components/Product/AddProduct/AddProduct";
import EditProduct from "./Components/Product/EditProduct/EditProduct";
import AddReviews from "./Components/Reviews/AddReviews/AddReviews";
import EditReviews from "./Components/Reviews/EditReviews/EditReviews";
import EditSection from "./Components/Section/EditSection/EditSection";
import AddSimilarProduct from "./Components/SimilarProduct/AddSimilarProduct/AddSimilarProduct";
import EditSimilarProduct from "./Components/SimilarProduct/EditSimilarProduct/EditSimilarProduct";
import AddStocks from "./Components/Stocks/AddStocks/AddStocks";
import EditStocks from "./Components/Stocks/EditStocks/EditStocks";
import EditSubCategory from "./Components/SubCategory/EditSubCategory/EditSubCategory";
import EditSubCategoryBranches from "./Components/SubCategoryBranches/EditSubCategoryBranches/EditSubCategoryBranches";
import AddThickness from "./Components/Thickness/AddThickness/AddThickness";
import EditThickness from "./Components/Thickness/EditThickness/EditThickness";

const App = observer(() => {
  const { user } = useContext(Context);

  const [isLoading, setIsLoading] = useState(true);

  const getAuth = async () => {
    try {
      const token = localStorage.getItem("token");
      if (token) {
        await user.setIsAuth(true);
      } else {
        await user.setIsAuth(false);
      }
      setIsLoading(false);
    } catch (e) {
      alert(e.response.data.message);
      localStorage.removeItem("token");
      user.setIsAuth(false);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getAuth();
  }, []);

  if (isLoading) return <Loader />;

  return (
    <div className="App">
      {user._isAuth ? (
        <Row>
          <Col span={6}>
            <MenuRight />
          </Col>
          <Col span={18} className="leftBlock">
            <Routes>
              <Route path="/thickness" element={<Thickness />} />
              <Route path="/similarProducts" element={<SimilarProducts />} />
              <Route path="/beforeAfter" element={<BeforeAfter />} />
              <Route path="/pagesContent" element={<PagesContent />} />

              <Route
                path="/edit-sproduct/:id"
                element={<EditSimilarProduct />}
              />
              <Route path="/add-sproduct" element={<AddSimilarProduct />} />
              <Route path="/" element={<Section />} />
              <Route path="/add-category" element={<AddCategory />} />
              <Route path="/add-subCategory" element={<AddSubCategory />} />
              <Route
                path="/add-subCategoryBranches"
                element={<AddSubCategoryBranches />}
              />
              <Route path="/category" element={<Category />} />
              <Route path="/subCategory" element={<SubCategory />} />
              <Route
                path="/subCategoryBranches"
                element={<SubCategoryBranches />}
              />
              <Route path="/products" element={<Product />} />
              <Route path="/add-product" element={<AddProduct />} />
              <Route path="/colors" element={<Colors />} />
              <Route path="/:id" element={<EditSection />} />
              <Route path="/edit-category/:id" element={<EditCategory />} />
              <Route
                path="/edit-subCategory/:id"
                element={<EditSubCategory />}
              />
              <Route
                path="/edit-subCategoryBranches/:id"
                element={<EditSubCategoryBranches />}
              />
              <Route path="/edit-product/:id" element={<EditProduct />} />
              <Route path="/orders" element={<Orders />} />
              <Route path="/edit-order/:id" element={<EditOrder />} />
              <Route path="/add-order" element={<AddOrder />} />
              <Route path="/add-section" element={<AddSection />} />
              <Route path="/add-color" element={<AddColor />} />
              <Route path="/edit-color/:id" element={<EditColors />} />
              <Route path="/faction" element={<Fractions />} />
              <Route path="/edit-factions/:id" element={<EditFractions />} />
              <Route path="/add-factions" element={<AddFractions />} />
              <Route path="/packaging" element={<Packagings />} />
              <Route path="/edit-packaging/:id" element={<EditPackaging />} />
              <Route path="/add-packaging" element={<AddPackagings />} />
              <Route path="/stocks" element={<Stocks />} />
              <Route path="/edit-stock/:id" element={<EditStocks />} />
              <Route path="/add-stock" element={<AddStocks />} />
              <Route path="/materials" element={<Materials />} />
              <Route path="/edit-materials/:id" element={<EditMaterials />} />
              <Route path="/add-materials" element={<AddMaterials />} />
              <Route
                path="/completedprojects"
                element={<CompletedProjects />}
              />
              <Route
                path="/edit-completedprojects/:id"
                element={<EditCompletedProject />}
              />
              <Route
                path="/add-completedprojects"
                element={<AddCompletedProject />}
              />
              <Route path="/reviews" element={<Reviews />} />
              <Route path="/edit-reviews/:id" element={<EditReviews />} />
              <Route path="/add-reviews" element={<AddReviews />} />
              <Route path="/add-thickness" element={<AddThickness />} />
              <Route path="/edit-thickness/:id" element={<EditThickness />} />

              <Route path="*" element={<Navigate to={"/"} />} />
            </Routes>
          </Col>
        </Row>
      ) : (
        <Routes>
          <Route exact element={<Login />} path="/" />
          <Route path="*" element={<Navigate to={"/"} />} />
        </Routes>
      )}
    </div>
  );
});

export default App;
