import React, { useState, useEffect } from 'react';
import './AddSubCategoryBranches.scss';
import { Button, notification } from 'antd';
import { useNavigate } from 'react-router-dom';
import { addSubCategoryBranches, getSubCategories } from '../../Api';
import SelectOption from '../../Components/SelectOption/SelectOption';

export default function AddSubCategoryBranches() {
  const [name, setName] = useState('');
  const [data, setData] = useState('');
  const [subCategory, setSubCategory] = useState('');
  const [subCategories, setSubCategories] = useState([]);
  const navigate = useNavigate();

  const openNotificationWithIcon = (type) => {
    notification[type]({
      message: type,
      description:
        type === 'success'
          ? 'SubCategoryBranches has added successfully'
          : 'error while adding SubCategoryBranches',
    });
  };

  useEffect(() => {
    setData({
      name: name,
      subCategoryId: subCategory,
    });
  }, [name, subCategory]);

  useEffect(() => {
    getSubCategoryData();
  }, []);

  const getSubCategoryData = async () => {
    const data = await getSubCategories();
    reformedRoletDataForTable(data);
  };

  const reformedRoletDataForTable = (data) => {
    setSubCategories(data);
  };

  return (
    <>
      <div className="header-cont">
        <h1>Добавить</h1>
        <Button
          onClick={() => {
            navigate(-1);
          }}
        >
          Назад
        </Button>
      </div>
      <div className="Product">
        <form>
          <div className="name-surname">
            <input
              type="text"
              placeholder="Название"
              onChange={(e) => setName(e.target.value)}
              value={name || ''}
            />
            <div className="roles">
              <SelectOption
                data={subCategories}
                placeholder="Тип товара"
                setId={setSubCategory}
                currentId={subCategory}
              />
            </div>
          </div>

          <div className="buttons">
            <button
              onClick={(e) => {
                e.preventDefault();
                navigate(-1);
              }}
            >
              Отмена
            </button>
            <button
              onClick={(e) => {
                e.preventDefault();
                addSubCategoryBranches(data, openNotificationWithIcon);
              }}
            >
              Добавить
            </button>
          </div>
        </form>
      </div>
    </>
  );
}
