import React, { useState, useEffect } from 'react';
import './EditSection.scss';
import { Button, notification } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import { getSection, editSection } from '../../../Api';

export default function AddProduct() {
  const [name, setName] = useState('');
  const [data, setData] = useState();
  const { id } = useParams();
  const sectionId = id.replaceAll(':', '');

  useEffect(() => {
    getSectionData();
  }, [sectionId]);

  const getSectionData = async () => {
    const section = await getSection(sectionId);
    addSectionState(section);
  };

  const addSectionState = (section) => {
    setName(section.name);
  };

  useEffect(() => {
    setData({
      name: name,
    });
  }, [name]);
  const navigate = useNavigate();

  const openNotificationWithIcon = (type) => {
    notification[type]({
      message: type,
      description: type === 'success' ? 'changes entered successfully' : 'error when making a change',
    });
  };

  return (
    <>
      <div className="header-cont">
        <h1>Изменить</h1>
        <Button
          onClick={() => {
            navigate(-1);
          }}
        >
          Назад
        </Button>
      </div>
      <div className="Product">
        <form>
          <div className="name-surname">
            <input type="text" onChange={(e) => setName(e.target.value)} value={name || ''} />
          </div>

          <div className="buttons">
            <button
              onClick={(e) => {
                e.preventDefault();
                navigate(-1);
              }}
            >
              Отмена
            </button>
            <button
              onClick={(e) => {
                e.preventDefault();
                editSection(sectionId, data, openNotificationWithIcon);
              }}
            >
              Сохранить
            </button>
          </div>
        </form>
      </div>
    </>
  );
}
