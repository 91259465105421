import React, { useState, useEffect } from 'react';
import './AddSection.scss';
import { Button, notification } from 'antd';
import { useNavigate } from 'react-router-dom';
import { addSection } from '../../Api';

export default function AddProduct() {
  const [name, setName] = useState('');
  const navigate = useNavigate();
  const [data, setData] = useState('');

  const openNotificationWithIcon = (type) => {
    notification[type]({
      message: type,
      description: type === 'success' ? 'Добавлено' : 'Ошибка добавления',
    });
  };

  useEffect(() => {
    setData({ name });
  }, [name]);

  return (
    <>
      <div className="header-cont">
        <h1>Добавить секцию</h1>
        <Button onClick={() => navigate(-1)}>Назад</Button>
      </div>
      <div className="Product">
        <form>
          <div className="name-surname">
            <input
              type="text"
              placeholder="Название"
              onChange={(e) => setName(e.target.value)}
              value={name || ''}
            />
          </div>

          <div className="buttons">
            <button
              onClick={(e) => {
                e.preventDefault();
                navigate(-1);
              }}
            >
              Отмена
            </button>
            <button
              onClick={(e) => {
                e.preventDefault();
                addSection(data, openNotificationWithIcon);
              }}
            >
              Добавить
            </button>
          </div>
        </form>
      </div>
    </>
  );
}
