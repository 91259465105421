import React, { useEffect, useState } from 'react';
import { Button, notification } from 'antd';
import ModalUsers from '../../Components/SubCategory/ModalSubCategory/Modal';
import './SubCategory.scss';
import TableComp from '../../Components/Table/TableComp';
import ModalDelet from '../../Components/SubCategory/ModalDeletSubCategory/ModalDelet';
import ButtonEdit from '../../Components/ButtonEdit/ButtonEdit';
import { Link } from 'react-router-dom';
import { getSubCategories } from '../../Api';
import { Loader } from '../../Components/Loader/Loader';

const SubCategory = () => {
  const [subCategories, setSubCategories] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const openNotificationWithIcon = (type) => {
    notification[type]({
      message: type,
      description: type === 'success' ? 'SubCategory deleted successfully' : 'SubCategory deletion error',
    });
  };

  const getSubCategoryData = async () => {
    setIsLoading(true);
    const data = await getSubCategories();
    const newCategory = reformidUserDataForTable(data);
    setSubCategories(newCategory);
    setIsLoading(false);
  };

  const reformidUserDataForTable = (data) => {
    if (!data?.length) return [];
    return data.map((i, index) => ({
      ...i,
      id: index + 1,
      Название: i.name,
      'Category ID': i.categoryId._id,
      Раздел: i.categoryId.name,
      Действия: (
        <React.Fragment key={index}>
          <ModalDelet deleteInfo={openNotificationWithIcon} deleteData={getSubCategoryData} rowId={i._id} />
          <ButtonEdit itemPath="/edit-subCategory" itemId={i._id} />
          <ModalUsers subCategory={i} />
        </React.Fragment>
      ),
      'post code': i.postalCode,
    }));
  };

  useEffect(() => {
    getSubCategoryData();
  }, []);

  if (isLoading) return <Loader />;

  return (
    <>
      <div className="header-cont">
        <h2>Товары и услуги</h2>
        <Button>
          <Link to={'/add-subCategory'}>Добавить</Link>
        </Button>
      </div>
      <TableComp
        items={[{ title: 'id' }, { title: 'Название' }, { title: 'Раздел' }, { title: 'Действия' }]}
        dataSource={subCategories}
      />
    </>
  );
};

export default SubCategory;
