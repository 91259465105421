import React, { useState, useEffect } from 'react';
import './AddCategory.scss';
import { Button, notification } from 'antd';
import { useNavigate } from 'react-router-dom';
import { addCategory, getSections } from '../../Api';
import SelectOption from '../../Components/SelectOption/SelectOption';

export default function AddProduct() {
  const [name, setName] = useState('');
  const [data, setData] = useState('');
  const [section, setSection] = useState('');
  const [sections, setSections] = useState([]);
  const navigate = useNavigate();

  const openNotificationWithIcon = (type) => {
    notification[type]({
      message: type,
      description: type === 'success' ? 'Category has added successfully' : 'error while adding Category',
    });
  };

  useEffect(() => {
    setData({
      name: name,
      sectionId: section,
    });
  }, [name, section]);

  useEffect(() => {
    getSectionData();
  }, []);

  const getSectionData = async () => {
    const data = await getSections();
    reformedRoletDataForTable(data);
  };

  const reformedRoletDataForTable = (data) => {
    setSections(data);
  };

  return (
    <>
      <div className="header-cont">
        <h1>Добавить Категорию</h1>
        <Button
          onClick={() => {
            navigate(-1);
          }}
        >
          Назад
        </Button>
      </div>
      <div className="Product">
        <form>
          <div className="name-surname">
            <input
              type="text"
              placeholder="Название"
              onChange={(e) => setName(e.target.value)}
              value={name || ''}
            />
            <div className="roles">
              <SelectOption data={sections} placeholder="Раздел" setId={setSection} currentId={section} />
            </div>
          </div>

          <div className="buttons">
            <button
              onClick={(e) => {
                e.preventDefault();
                navigate(-1);
              }}
            >
              Отмена
            </button>
            <button
              onClick={(e) => {
                e.preventDefault();
                addCategory(data, openNotificationWithIcon);
              }}
            >
              Добавить
            </button>
          </div>
        </form>
      </div>
    </>
  );
}
