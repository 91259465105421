import React, { useState, useEffect } from 'react';
import './EditOrder.scss';
import { Button, notification, Image } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import { getOrder, getProducts, editOrder, getPackagings } from '../../../Api';
import SelectOption from '../../SelectOption/SelectOption';

export default function EditOrder() {
  const [count, setCount] = useState('');
  const [email, setEmail] = useState('');
  const [productId, setProductId] = useState(null);
  const [newPrice, setNewPrice] = useState('');
  const [phone, setPhone] = useState('');
  const [categories, setCategroies] = useState([]);
  const [packaging, setPackaging] = useState([]);
  const [packagingId, setPackagingId] = useState(null);
  const [data, setData] = useState();

  const { id } = useParams();
  const orderId = id.replaceAll(':', '');

  useEffect(() => {
    getProductData();
    getCategoryData();
  }, [orderId]);

  const getProductData = async () => {
    const order = await getOrder(orderId);
    addProductState(order);
  };

  const addProductState = (order) => {
    setProductId(order.productId);
    setCount(order.count);
    setNewPrice(order.newPrice);
    setPackagingId(order.packaging);
    setPhone(order.phone);
    setEmail(order.email);
  };

  const getCategoryData = async () => {
    const data = await getProducts();
    const data2 = await getPackagings();

    reformedRoletDataForTable(data, data2);
  };

  const reformedRoletDataForTable = (data, data2) => {
    setCategroies(data);
    setPackaging(data2);
  };

  useEffect(() => {
    setData({
      count: count,
      email: email,
      productId: productId,
      newPrice: newPrice,
      phone: phone,
      packaging: packagingId,
    });
  }, [count, email, productId, newPrice, phone, packagingId]);
  const navigate = useNavigate();

  const openNotificationWithIcon = (type) => {
    notification[type]({
      message: type,
      description: type === 'success' ? 'changes entered successfully' : 'error when making a change',
    });
  };

  return (
    <>
      <div className="header-cont">
        <h1>Изменить</h1>
        <Button
          onClick={() => {
            navigate(-1);
          }}
        >
          Назад
        </Button>
      </div>
      <div className="Product">
        <form>
          <div className="name-surname">
            <div className="selectOption">
              <SelectOption
                data={categories}
                placeholder="Продукт"
                setId={setProductId}
                currentId={productId}
              />
            </div>
            <input
              className="Name"
              type="text"
              placeholder="Количество"
              onChange={(e) => setCount(e.target.value)}
              value={count || ''}
            />
          </div>
          <div className="phoneNumber-addres">
            <input
              type="text"
              input
              placeholder="Цена"
              onChange={(e) => setNewPrice(e.target.value)}
              value={newPrice || ''}
            />
            <input
              type="text"
              input
              placeholder="Email"
              onChange={(e) => setEmail(e.target.value)}
              value={email || ''}
            />
            <input
              type="text"
              input
              placeholder="Телефон"
              onChange={(e) => setPhone(e.target.value)}
              value={phone || ''}
            />
          </div>

          <div className="roles">
            <SelectOption
              data={packaging}
              placeholder="Упаковка"
              setId={setPackagingId}
              currentId={packagingId}
            />
          </div>
          <div className="buttons">
            <button
              onClick={(e) => {
                e.preventDefault();
                navigate(-1);
              }}
            >
              Отмена
            </button>
            <button
              onClick={(e) => {
                e.preventDefault();
                editOrder(orderId, data, openNotificationWithIcon);
              }}
            >
              Сохранить
            </button>
          </div>
        </form>
      </div>
    </>
  );
}
