import React from "react";

export const Loader = () => (
  <h2
    style={{
      margin: "2rem auto",
      fontSize: "2rem",
      fontWeight: 700,
      width: "fit-content",
    }}
  >
    ЗАГРУЗКА ...
  </h2>
);
