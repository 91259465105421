import { Modal, Button, Space } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { deleteThickness } from '../../../Api';
import { FaTrash } from 'react-icons/fa';

const { confirm } = Modal;

export default function ModalDelet(props) {
  const { deleteData, deleteInfo, rowId } = props;

  function showDeleteConfirm() {
    confirm({
      title: 'Вы уверены, что хотите удалить?',
      icon: <ExclamationCircleOutlined />,
      okText: 'Да',
      okType: 'danger',
      cancelText: 'Нет',
      onOk() {
        deleteThickness(rowId, deleteData, deleteInfo);
      },
      onCancel() {},
    });
  }
  return (
    <Space wrap>
      <Button className="delete" onClick={showDeleteConfirm} type="dashed" title="Удалить">
        {/* Delete */}
        <FaTrash />
      </Button>
    </Space>
  );
}
