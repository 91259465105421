import React, { useState } from 'react';
import { Modal, Button, Row, Col, Image } from 'antd';
import './Modal.scss';
import PropTypes from 'prop-types';
import { BsInfoCircle } from 'react-icons/bs';

const ModalUsers = (props) => {
  const { materials } = props;
  const [isModalVisible, setIsModalVisible] = useState(false);
  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <>
      <Button className="modal" type="primary" onClick={showModal} title="Подробнее">
        {/* Подробнее */}
        {/* <RiInformationLine />
        <IoIosInformationCircle /> */}
        <BsInfoCircle />
      </Button>
      <Modal
        title="Материал"
        className="admin-modal"
        open={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        cancelText="Отмена"
      >
        <Row>
          <Col span={24}>
            <div className="avatar-list">
              <ul>
                <li>
                  <b>Название:</b> {materials?.name}
                </li>
                <div className="img-table" style={{ margin: '0.5rem 0 0.5rem 2rem' }}>
                  <Image
                    src={materials?.image?.replace('188.225.36.154:3500', 'апи.камень-газон.рф')}
                    alt=""
                  />
                </div>
              </ul>
            </div>
          </Col>
        </Row>
      </Modal>
    </>
  );
};

export default ModalUsers;

ModalUsers.propTypes = {
  materials: PropTypes.shape({
    name: PropTypes.string.isRequired,
    _id: PropTypes.string.isRequired,
  }),
};
ModalUsers.defaultProps = {
  name: '',
  _id: '',
};
