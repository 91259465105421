import React, { useState, useEffect } from 'react';
import './EditColors.scss';
import imginp from '../../../img/imageInp.svg';
import { CloseOutlined } from '@ant-design/icons';

import { Button, Image, notification } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import { getColor, editColor } from '../../../Api';

export default function EditColors() {
  const [name, setName] = useState();
  const [image, setImage] = useState('');
  const { id } = useParams();
  const colorId = id.replaceAll(':', '');
  const [data, setData] = useState();
  useEffect(() => {
    getColorData();
  }, [colorId]);

  const getColorData = async () => {
    const color = await getColor(colorId);
    addColorState(color);
  };

  const addColorState = (color) => {
    setName(color.name);
    setImage(color.image);
  };

  useEffect(() => {
    setData({ name: name, image: image });
  }, [name, image]);

  const navigate = useNavigate();

  const uploadImage = async (e) => {
    const file = e.target.files[0];
    const base64 = await convertBase64(file);
    setImage(base64);
  };

  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);

      fileReader.onload = () => {
        resolve(fileReader.result);
      };

      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  const openNotificationWithIcon = (type) => {
    notification[type]({
      message: type,
      description: type === 'success' ? 'changes entered successfully' : 'error when making a change',
    });
  };

  return (
    <>
      <div className="header-cont">
        <h1>Изменить</h1>
        <Button
          onClick={() => {
            navigate(-1);
          }}
        >
          Назад
        </Button>
      </div>

      <div className="Product">
        <form>
          <input
            className="Name"
            placeholder="Цвет"
            type={'text'}
            onChange={(e) => setName(e.target.value)}
            value={name || ''}
          />
          <div className="product-img">
            <h2>Фото цвета</h2>
            {image ? (
              <CloseOutlined
                onClick={() => {
                  setImage('');
                }}
                className="deletFoto"
              />
            ) : (
              ''
            )}
            <Image src={image?.replace('188.225.36.154:3500', 'апи.камень-газон.рф')} alt="" />
          </div>
          <label className="Image" htmlFor="file">
            <img src={imginp} alt="" /> Загрузить фото
          </label>
          <input
            id="file"
            type="file"
            onChange={(e) => {
              uploadImage(e);
            }}
          />
          <div className="buttons">
            <input
              onClick={(e) => {
                e.preventDefault();
                navigate(-1);
              }}
              className="Submit"
              value="Отмена"
              style={{ marginRight: '1rem' }}
              type={'submit'}
            />

            <input
              onClick={(e) => {
                e.preventDefault();
                editColor(colorId, data, openNotificationWithIcon);
              }}
              className="Submit"
              value="Сохранить"
              type={'submit'}
            />
          </div>
        </form>
      </div>
    </>
  );
}
