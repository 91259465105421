import React, { useState, useEffect } from 'react';
import './AddSimilarProduct.scss';
import { Button, notification, Image } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { addProduct, getProducts, addSimilarProduct } from '../../../Api';
import SelectOption from '../../SelectOption/SelectOption';

export default function AddSimilarProduct() {
  const [products, setProducts] = useState([]);

  const [product, setProduct] = useState(null);
  const [productOne, setProductOne] = useState(null);
  const [productTwo, setProductTwo] = useState(null);
  const [productThree, setProductThree] = useState(null);
  const [productFour, setProductFour] = useState(null);
  const [data, setData] = useState();

  useEffect(() => {
    getCategoryData();
  }, []);

  const getCategoryData = async () => {
    const data = await getProducts();
    reformedRoletDataForTable(data);
  };

  const reformedRoletDataForTable = (data) => {
    setProducts(data);
  };

  // const uploadImage = async (e) => {
  //   const file = e.target.files[0];
  //   const base64 = await convertBase64(file);
  //   setImg([...img, base64]);
  // };

  // console.log("imgimgimg", img);
  // const convertBase64 = (file) => {
  //   return new Promise((resolve, reject) => {
  //     const fileReader = new FileReader();
  //     fileReader.readAsDataURL(file);

  //     fileReader.onload = () => {
  //       resolve(fileReader.result);
  //     };

  //     fileReader.onerror = (error) => {
  //       reject(error);
  //     };
  //   });
  // };

  useEffect(() => {
    setData({
      product: product,
      productOne: productOne,
      productTwo: productTwo,
      productThree: productThree,
      productFour: productFour,
    });
  }, [product, productOne, productTwo, productThree, productFour]);

  console.log('productproduct', data);
  const navigate = useNavigate();

  const openNotificationWithIcon = (type) => {
    notification[type]({
      message: type,
      description: type === 'success' ? 'changes entered successfully' : 'error when making a change',
    });
  };

  // const deleteFoto = (index) => {
  //   img.splice(index, index)
  //   console.log("index", index);
  // };

  return (
    <>
      <div className="header-cont">
        <h1>Добавить Похожий Продукт</h1>
        <Button
          onClick={() => {
            navigate(-1);
          }}
        >
          Назад
        </Button>
      </div>
      <div className="Product">
        <form>
          <div className="roles">
            <SelectOption
              data={products}
              placeholder="Основной продукт"
              setId={setProduct}
              currentId={product}
            />
          </div>

          <div className="roles">
            <SelectOption
              data={products}
              placeholder="Продукт 1"
              setId={setProductOne}
              currentId={productOne}
            />
          </div>

          <div className="roles">
            <SelectOption
              data={products}
              placeholder="Продукт 2"
              setId={setProductTwo}
              currentId={productTwo}
            />
          </div>

          <div className="roles">
            <SelectOption
              data={products}
              placeholder="Продукт 3"
              setId={setProductThree}
              currentId={productThree}
            />
          </div>

          <div className="roles">
            <SelectOption
              data={products}
              placeholder="Продукт 4"
              setId={setProductFour}
              currentId={productFour}
            />
          </div>

          {/* <div className="product-img">
            <h2>Product Image</h2>
            <Image src={img} alt="" />
          </div> */}

          <div className="buttons">
            <button
              onClick={(e) => {
                e.preventDefault();
                navigate(-1);
              }}
            >
              Отмена
            </button>
            <button
              onClick={(e) => {
                e.preventDefault();
                addSimilarProduct(data, openNotificationWithIcon);
              }}
            >
              Сохранить
            </button>
          </div>
        </form>
      </div>
    </>
  );
}
