import React, { useState } from 'react';
import { Image, Modal, Button, Row, Col } from 'antd';
import './Modal.scss';
import PropTypes from 'prop-types';
import { BsInfoCircle } from 'react-icons/bs';

const ModalUsers = (props) => {
  const { product } = props;
  const [isModalVisible, setIsModalVisible] = useState(false);
  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <>
      <Button className="modal" type="primary" onClick={showModal} title="Подробнее">
        {/* Подробнее */}
        {/* <RiInformationLine />
        <IoIosInformationCircle /> */}
        <BsInfoCircle />
      </Button>
      <Modal
        title="Продукт"
        className="admin-modal"
        open={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        cancelText="Отмена"
      >
        <Row>
          <Col span={0}>
            <div className="admin-modal-img">
              <Image src={product.image} alt="" />
            </div>
          </Col>
          <Col span={24}>
            <div className="avatar-list">
              <ul>
                <li>
                  <b>ID:</b> {product._id}
                </li>
                <li>
                  <b>ID раздела:</b> {product.itemCategoryId}
                </li>
                <li>
                  <b>Раздел:</b> {product?.categoryId?.name || product?.categoryId?._id}
                  {/* <b>Category ID:</b> {product?.categoryId?.name} */}
                </li>

                <li style={{ display: 'flex', flexWrap: 'wrap' }}>
                  <b>Тип товара (применение):</b>{' '}
                  {product.categoryIdByAppointment === null
                    ? '-'
                    : Array.isArray(product?.categoryIdByAppointment)
                    ? product?.categoryIdByAppointment.map((el, idx) => (
                        <React.Fragment key={idx}>{el}, </React.Fragment>
                      ))
                    : JSON.stringify(product?.categoryIdByAppointment)}
                  {/* : product?.categoryIdByAppointment?.name} */}
                </li>

                <li>
                  <b>Тип товара:</b>{' '}
                  {product.categoryIdByType === null ? '-' : product?.categoryIdByType?.name}
                </li>
                <li>
                  <b>Название:</b> {product?.name}
                </li>
                <li>
                  <b>Новая цена:</b> {product?.newPrice || '-'}
                </li>
                <li>
                  <b>Старая цена:</b> {product.oldPrice ? product.oldPrice : '-'}
                </li>
                <li>
                  <b>Размер x:</b> {product.x ? product.x : '-'}
                </li>
                <li>
                  <b>Размер y:</b> {product.y ? product.y : '-'}
                </li>
                <li>
                  <b>Размер z:</b> {product.z ? product.z : '-'}
                </li>
                <li>
                  <b>Product Status:</b> {product.status ? product.status : '-'}
                </li>
                <li>
                  <b>Диаметр:</b> {product.diameter ? product.diameter : '-'}
                </li>
                <li>
                  <b>Температура:</b> {product.temperature ? product.temperature : '-'}
                </li>
                <li>
                  <b>Площадь:</b> {product.square ? product.square : '-'}
                </li>
                <li>
                  <b>Вес:</b> {product.weight ? product.weight : '-'}
                </li>
                <li>
                  <b>Доступно в каталоге:</b> {product.isActive ? 'ДА' : 'НЕТ'}
                </li>
                <li>
                  <b>Лимит:</b> {product.limit ? product.limit : '-'}
                </li>

                {product.color ? (
                  product.color.map((i, index) => (
                    <li key={index}>
                      <b>Цвет {index + 1}:</b>
                      {i?.name}
                    </li>
                  ))
                ) : (
                  <li>
                    <b>Цвет:</b> -
                  </li>
                )}

                {product.faction ? (
                  product.faction.map((i, index) => (
                    <li key={index}>
                      <b>Фракция {index + 1}:</b>
                      {i?.size}
                    </li>
                  ))
                ) : (
                  <li>
                    <b>Фракция:</b> -
                  </li>
                )}

                {product.packaging ? (
                  product.packaging.map((i, index) => (
                    <li key={index}>
                      <b>Упаковка {index + 1}:</b>
                      {i?.name}
                    </li>
                  ))
                ) : (
                  <li>
                    <b>Упаковка:</b> -
                  </li>
                )}

                {product.material ? (
                  product.material.map((i, index) => (
                    <li key={index}>
                      <b>Материал {index + 1}:</b>
                      {i?.name}
                    </li>
                  ))
                ) : (
                  <li>
                    <b>Материал:</b> -
                  </li>
                )}

                <li>
                  <b>Информация:</b> {product.info ? product.info : '-'}
                </li>
              </ul>
            </div>
          </Col>
        </Row>
      </Modal>
    </>
  );
};

export default ModalUsers;

ModalUsers.propTypes = {
  product: PropTypes.shape({
    name: PropTypes.string.isRequired,
    price: PropTypes.string,
    img: PropTypes.string,
  }),
};
