import { useEffect, useState } from 'react';
import { getColors } from '../../Api';
import { Image, Button, notification } from 'antd';
import ModalProduct from '../../Components/Colors/ModalColor/Modal';
import TableComp from '../../Components/Table/TableComp';
import ModalDelet from '../../Components/Colors/ModalDeletColors/ModalDelet';
import { Link } from 'react-router-dom';
import ButtonEdit from '../../Components/ButtonEdit/ButtonEdit';
import './Colors.scss';
import { Loader } from '../../Components/Loader/Loader';

export default function Colors() {
  const [color, setColor] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const getColorData = async () => {
    setIsLoading(true);
    const data = await getColors();
    const NewColor = reformedColorDataForTable(data);
    setColor(NewColor);
    setIsLoading(false);
  };

  const openNotificationWithIcon = (type) => {
    notification[type]({
      message: type,
      description: type === 'success' ? 'Colors deleted successfully' : 'Colors deletion error',
    });
  };

  const reformedColorDataForTable = (data) => {
    if (!data?.length) return [];
    return data.map((i, index) => ({
      ...i,
      id: index + 1,
      Название: i?.name || index,
      Фото: (
        <div className="img-table" key={index} style={{ margin: '0 auto' }}>
          <Image src={i.image?.replace('188.225.36.154:3500', 'апи.камень-газон.рф')} />
        </div>
      ),
      Действия: (
        <>
          <ModalDelet
            key={index}
            deleteInfo={openNotificationWithIcon}
            deleteData={getColorData}
            rowId={i._id}
          />
          <ButtonEdit itemPath="/edit-color" itemId={i._id} />
          <ModalProduct color={i} />
        </>
      ),
    }));
  };

  useEffect(() => {
    getColorData();
  }, []);

  if (isLoading) return <Loader />;

  return (
    <div>
      <div className="header-cont">
        <h2>Цвета</h2>
        <Button>
          <Link to={'/add-color'}>Добавить</Link>
        </Button>
      </div>

      <TableComp
        items={[{ title: 'id' }, { title: 'Фото' }, { title: 'Название' }, { title: 'Действия' }]}
        dataSource={color}
      />
    </div>
  );
}
