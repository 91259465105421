import React, { useState, useRef, useEffect, useCallback } from "react";
import "./BeforeAfter.scss";
import { Button, notification, Image, Divider } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { getBeforeAfter, saveBeforeAfter } from "../../Api";
import imginp from "../../img/imageInp.svg";

export default function BeforeAfter() {
  const [price, setPrice] = useState([0]);
  const [imgBefore, setImgBefore] = useState([""]);
  const [imgAfter, setImgAfter] = useState([""]);

  const resetState = () => {
    setImgBefore([""]);
    setImgAfter([""]);
    setPrice([0]);
  };

  const getData = useCallback(async () => {
    const data = await getBeforeAfter();
    if (data.price) setPrice(data.price);
    if (data.imgBefore) setImgBefore(data.imgBefore);
    if (data.imgAfter) setImgAfter(data.imgAfter);
  }, []);

  useEffect(() => {
    getData();
  }, [getData]);

  const uploadImage = async (e, before, index) => {
    let file = e.target.files[0];
    const base64 = await convertBase64(file);

    console.log("index", index);

    if (before) {
      setImgBefore((prev) => {
        const arr = [...prev];
        arr[index] = base64;
        return arr;
      });
    } else {
      setImgAfter((prev) => {
        const arr = [...prev];
        arr[index] = base64;
        return arr;
      });
    }
  };

  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);

      fileReader.onload = () => {
        resolve(fileReader.result);
      };

      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  const deleteFoto = (before = true, index) => {
    if (before) {
      setImgBefore((prev) => {
        const arr = [...prev];
        arr.splice(index, 1);
        return arr;
      });
    } else {
      setImgAfter((prev) => {
        const arr = [...prev];
        arr.splice(index, 1);
        return arr;
      });
    }
  };

  const navigate = useNavigate();

  const openNotificationWithIcon = (type) => {
    notification[type]({
      message: type,
      description:
        type === "success" ? "Everything OK" : "Error when making a change",
    });

    if (type === "success" && headerRef?.current) {
      headerRef.current.scrollIntoView();
    }
  };

  const headerRef = useRef(null);

  return (
    <>
      <div className="header-cont" ref={headerRef}>
        <h1>Блок "ДО" и "ПОСЛЕ"</h1>
        <Button
          onClick={() => {
            setPrice((prev) => [...prev, 0]);
          }}
        >
          Добавить
        </Button>
        <Button
          onClick={() => {
            navigate(-1);
          }}
        >
          Назад
        </Button>
      </div>
      <div className="Product" style={{ width: "auto" }}>
        <form
          noValidate={false}
          onSubmit={(e) => {
            e.preventDefault();
            const data = { price, imgAfter, imgBefore };
            saveBeforeAfter(data, openNotificationWithIcon);
          }}
        >
          {price.map((_, i) => (
            <div className="container" key={i}>
              <div style={{ display: "flex", gap: "1rem" }}>
                {imgBefore[i] ? (
                  <div
                    className="product-img"
                    style={{
                      justifyContent: "flex-start",
                      flexDirection: "column",
                    }}
                  >
                    <CloseOutlined
                      onClick={() => {
                        deleteFoto(true, i);
                      }}
                      className="deletFoto"
                    />
                    <h2>Фото ДО</h2>
                    <Image src={imgBefore[i]} alt="" />
                  </div>
                ) : (
                  <label className="Image" style={{ height: "fit-content" }}>
                    <img src={imginp} alt="" /> Фото ДО
                    <input
                      type="file"
                      onChange={(e) => uploadImage(e, true, i)}
                      value=""
                      style={{ position: "absolute", visibility: "hidden" }}
                    />
                  </label>
                )}

                {imgAfter[i] ? (
                  <div
                    className="product-img"
                    style={{
                      justifyContent: "flex-start",
                      flexDirection: "column",
                    }}
                  >
                    <CloseOutlined
                      onClick={() => {
                        deleteFoto(false, i);
                      }}
                      className="deletFoto"
                    />
                    <h2>Фото ПОСЛЕ</h2>
                    <Image src={imgAfter[i]} alt="" />
                  </div>
                ) : (
                  <label className="Image" style={{ height: "fit-content" }}>
                    <img src={imginp} alt="" /> Фото ПОСЛЕ
                    <input
                      type="file"
                      onChange={(e) => uploadImage(e, false, i)}
                      value=""
                      style={{ position: "absolute", visibility: "hidden" }}
                    />
                  </label>
                )}
              </div>

              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "1rem",
                  width: "fit-content",
                  margin: "0 auto",
                }}
              >
                <h2>Цена: </h2>
                <input
                  type="number"
                  placeholder="Цена"
                  onChange={(e) =>
                    setPrice((prev) => {
                      const arr = [...prev];
                      arr[i] = +e.target.value;
                      return arr;
                    })
                  }
                  value={price[i]}
                  // required
                  // style={{ width: "fit-content" }}
                />
              </div>

              {i !== price.length - 1 && (
                <Divider dashed style={{ borderColor: "darkgrey" }} />
              )}
            </div>
          ))}

          {(!imgBefore?.length || !imgAfter?.length || !price) && (
            <p style={{ color: "red", fontWeight: 700, margin: "1rem" }}>
              ✱ - все поля обязательны для заполнения
            </p>
          )}

          <div className="buttons" style={{ marginTop: "1em", gap: "1vmax" }}>
            <button
              onClick={(e) => {
                e.preventDefault();
                navigate(-1);
              }}
            >
              Отмена
            </button>
            <button onClick={resetState}>Удалить всё</button>
            <button
              type="submit"
              disabled={
                !imgAfter?.length ||
                !imgBefore?.length ||
                !price?.length ||
                !price?.[0]
              }
            >
              Сохранить
            </button>
          </div>
        </form>
      </div>
    </>
  );
}
