import React from 'react';
import { Table, Empty } from 'antd';
import Highlighter from 'react-highlight-words';
import { SearchOutlined } from '@ant-design/icons';
import '../Table/TableComp.scss';

const tableLocale = {
  emptyText: <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="Нет данных" />,
};

class TableComp extends React.Component {
  state = {
    searchText: '',
    searchedColumn: '',
    page: +sessionStorage.getItem(window.location.pathname) || 1,
  };

  getColumnSearchProps = (dataIndex) => ({
    filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value, record) =>
      record[dataIndex] ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()) : '',
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select(), 100);
      }
    },
    render: (text) =>
      this.state.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  handleReset = (clearFilters) => {
    clearFilters();
    this.setState({ searchText: '' });
  };

  render() {
    const columns = [];

    this.props.items.map((item, index) => {
      if (index > 1 && index < 3) {
        columns.push({
          title: item.title,
          dataIndex: item.title,
          key: item.title,
          ...this.getColumnSearchProps(item.title),
        });
      } else {
        columns.push({
          title: item.title,
          dataIndex: item.title,
          key: item.title,
        });
      }
    });

    return (
      <Table
        locale={tableLocale}
        columns={columns}
        dataSource={this.props.dataSource}
        hide
        pagination={{
          hideOnSinglePage: true,
          onChange: (e) => {
            sessionStorage.setItem(window.location.pathname, e);
            this.setState({ ...this.state, page: e });
          },
          current: this.state.page || 1,
        }}
      />
    );
  }
}

export default TableComp;
