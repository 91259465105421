import React, { useContext } from 'react';

// router
import { Link, useLocation } from 'react-router-dom';

// MobX
import { observer } from 'mobx-react-lite';
import { Context } from './../../index';
// import { FaBorderStyle, FaCartArrowDown, FaSignOutAlt, FaUsers, FaUserTie } from "react-icons/fa";

// styles
import './Menu.scss';

const navbarRouter = [
  {
    name: 'Секции',
    key: '',
    route: '/',
  },
  {
    name: 'Разделы',
    key: 'category',
    route: '/category',
  },
  {
    name: 'Товары и услуги',
    key: 'subCategory',
    route: '/subCategory',
  },
  {
    name: 'Типы товара (применение)',
    key: 'subCategoryBranches',
    route: '/subCategoryBranches',
  },
  // {
  //
  //     name: "SubCategoryBranchesItem",
  //     key: "subCategoryBranchesItem",
  //     route: "/subCategoryBranchesItem"
  // },
  {
    name: 'Продукты',
    key: 'products',
    route: '/products',
  },
  {
    name: 'Заказы',
    key: 'orders',
    route: '/orders',
  },
  {
    name: 'Цвета',
    key: 'colors',
    route: '/colors',
  },
  {
    name: 'Фракции',
    key: 'faction',
    route: '/faction',
  },
  {
    name: 'Толщина',
    key: 'thickness',
    route: '/thickness',
  },
  {
    name: 'Упаковки',
    key: 'packaging',
    route: '/packaging',
  },
  {
    name: 'Акции',
    key: 'stocks',
    route: '/stocks',
  },
  {
    name: 'Материалы',
    key: 'materials',
    route: '/materials',
  },
  {
    name: 'Завершённые проекты',
    key: 'completedprojects',
    route: '/completedprojects',
  },
  {
    name: 'Отзывы',
    key: 'reviews',
    route: '/reviews',
  },
  {
    name: 'Похожие продукты',
    key: 'similarProducts',
    route: '/similarProducts',
  },
  {
    name: `Блок "ДО" и "ПОСЛЕ"`,
    key: 'beforeAfter',
    route: '/beforeAfter',
  },
  {
    name: `Содержимое страниц`,
    key: 'pagesContent',
    route: '/pagesContent',
  },
].map((el) => ({ ...el, type: 'collapse' }));

const MenuRight = observer(() => {
  const { user } = useContext(Context);
  const { pathname } = useLocation();

  const collapseName = pathname.replace('/', '');

  return (
    <div className="menu-right">
      <div>
        <h1 style={{ textAlign: 'center' }}>Панель администратора</h1>
      </div>
      <div>
        <div className="menu-down">
          <ul>
            {navbarRouter.map((item) => (
              <li key={item.key}>
                <Link
                  to={item.route}
                  key={item.key}
                  className={`menuItem ${item.key === collapseName && 'active'}`}
                >
                  <div className={`icons ${item.key === collapseName && 'iconsActive'}`}>{item.icon}</div>
                  {item.name}
                </Link>
              </li>
            ))}
          </ul>
        </div>
      </div>
      <button
        onClick={() => {
          localStorage.removeItem('token');
          user.setIsAuth(false);
        }}
        className="logOytBtn"
      >
        Выйти
      </button>
    </div>
  );
});

export default MenuRight;
