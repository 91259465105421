import React, { useEffect, useState } from 'react';
import { getPackagings } from '../../Api';
import { Button, notification } from 'antd';
import ModalProduct from '../../Components/Packagings/ModalPackagings/Modal';
import TableComp from '../../Components/Table/TableComp';
import ModalDelet from '../../Components/Packagings/ModalDeletPackagings/ModalDelet';
import { Link } from 'react-router-dom';
import ButtonEdit from '../../Components/ButtonEdit/ButtonEdit';
import './Packagings.scss';
import { Loader } from '../../Components/Loader/Loader';

export default function Packagings() {
  const [packagings, setPackagings] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const getPackagingsData = async () => {
    setIsLoading(true);
    const data = await getPackagings();
    const NewPackagings = reformedPackagingsDataForTable(data);
    setPackagings(NewPackagings);
    setIsLoading(false);
  };

  const openNotificationWithIcon = (type) => {
    notification[type]({
      message: type,
      description: type === 'success' ? 'Packagings deleted successfully' : 'Packagings deletion error',
    });
  };

  const reformedPackagingsDataForTable = (data) => {
    if (!data?.length) return [];
    return data.map((i, index) => ({
      ...i,
      id: index + 1,
      Название: i.name,
      Вес: i.weight,
      Действия: (
        <React.Fragment key={index}>
          <ModalDelet deleteInfo={openNotificationWithIcon} deleteData={getPackagingsData} rowId={i._id} />
          <ButtonEdit itemPath="/edit-packaging" itemId={i._id} />
          <ModalProduct packagings={i} />
        </React.Fragment>
      ),
    }));
  };

  useEffect(() => {
    getPackagingsData();
  }, []);

  if (isLoading) return <Loader />;

  return (
    <div>
      <div className="header-cont">
        <h2>Упаковки</h2>
        <Button>
          <Link to={'/add-packaging'}>Добавить</Link>
        </Button>
      </div>

      <TableComp
        items={[{ title: 'id' }, { title: 'Название' }, { title: 'Вес' }, { title: 'Действия' }]}
        dataSource={packagings}
      />
    </div>
  );
}
