import React, { useState, useEffect } from 'react';
import imginp from '../../../img/imageInp.svg';
import { CloseOutlined } from '@ant-design/icons';

import './AddCompletedProject.scss';
import { addCompletedProject, getCategories, getSections } from '../../../Api';
import { useNavigate } from 'react-router-dom';
import { Button, Image, notification } from 'antd';
import SelectOption from '../../SelectOption/SelectOption';

export default function AddCompletedProject() {
  const [name, setName] = useState('');
  const [sectionId, setSectionId] = useState('');
  const [categoryId, setCategoryId] = useState('');
  const [categories, setCategroies] = useState([]);
  const [sections, setSections] = useState([]);
  const [info, setInfo] = useState('');
  const [param1, setParam1] = useState('');
  const [param2, setParam2] = useState('');
  const [img, setImg] = useState('');
  const [data, setData] = useState('');
  const navigate = useNavigate();

  const uploadImage = async (e) => {
    const file = e.target.files[0];
    const base64 = await convertBase64(file);
    setImg(base64);
  };

  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);

      fileReader.onload = () => {
        resolve(fileReader.result);
      };

      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };
  useEffect(() => {
    getCategoryData();
  }, []);

  const getCategoryData = async () => {
    const data = await getCategories();
    const data2 = await getSections();
    reformedRoletDataForTable(data, data2);
  };
  const reformedRoletDataForTable = (data, data2) => {
    setCategroies(data);
    setSections(data2);
  };

  useEffect(() => {
    setData({
      name: name,
      info: info,
      parametr1: param1,
      parametr2: param2,
      image: img,
    });
  }, [name, info, param1, param2, img]);

  const openNotificationWithIcon = (type) => {
    notification[type]({
      message: type,
      description: type === 'success' ? 'Добавлено' : 'Ошибка добавления',
    });
  };

  return (
    <>
      <div className="header-cont">
        <h1>Добавить</h1>
        <Button
          onClick={() => {
            navigate(-1);
          }}
        >
          Назад
        </Button>
      </div>
      <div className="Product">
        <form>
          <div className="roles">
            <SelectOption
              data={categories}
              placeholder="Раздел"
              setId={setCategoryId}
              currentId={categoryId}
            />

            <SelectOption data={sections} placeholder="Секция" setId={setSectionId} currentId={sectionId} />
          </div>
          <input
            type="text"
            placeholder="Название"
            onChange={(e) => setName(e.target.value)}
            value={name || ''}
          />
          <input
            type="text"
            placeholder="Параметр 1"
            onChange={(e) => setParam1(e.target.value)}
            value={param1 || ''}
          />
          <input
            type="text"
            placeholder="Параметр 2"
            onChange={(e) => setParam2(e.target.value)}
            value={param2 || ''}
          />
          <textarea
            rows="4"
            cols="50"
            type="text"
            placeholder="Описание"
            onChange={(e) => setInfo(e.target.value)}
            value={info || ''}
            style={{ padding: '.5rem', marginBottom: '1rem' }}
          />
          <div className="product-img">
            <h2>Фото</h2>
            {img ? <CloseOutlined onClick={() => setImg('')} className="deletFoto" /> : ''}
            <Image src={img} alt="" />
          </div>
          <label className="Image" htmlFor="file">
            <img src={imginp} alt="" /> Загрузить фото
          </label>
          <input
            id="file"
            type="file"
            onChange={(e) => {
              uploadImage(e);
            }}
          />
          <input
            onClick={(e) => {
              e.preventDefault();
              addCompletedProject(data, openNotificationWithIcon);
            }}
            className="Submit"
            value={'Добавить'}
            type={'submit'}
          />
        </form>
      </div>
    </>
  );
}
