import React, { useEffect, useState } from 'react';
import { getThicknesses } from '../../Api';
import { Button, notification } from 'antd';
import ModalThickness from '../../Components/Thickness/ModalThickness/Modal';
import TableComp from '../../Components/Table/TableComp';
import ModalDelet from '../../Components/Thickness/ModalDeletThickness/ModalDelet';
import { Link } from 'react-router-dom';
import ButtonEdit from '../../Components/ButtonEdit/ButtonEdit';
import './Thickness.scss';
import { Loader } from '../../Components/Loader/Loader';

export default function Thickness() {
  const [thickness, setThickness] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const getThicknessesData = async () => {
    setIsLoading(true);
    const data = await getThicknesses();
    const NewFractions = reformedFractionsDataForTable(data);
    setThickness(NewFractions);
    setIsLoading(false);
  };

  const openNotificationWithIcon = (type) => {
    notification[type]({
      message: type,
      description: type === 'success' ? 'Удалено' : 'Ошибка удаления',
    });
  };

  const reformedFractionsDataForTable = (data) => {
    if (!data?.length) return [];
    return data.map((i, index) => ({
      ...i,
      id: index + 1,
      размер: i.size,
      Действия: (
        <React.Fragment key={index}>
          <ModalDelet deleteInfo={openNotificationWithIcon} deleteData={getThicknessesData} rowId={i._id} />
          <ButtonEdit itemPath="/edit-thickness" itemId={i._id} />
          <ModalThickness thickness={i} />
        </React.Fragment>
      ),
    }));
  };

  useEffect(() => {
    getThicknessesData();
  }, []);

  if (isLoading) return <Loader />;

  return (
    <div>
      <div className="header-cont">
        <h2>Толщина</h2>
        <Button>
          <Link to={'/add-thickness'}>Добавить</Link>
        </Button>
      </div>

      <TableComp
        items={[{ title: 'id' }, { title: 'размер' }, { title: 'Действия' }]}
        dataSource={thickness}
      />
    </div>
  );
}
