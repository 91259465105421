import React, { useEffect, useState } from 'react';
import { getStocks } from '../../Api';
import { Button, notification, Image } from 'antd';
import ModalProduct from '../../Components/Stocks/ModalStocks/Modal';
import TableComp from '../../Components/Table/TableComp';
import ModalDelet from '../../Components/Stocks/ModalDeletStocks/ModalDelet';
import { Link } from 'react-router-dom';
import ButtonEdit from '../../Components/ButtonEdit/ButtonEdit';
import './Stocks.scss';
import { Loader } from '../../Components/Loader/Loader';

export default function Stocks() {
  const [stock, setStock] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  // console.log(stock);

  const getStockData = async () => {
    setIsLoading(true);
    const data = await getStocks();
    const NewStock = reformedStockDataForTable(data);
    setStock(NewStock);
    setIsLoading(false);
  };

  const openNotificationWithIcon = (type) => {
    notification[type]({
      message: type,
      description: type === 'success' ? 'Удалено' : 'Ошибка удаления',
    });
  };

  const reformedStockDataForTable = (data) => {
    if (!data?.length) return [];
    return data.map((i, index) => ({
      ...i,
      id: index + 1,
      Название: i.name,
      Информация: i.info,
      Фото: (
        <div className="img-table" key={index} style={{ margin: '0 auto' }}>
          <Image src={i.image} />
        </div>
      ),
      Действия: (
        <React.Fragment key={index}>
          <ModalDelet deleteInfo={openNotificationWithIcon} deleteData={getStockData} rowId={i._id} />
          <ButtonEdit itemPath="/edit-stock" itemId={i._id} />
          <ModalProduct stocks={i} />
        </React.Fragment>
      ),
    }));
  };

  useEffect(() => {
    getStockData();
  }, []);

  if (isLoading) return <Loader />;

  return (
    <div>
      <div className="header-cont">
        <h2>Акции</h2>
        <Button>
          <Link to={'/add-stock'}>Добавить</Link>
        </Button>
      </div>

      <TableComp
        items={[
          { title: 'id' },
          { title: 'Фото' },
          { title: 'Название' },
          { title: 'Информация' },
          { title: 'Действия' },
        ]}
        dataSource={stock}
      />
    </div>
  );
}
