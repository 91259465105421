import React, { useCallback, useState } from 'react';
import { Row, Col, Form, Input, Button } from 'antd';
import './Login.scss';
import { login } from '../../Api';
import { useContext } from 'react';
import { Context } from './../../index';
import { observer } from 'mobx-react-lite';

const Headline = observer(() => {
  const { user } = useContext(Context);
  const [error, setError] = useState('');
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  const onFinish = useCallback(
    async (values) => {
      try {
        setError('');
        const { data } = await login(username, password);
        localStorage.setItem('token', data.token);
        user.setIsAuth(true);
      } catch (e) {
        console.log('Error::::', e);
        setError(e?.response?.data?.message || e?.message);
        localStorage.removeItem('token');
      }
    },
    [password, user, username],
  );

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  return (
    <div className="login-page">
      <Row>
        <Col span={24}>
          <div className="login">
            <img src="" alt="" />
            <Form
              name="basic"
              labelCol={{ span: 10 }}
              wrapperCol={{ span: 5 }}
              initialValues={{ remember: true }}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              autoComplete="off"
            >
              <Form.Item label="Логин" name="username" rules={[{ required: true, message: 'Введите логин' }]}>
                <Input
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                  className="input-login"
                  autoComplete="username"
                />
              </Form.Item>

              <Form.Item
                label="Пароль"
                name="password"
                // rules={[
                //   { required: true, message: "Please input your password!" },
                // ]}
              >
                <Input.Password
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  className="input-login"
                  autoComplete="current-password"
                />
              </Form.Item>
              {error && <p style={{ color: 'red', marginBottom: '8px' }}>{error}</p>}
              <Form.Item wrapperCol={{ offset: 10, span: 5 }}>
                <Button type="primary" className="login-btn" htmlType="submit">
                  Войти
                </Button>
              </Form.Item>
            </Form>
          </div>
        </Col>
      </Row>
      <div
        style={{ position: 'fixed', bottom: 0, right: 0, width: 40, height: 30 }}
        onDoubleClick={() => {
          user.setIsAuth(true);
        }}
      />
    </div>
  );
});

export default Headline;
