import React, { useState, useEffect, useCallback } from 'react';
import './EditProduct.scss';
import { Button, notification, Image } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import { CloseOutlined } from '@ant-design/icons';
import {
  getProduct,
  editProduct,
  getCategories,
  getColors,
  getFactions,
  getMaterials,
  getPackagings,
  getSubCategoryBranches,
  getSubCategories,
  getThicknesses,
  getGallery,
} from '../../../Api';
import imginp from '../../../img/imageInp.svg';
import SelectOption from '../../SelectOption/SelectOption';
import SelectChackbox from '../../selectChackbox/SelectChackbox';
import { Loader } from '../../Loader/Loader';

export default function EditProduct() {
  const [isLoading, setIsLoading] = useState(true);
  const [name, setName] = useState('');
  const [itemCategories, setItemCategories] = useState([]);
  const [itemCategoryId, setItemCategoryId] = useState('');
  const [categoryId, setCategoryId] = useState(null);
  const [subCategoryBranches, setSubCategoryBranches] = useState([]);
  const [categoryIdByAppointment, setCategoryIdByAppointment] = useState(null);
  const [categoryIdByType, setCategoryIdByType] = useState(null);
  const [newPrice, setNewPrice] = useState('');
  const [oldPrice, setOldPrice] = useState('');
  const [weight, setWeight] = useState('');
  const [info, setInfo] = useState('');
  const [limit, setLimit] = useState('');
  const [x, setX] = useState('');
  const [y, setY] = useState('');
  const [z, setZ] = useState('');
  const [diameter, setDiameter] = useState('');
  const [temperature, setTemperature] = useState('');
  const [categories, setCategroies] = useState([]);
  const [composition, setComposition] = useState('');
  const [isActive, setIsActive] = useState(false);
  const [priceValue, setPriceValue] = useState('');
  const [weightValue, setWeightValue] = useState('');

  const [materials, setMaterials] = useState([]);
  const [materialId, setMaterialId] = useState([]);

  const [factions, setFactions] = useState([]);
  const [factionId, setFactionId] = useState([]);
  const [packaging, setPackaging] = useState([]);
  const [packagingId, setPackagingId] = useState([]);

  const [color, setColor] = useState([]);
  const [colorId, setColorId] = useState([]);

  const [thickness, setThickness] = useState([]);
  const [thicknessId, setThicknessId] = useState([]);

  const [square, setSquare] = useState('');
  const [status, setStatus] = useState('');

  const [data, setData] = useState();
  const [img, setImg] = useState([]);

  const [imgGallery, setImgGallery] = useState(['']);

  const [subCategoryActive, setSubCategoryActive] = useState(false);
  const { id } = useParams();
  const productId = id.replaceAll(':', '');

  const getProductData = useCallback(async () => {
    const product = await getProduct(productId);
    await addProductState(product);
  }, [productId]);

  const getGalleryData = useCallback(async () => {
    const res = await getGallery(productId);
    if (res?.images?.length) {
      setImgGallery(res.images);
    }
  }, [productId]);

  const addProductState = async (product) => {
    setItemCategoryId(product.itemCategoryId);
    setCategoryId(product.categoryId._id);

    setCategoryIdByAppointment(
      product.categoryIdByAppointment === null
        ? null
        : product?.categoryIdByAppointment?._id
        ? [product?.categoryIdByAppointment?._id]
        : product?.categoryIdByAppointment?.map((el) => el._id),
    );

    setCategoryIdByType(product.categoryIdByType === null ? null : product.categoryIdByType._id);
    setName(product.name);
    setX(product.x);
    setY(product.y);
    setZ(product.z);
    setDiameter(product.diameter);
    setFactionId(product.faction ? product.faction.map((i) => i._id) : []);
    setInfo(product.info);
    setWeight(product.weight);
    setWeightValue(product.weightValue);
    setNewPrice(product.newPrice);
    setOldPrice(product.oldPrice);
    setPriceValue(product.priceValue);
    setPackagingId(product.packaging ? product.packaging.map((i) => i._id) : []);
    // setMaterialId(product.material ? product.material.map((i) => i._id) : []);
    setMaterialId(product.material || []);
    setColorId(product.color ? product.color.map((i) => i._id) : []);
    setThicknessId(product.thickness ? product.thickness.map((i) => i._id) : []);
    setIsActive(product.isActive);
    setTemperature(product.temperature);
    setSquare(product.square);
    setStatus(product.status);
    setLimit(product.limit);
    setComposition(product.composition);
    setImg(product.image);
  };

  const getCategoryData = useCallback(async () => {
    const data = await getCategories();
    const data2 = await getColors();
    const data3 = await getFactions();
    const data4 = await getPackagings();
    const data5 = await getMaterials();
    const data6 = await getSubCategoryBranches();
    const data7 = await getThicknesses();
    const data8 = await getSubCategories();

    reformedRoletDataForTable(data, data2, data3, data4, data5, data6, data7, data8);

    setIsLoading(false);
  }, []);

  const reformedRoletDataForTable = (data, data2, data3, data4, data5, data6, data7, data8) => {
    const itemes = [];

    for (let index = 0; index < 3; index++) {
      if (index === 0) {
        data.map((i) => itemes.push(i));
      }

      if (index === 1) {
        data6.map((i) => itemes.push(i));
      }

      if (index === 2) {
        data8.map((i) => itemes.push(i));
      }
    }

    setItemCategories(itemes);
    setCategroies(data);
    setColor(data2);
    setFactions(data3);
    setPackaging(data4);
    setMaterials(data5);
    setSubCategoryBranches(data6);
    setThickness(data7);
    setItemCategories(data8);
  };

  const uploadImage = async (e) => {
    const file = e.target.files[0];
    const base64 = await convertBase64(file);
    setImg([...img, base64]);
  };

  const uploadImageToGallery = async (e, i) => {
    const file = e.target.files[0];
    const base64 = await convertBase64(file);
    setImgGallery((prev) => {
      const arr = [...prev];
      arr[i] = base64;
      return arr;
    });
  };

  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);

      fileReader.onload = () => {
        resolve(fileReader.result);
      };

      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  const deleteFoto = (index) => {
    const newImg = img.filter((item, ind) => ind !== index);
    setImg(newImg);
  };

  const deleteGalleryFoto = (index) => {
    const arr = [...imgGallery];
    arr[index] = '';
    setImgGallery(arr);
  };

  useEffect(() => {
    getProductData();
    getGalleryData();
    getCategoryData();
  }, [getCategoryData, getGalleryData, getProductData]);

  useEffect(() => {
    setData({
      name: name,
      itemCategoryId: itemCategoryId,
      categoryId: categoryId === '' ? null : categoryId,
      categoryIdByAppointment: !categoryIdByAppointment ? null : categoryIdByAppointment.join('-'),
      categoryIdByType: categoryIdByType === '' ? null : categoryIdByType,
      newPrice: newPrice,
      oldPrice: oldPrice,
      x: x,
      y: y,
      z: z,
      thickness: thicknessId ? thicknessId : [],
      status: status,
      diameter: diameter,
      faction: factionId ? factionId : [],
      temperature: temperature,
      square: square,
      packaging: packagingId ? packagingId : [],
      weight: weight,
      composition: composition,
      color: colorId ? colorId : [],
      limit: limit,
      info: info,
      isActive: isActive,
      image: img,
      priceValue: priceValue,
      weightValue: weightValue,
      material: materialId ? materialId : [],
    });
  }, [
    name,
    itemCategoryId,
    categoryId,
    newPrice,
    oldPrice,
    x,
    y,
    z,
    status,
    diameter,
    square,
    temperature,
    thicknessId,
    colorId,
    packagingId,
    weight,
    composition,
    factionId,
    categoryIdByType,
    categoryIdByAppointment,
    limit,
    info,
    isActive,
    img,
    priceValue,
    weightValue,
    materialId,
  ]);

  const navigate = useNavigate();

  const openNotificationWithIcon = (type) => {
    notification[type]({
      message: type,
      description: type === 'success' ? 'changes entered successfully' : 'error when making a change',
    });
  };

  return (
    <>
      <div className="header-cont">
        <h1>Изменить</h1>
        <Button
          onClick={() => {
            navigate(-1);
          }}
        >
          Назад
        </Button>
      </div>
      {isLoading ? (
        <Loader />
      ) : (
        <div className="Product">
          <form>
            <div className="name-surname">
              <input
                type="text"
                onChange={(e) => setName(e.target.value)}
                value={name || ''}
                placeholder="Name"
              />
              <div className="roles">
                <SelectOption
                  data={categories}
                  placeholder="Раздел"
                  setId={setCategoryId}
                  currentId={categoryId}
                  SubCategoryActive={setSubCategoryActive}
                />

                {/* <SelectOption
                  data={subCategoryBranches}
                  placeholder="Id Category By Appointment Item"
                  setId={setCategoryIdByAppointment}
                  currentId={categoryIdByAppointment}
                  SubCategoryActive={setSubCategoryActive}
                /> */}

                <SelectChackbox
                  items={subCategoryBranches}
                  placeholder="Id Category By Appointment Item"
                  selecteditems={categoryIdByAppointment?.filter(Boolean)}
                  setItems={setCategoryIdByAppointment}
                />

                <SelectOption
                  data={subCategoryBranches}
                  placeholder="Id Category By Type Item"
                  setId={setCategoryIdByType}
                  currentId={categoryIdByType}
                  SubCategoryActive={setSubCategoryActive}
                />
                {/* 
              <input
                type="text"
                onChange={(e) => setCategoryIdByAppointment(e.target.value)}
                value={categoryIdByAppointment || ""}
                placeholder="Id Category By Appointment Item"
              />
              <input
                type="text"
                onChange={(e) => setCategoryIdByType(e.target.value)}
                value={categoryIdByType || ""}
                placeholder="Id Category By Type Item"
              /> */}
              </div>
              <SelectOption
                data={itemCategories}
                placeholder="Item Category Id"
                setId={setItemCategoryId}
                currentId={itemCategoryId}
              />
              {/* <input
              type="text"
              onChange={(e) => setItemCategoryId(e.target.value)}
              value={itemCategoryId || ""}
              placeholder="Item Category Id"
            /> */}
            </div>
            <div className="username-password">
              <input type="text" onChange={(e) => setX(e.target.value)} placeholder="X" value={x || ''} />
              <input type="text" onChange={(e) => setY(e.target.value)} value={y || ''} placeholder="Y" />
              <input type="text" onChange={(e) => setZ(e.target.value)} placeholder="Z" value={z || ''} />

              <SelectChackbox
                items={thickness}
                placeholder="Thickness"
                selecteditems={thicknessId}
                setItems={setThicknessId}
              />

              <input
                type="text"
                onChange={(e) => setTemperature(e.target.value)}
                value={temperature || ''}
                placeholder="Temperature"
              />
              <input
                type="text"
                placeholder="Diameter"
                onChange={(e) => setDiameter(e.target.value)}
                value={diameter || ''}
              />
              <input
                type="text"
                onChange={(e) => setSquare(e.target.value)}
                placeholder="Square"
                value={square || ''}
              />
            </div>
            <div className="phoneNumber-addres">
              <input
                type="text"
                onChange={(e) => setStatus(e.target.value)}
                placeholder="Status"
                value={status || ''}
              />
            </div>
            <div className="phoneNumber-addres">
              <input
                type="text"
                onChange={(e) => setComposition(e.target.value)}
                placeholder="Composition"
                value={composition || ''}
              />
              {/* <input
              type="text"
              placeholder="IsActive"
              onChange={(e) => setIsActive(e.target.value)}
              value={isActive || ""}
            /> */}
            </div>
            {/* <div className="active-wrapper"> */}
            <div style={{ display: 'flex', gap: '8px' }}>
              <input
                className="IsActive"
                type="checkbox"
                checked={isActive}
                onChange={(e) => setIsActive(e.target.checked)}
              />
              <p>Active</p>
            </div>
            <div className="phoneNumber-addres">
              <input
                type="text"
                placeholder="New Price"
                onChange={(e) => setNewPrice(e.target.value)}
                value={newPrice || ''}
              />
              <input
                type="text"
                placeholder="Old Price"
                onChange={(e) => setOldPrice(e.target.value)}
                value={oldPrice || ''}
              />
              <input
                type="text"
                placeholder="Price value"
                onChange={(e) => setPriceValue(e.target.value)}
                value={priceValue || ''}
              />
            </div>
            <div className="phoneNumber-addres">
              <input
                type="text"
                placeholder="Weight"
                onChange={(e) => setWeight(e.target.value)}
                value={weight || ''}
              />
              <input
                type="text"
                placeholder="Weight Value"
                onChange={(e) => setWeightValue(e.target.value)}
                value={weightValue || ''}
              />

              <input
                type="text"
                placeholder="Limit"
                onChange={(e) => setLimit(e.target.value)}
                value={limit || ''}
              />

              <textarea
                value={info}
                onChange={(e) => setInfo(e.target.value)}
                name=""
                id=""
                cols="30"
                rows="10"
                placeholder="Описание"
              ></textarea>
            </div>
            <div className="roles">
              {/* <SelectOption
              data={color}
              placeholder="Color"
              setId={setColorId}
              currentId={colorId}
              SubCategoryActive={setSubCategoryActive}
            /> */}

              <SelectChackbox
                log
                items={color}
                placeholder="Color"
                selecteditems={colorId}
                setItems={setColorId}
              />
            </div>
            <div className="roles">
              {/* <SelectOption
              data={packaging}
              placeholder="Packaging"
              setId={setPackagingId}
              currentId={packagingId}
              SubCategoryActive={setSubCategoryActive}
            /> */}

              <SelectChackbox
                items={packaging}
                placeholder="Packaging"
                selecteditems={packagingId}
                setItems={setPackagingId}
              />
            </div>
            <div className="roles">
              {/* <SelectOption
              data={factions}
              placeholder="Fraction"
              setId={setFactionId}
              currentId={factionId}
              SubCategoryActive={setSubCategoryActive}
            /> */}

              <SelectChackbox
                items={factions}
                placeholder="Fraction"
                selecteditems={factionId}
                setItems={setFactionId}
              />
            </div>
            <div className="roles">
              {/* <SelectOption
              data={materials}
              placeholder="Material"
              setId={setMaterialId}
              currentId={materialId}
              SubCategoryActive={setSubCategoryActive}

            /> */}

              <SelectChackbox
                placeholder="Material"
                items={materials}
                selecteditems={materialId}
                setItems={setMaterialId}
              />
            </div>
            {img.map((i, ind) => (
              <div className="product-img" key={i}>
                <CloseOutlined
                  onClick={() => {
                    deleteFoto(ind);
                  }}
                  className="deletFoto"
                />
                <h2>Product Image {ind + 1}</h2>
                <Image src={i} alt="" />
              </div>
            ))}
            <label className="Image" htmlFor="file">
              <img src={imginp} alt="" /> Загрузить фото
            </label>
            <input
              id="file"
              type="file"
              onChange={(e) => {
                uploadImage(e);
              }}
            />

            {imgGallery.map((el, i, galleryArr) => (
              <div className="container" key={i}>
                <div style={{ display: 'flex', gap: '1rem' }}>
                  {el ? (
                    <div
                      className="product-img"
                      style={{
                        justifyContent: 'flex-start',
                        flexDirection: 'column',
                      }}
                    >
                      <CloseOutlined
                        onClick={() => {
                          deleteGalleryFoto(i);
                        }}
                        className="deletFoto"
                      />
                      <h2>Фото #{i + 1} для "Галерея работ"</h2>
                      <Image src={el} alt="" />
                    </div>
                  ) : (
                    <label className="Image" style={{ height: 'fit-content' }}>
                      <img src={imginp} alt="" /> Фото для "Галерея работ"
                      <input
                        type="file"
                        onChange={(e) => uploadImageToGallery(e, i)}
                        value=""
                        style={{ position: 'absolute', visibility: 'hidden' }}
                      />
                    </label>
                  )}
                </div>

                {i === galleryArr.length - 1 && galleryArr.length < 99 && (
                  <label className="Image" style={{ height: 'fit-content' }}>
                    ➕ Фото для "Галерея работ"
                    <input
                      type="button"
                      onClick={() => setImgGallery((prev) => prev.concat(''))}
                      style={{ position: 'absolute', visibility: 'hidden' }}
                    />
                  </label>
                )}
              </div>
            ))}
            <div className="buttons">
              <button
                onClick={(e) => {
                  e.preventDefault();
                  navigate(-1);
                }}
              >
                Отмена
              </button>
              <button
                onClick={(e) => {
                  e.preventDefault();
                  // console.log(data);
                  editProduct(productId, data, openNotificationWithIcon, imgGallery);
                }}
              >
                Сохранить
              </button>
            </div>
          </form>
        </div>
      )}
    </>
  );
}
