import React, { useState, useEffect } from 'react';
import './EditSubCategoryBranches.scss';
import { Button, notification } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import { getSubCategoryBranch, editSubCategoryBranches, getSubCategories } from '../../../Api';
import SelectOption from '../../SelectOption/SelectOption';

export default function EditSubCategoryBranches() {
  const [name, setName] = useState('');
  const [itemCategoryId, setItemCategoryId] = useState('');
  const [categoryId, setCategoryId] = useState('');
  const [categories, setCategroies] = useState([]);
  const [value, setValue] = useState('');
  const [data, setData] = useState();
  const { id } = useParams();
  const productId = id.replaceAll(':', '');

  useEffect(() => {
    getProductData();
    getgetSubCategoryBranchesData();
  }, [productId]);

  const getProductData = async () => {
    const product = await getSubCategoryBranch(productId);
    addProductState(product);
  };

  useEffect(() => {
    if (categories.length && categoryId) {
      setValue(categories.find((item) => item._id === categoryId).name);
    }
  }, [categories, categoryId]);

  const addProductState = (subCategoryBranches) => {
    setItemCategoryId(subCategoryBranches.itemCategoryId);
    setCategoryId(subCategoryBranches.subCategoryId);
    setName(subCategoryBranches.name);
  };
  const getgetSubCategoryBranchesData = async () => {
    const data = await getSubCategories();
    reformedRoletDataForTable(data);
  };

  const reformedRoletDataForTable = (data) => {
    setCategroies(data);
  };

  useEffect(() => {
    setData({
      name: name,
      itemCategoryId: itemCategoryId,
      categoryId: categoryId,
    });
  }, [name, itemCategoryId, categoryId]);
  const navigate = useNavigate();

  const openNotificationWithIcon = (type) => {
    notification[type]({
      message: type,
      description: type === 'success' ? 'changes entered successfully' : 'error when making a change',
    });
  };

  return (
    <>
      <div className="header-cont">
        <h1>Изменить</h1>
        <Button
          onClick={() => {
            navigate(-1);
          }}
        >
          Назад
        </Button>
      </div>
      <div className="Product">
        <form>
          <div className="name-surname">
            <input type="text" onChange={(e) => setName(e.target.value)} value={name || ''} />
          </div>
          <div className="roles">
            <SelectOption
              data={categories}
              placeholder="Раздел"
              setId={setCategoryId}
              currentId={categoryId}
            />
          </div>
          <div className="buttons">
            <button
              onClick={(e) => {
                e.preventDefault();
                navigate(-1);
              }}
            >
              Отмена
            </button>
            <button
              onClick={(e) => {
                e.preventDefault();
                editSubCategoryBranches(productId, data, openNotificationWithIcon);
              }}
            >
              Сохранить
            </button>
          </div>
        </form>
      </div>
    </>
  );
}
