import React, { useState } from 'react';
import { Modal, Button, Row, Col } from 'antd';
import './Modal.scss';
import PropTypes from 'prop-types';
import { BsInfoCircle } from 'react-icons/bs';

const ModalUsers = (props) => {
  const { category } = props;
  const [isModalVisible, setIsModalVisible] = useState(false);
  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <>
      <Button className="modal" type="primary" onClick={showModal} title="Подробнее">
        {/* Подробнее */}
        {/* <RiInformationLine />
        <IoIosInformationCircle /> */}
        <BsInfoCircle />
      </Button>
      <Modal
        title="Раздел"
        className="admin-modal"
        open={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        cancelText="Отмена"
      >
        <Row>
          <Col span={24}>
            <div className="avatar-list">
              <ul>
                <li>
                  <b>Название: {category.name}</b>
                </li>
                <li>
                  <b>ID: {category._id}</b>
                </li>
              </ul>
            </div>
          </Col>
        </Row>
      </Modal>
    </>
  );
};

export default ModalUsers;

ModalUsers.propTypes = {
  category: PropTypes.shape({
    name: PropTypes.string.isRequired,
    _id: PropTypes.string.isRequired,
  }),
};

ModalUsers.defaultProps = {
  name: '',
  _id: '',
};
