import React, { useState, useEffect } from 'react';
import './EditReviews.scss';
import imginp from '../../../img/imageInp.svg';
import { Button, Image, notification } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import { CloseOutlined } from '@ant-design/icons';
import { getreview, editreview } from '../../../Api';

export default function EditReviews() {
  const [name, setName] = useState();
  const [image, setImage] = useState('');
  const [info, setInfo] = useState('');
  const { id } = useParams();
  const reviewsId = id.replaceAll(':', '');
  const [data, setData] = useState();
  useEffect(() => {
    getReviewsData();
  }, [reviewsId]);

  const getReviewsData = async () => {
    const reviews = await getreview(reviewsId);
    addReviewsState(reviews);
  };

  const addReviewsState = (reviews) => {
    setName(reviews.name);
    setImage(reviews.image);
    setInfo(reviews.info);
  };

  useEffect(() => {
    setData({ name: name, info, image: image });
  }, [name, info, image]);

  const navigate = useNavigate();

  const uploadImage = async (e) => {
    const file = e.target.files[0];
    const base64 = await convertBase64(file);
    setImage(base64);
  };

  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);

      fileReader.onload = () => {
        resolve(fileReader.result);
      };

      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  const openNotificationWithIcon = (type) => {
    notification[type]({
      message: type,
      description: type === 'success' ? 'changes entered successfully' : 'error when making a change',
    });
  };

  return (
    <>
      <div className="header-cont">
        <h1>Изменить</h1>
        <Button
          onClick={() => {
            navigate(-1);
          }}
        >
          Назад
        </Button>
      </div>

      <div className="Product">
        <form>
          <input
            className="Name"
            placeholder="Имя"
            type="text"
            onChange={(e) => setName(e.target.value)}
            value={name || ''}
          />
          <textarea
            rows="4"
            cols="50"
            type="text"
            placeholder="Описание"
            onChange={(e) => setInfo(e.target.value)}
            value={info || ''}
            style={{ marginBottom: '1rem', padding: '0.5rem' }}
          />
          <div className="product-img">
            <h2>Фото</h2>
            {image ? <CloseOutlined onClick={() => setImage('')} className="deletFoto" /> : ''}
            <Image src={image} alt="" />
          </div>
          <label className="Image" htmlFor="file">
            <img src={imginp} alt="" /> Загрузить фото
          </label>
          <input
            id="file"
            type="file"
            onChange={(e) => {
              uploadImage(e);
            }}
          />
          <div className="buttons">
            <input
              onClick={(e) => {
                e.preventDefault();
                navigate(-1);
              }}
              className="Submit"
              value="Отмена"
              style={{ marginRight: '1rem' }}
              type={'submit'}
            />

            <input
              onClick={(e) => {
                e.preventDefault();
                editreview(reviewsId, data, openNotificationWithIcon);
              }}
              className="Submit"
              value="Сохранить"
              type={'submit'}
            />
          </div>
        </form>
      </div>
    </>
  );
}
