import React, { useState, useEffect } from 'react';
import './EditFractions.scss';
import { Button, notification } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import { getFaction, editFaction } from '../../../Api';

export default function EditFractions() {
  const [size, setSize] = useState();
  const { id } = useParams();
  const fractionId = id.replaceAll(':', '');
  const [data, setData] = useState();

  useEffect(() => {
    getFractionData();
  }, [fractionId]);

  const getFractionData = async () => {
    const fraction = await getFaction(fractionId);
    addFractionState(fraction);
  };

  const addFractionState = (fraction) => {
    setSize(fraction.size);
  };

  useEffect(() => {
    setData({ size });
  }, [size]);

  const navigate = useNavigate();

  const openNotificationWithIcon = (type) => {
    notification[type]({
      message: type,
      description: type === 'success' ? 'changes entered successfully' : 'error when making a change',
    });
  };

  return (
    <>
      <div className="header-cont">
        <h1>Изменить</h1>
        <Button
          onClick={() => {
            navigate(-1);
          }}
        >
          Назад
        </Button>
      </div>

      <div className="Product">
        <form>
          <input
            className="Name"
            placeholder="Размер фракции"
            type={'text'}
            onChange={(e) => setSize(e.target.value)}
            value={size || ''}
          />
          <div className="buttons">
            <input
              onClick={(e) => {
                e.preventDefault();
                navigate(-1);
              }}
              className="Submit"
              value={'Отмена'}
              type={'submit'}
              style={{ marginRight: '1rem' }}
            />
            <input
              onClick={(e) => {
                e.preventDefault();
                editFaction(fractionId, data, openNotificationWithIcon);
              }}
              className="Submit"
              value="Сохранить"
              type={'submit'}
            />
          </div>
        </form>
      </div>
    </>
  );
}
