import React, { useState } from 'react';
import { Image, Modal, Button, Row, Col } from 'antd';
import './Modal.scss';
import PropTypes from 'prop-types';
import { BsInfoCircle } from 'react-icons/bs';

const ModalUsers = (props) => {
  const { SimilarProducts } = props;
  const [isModalVisible, setIsModalVisible] = useState(false);
  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <>
      <Button className="modal" type="primary" onClick={showModal} title="Подробнее">
        {/* Подробнее */}
        {/* <RiInformationLine />
        <IoIosInformationCircle /> */}
        <BsInfoCircle />
      </Button>
      <Modal
        title="Product Modal"
        className="admin-modal"
        open={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <Row>
          <Col span={0}>
            <div className="admin-modal-img"></div>
          </Col>
          <Col span={24}>
            <div className="avatar-list">
              {/* <ul>
                <li>
                  <b>Product ID:</b> {SimilarProducts.product =! null ? SimilarProducts.product._id : "-"}
                </li>
                <li>
                  <b>ProductOne ID:</b> {SimilarProducts.productOne =! null ? SimilarProducts.productOne._id : "-"}
                </li>
                <li>
                  <b>ProductTwo ID:</b> {SimilarProducts.productTwo =! null ? SimilarProducts.productTwo._id : "-"}
                </li>
                <li>
                  <b>ProductOne ID:</b> {SimilarProducts.productThree =! null ? SimilarProducts.productThree._id : "-"}
                </li>
                <li>
                  <b>ProductTwo ID:</b> {SimilarProducts.productFour =! null ? SimilarProducts.productFour._id : "-"}
                </li>
              </ul> */}
              <ul>
                <li>
                  <b>Product ID:</b> {SimilarProducts.product._id}
                </li>
                <li>
                  <b>ProductOne ID:</b> {SimilarProducts.productOne._id}
                </li>
                <li>
                  <b>ProductTwo ID:</b> {SimilarProducts.productTwo._id}
                </li>
                <li>
                  <b>ProductOne ID:</b> {SimilarProducts.productThree._id}
                </li>
                <li>
                  <b>ProductTwo ID:</b> {SimilarProducts.productFour._id}
                </li>
              </ul>
            </div>
          </Col>
        </Row>
      </Modal>
    </>
  );
};

export default ModalUsers;

ModalUsers.propTypes = {
  product: PropTypes.shape({
    name: PropTypes.string.isRequired,
    price: PropTypes.string.isRequired,
    img: PropTypes.string.isRequired,
  }),
};
