import React, { useEffect, useState } from 'react';
import { getCompletedProjects } from '../../Api';
import { Image, Button, notification } from 'antd';
import ModalProduct from '../../Components/CompletedProjects/ModalCompletedProject/Modal';
import TableComp from '../../Components/Table/TableComp';
import ModalDelet from '../../Components/CompletedProjects/ModalDeletCompletedProject/ModalDelet';
import { Link } from 'react-router-dom';
import ButtonEdit from '../../Components/ButtonEdit/ButtonEdit';
import './CompletedProjects.scss';
import { Loader } from '../../Components/Loader/Loader';

export default function CompletedProjects() {
  const [completedprojects, setCompletedProject] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const getCompletedProjectData = async () => {
    setIsLoading(true);
    const data = await getCompletedProjects();
    const NewCompletedProjects = reformedCompletedProjectDataForTable(data);
    setCompletedProject(NewCompletedProjects);
    setIsLoading(false);
  };

  const openNotificationWithIcon = (type) => {
    notification[type]({
      message: type,
      description: type === 'success' ? 'Удалено' : 'Ошибка удаления',
    });
  };

  const reformedCompletedProjectDataForTable = (data) => {
    if (!data?.length) return [];
    return data.map((i, index) => ({
      ...i,
      id: index + 1,
      'Название проекта': i.name,
      'Выполненные работы': (
        <p>
          {i.parametr1} <br /> {i.parametr2}
        </p>
      ),
      key: index,
      Фото: (
        <div className="img-table" key={index} style={{ margin: '0 auto' }}>
          <Image src={i.image?.replace('188.225.36.154:3500', 'апи.камень-газон.рф')} />
        </div>
      ),
      Действия: (
        <React.Fragment key={index}>
          <ModalDelet
            deleteInfo={openNotificationWithIcon}
            deleteData={getCompletedProjectData}
            rowId={i._id}
          />
          <ButtonEdit itemPath="/edit-completedprojects" itemId={i._id} />
          <ModalProduct completedprojects={i} />
        </React.Fragment>
      ),
    }));
  };

  useEffect(() => {
    getCompletedProjectData();
  }, []);

  if (isLoading) return <Loader />;

  return (
    <div>
      <div className="header-cont">
        <h2>Завершённые Проекты</h2>
        <Button>
          <Link to={'/add-completedprojects'}>Добавить</Link>
        </Button>
      </div>

      <TableComp
        items={[
          { title: 'id' },
          { title: 'Фото' },
          { title: 'Название проекта' },
          { title: 'Выполненные работы' },
          { title: 'Действия' },
        ]}
        dataSource={completedprojects}
      />
    </div>
  );
}
