import React, { useState, useEffect } from 'react';
import './EditStocks.scss';
import { Button, notification } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import { getStock, editStock } from '../../../Api';
import { Image } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import imginp from '../../../img/imageInp.svg';

export default function EditStocks() {
  const [name, setName] = useState();
  const [info, setInfo] = useState();
  const { id } = useParams();
  const stockId = id.replaceAll(':', '');
  const [data, setData] = useState();
  const [img, setImg] = useState(null);

  useEffect(() => {
    getStockData();
  }, [stockId]);

  const getStockData = async () => {
    const stock = await getStock(stockId);
    addStockState(stock);
  };

  const addStockState = (stock) => {
    setName(stock.name);
    setInfo(stock.info);
    setImg(stock.image);
  };

  const uploadImage = async (e) => {
    const file = e.target.files[0];
    const base64 = await convertBase64(file);
    setImg(base64);
  };

  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);

      fileReader.onload = () => {
        resolve(fileReader.result);
      };

      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  const deleteFoto = () => {
    setImg(null);
  };

  useEffect(() => {
    setData({ name: name, info: info, image: img });
  }, [name, info, img]);

  const navigate = useNavigate();

  const openNotificationWithIcon = (type) => {
    notification[type]({
      message: type,
      description: type === 'success' ? 'changes entered successfully' : 'error when making a change',
    });
  };

  return (
    <>
      <div className="header-cont">
        <h1>Изменить</h1>
        <Button
          onClick={() => {
            navigate(-1);
          }}
        >
          Назад
        </Button>
      </div>

      <div className="Product">
        <form>
          <input
            className="Name"
            placeholder="Название"
            type={'text'}
            onChange={(e) => setName(e.target.value)}
            value={name || ''}
          />
          <textarea
            rows="4"
            cols="50"
            type="text"
            placeholder="Описание"
            onChange={(e) => setInfo(e.target.value)}
            value={info || ''}
            style={{ padding: '.5rem', marginBottom: '1rem' }}
          />
          <div className="product-img">
            <CloseOutlined
              onClick={() => {
                console.log('clicked');
                deleteFoto();
              }}
              className="deletFoto"
            />
            <h2>Фото</h2>
            <Image src={img} alt="" />
          </div>
          <label className="Image" htmlFor="file">
            <img src={imginp} alt="" /> Загрузить фото
          </label>
          <input
            id="file"
            type="file"
            onChange={(e) => {
              uploadImage(e);
            }}
          />
          <div className="buttons">
            <input
              onClick={(e) => {
                e.preventDefault();
                navigate(-1);
              }}
              className="Submit"
              value="Отмена"
              style={{ marginRight: '1rem' }}
              type={'submit'}
            />

            <input
              onClick={(e) => {
                e.preventDefault();
                editStock(stockId, data, openNotificationWithIcon);
              }}
              className="Submit"
              value="Сохранить"
              type={'submit'}
            />
          </div>
        </form>
      </div>
    </>
  );
}
