import React, { useState, useEffect } from 'react';
import { notification, Button } from 'antd';
import TableComp from '../../Components/Table/TableComp';
import ModalOrder from '../../Components/Order/ModalOrder/Modal';
import ModalDelet from '../../Components/Order/ModalDeletOrdner/ModalDelet';
import ButtonEdit from '../../Components/ButtonEdit/ButtonEdit';
import { Link } from 'react-router-dom';
import { getOrders } from '../../Api';
import './Orders.scss';
import { Loader } from '../../Components/Loader/Loader';

const Orders = () => {
  const [orders, setOrders] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const getOrdersData = async () => {
    setIsLoading(true);
    const data = await getOrders();
    console.log(data);
    const newOrders = reformidOrderDataForTable(data);
    setOrders(newOrders);
    setIsLoading(false);
  };

  const openNotificationWithIcon = (type) => {
    notification[type]({
      message: type,
      description: type === 'success' ? 'Order deleted successfully' : 'Order deletion error',
    });
  };

  const reformidOrderDataForTable = (data) => {
    if (!data?.length) return [];
    return data.map((i, index) => ({
      ...i,
      id: index + 1,
      'ID продукта': i.productId != null ? i.productId._id : '-',
      'Название продукта': i.productId != null ? i.productId.name : '-',
      Количество: i.count,
      key: index,
      Цена: i.newPrice,
      email: i.email,
      Телефон: i.phone,
      Упаковка: i.packagingName,
      Действия: (
        <React.Fragment key={index}>
          <ModalDelet deleteInfo={openNotificationWithIcon} deleteData={getOrdersData} rowId={i._id} />
          <ButtonEdit itemPath="/edit-order" itemId={i._id} />
          <ModalOrder order={i} />
        </React.Fragment>
      ),
    }));
  };

  useEffect(() => {
    getOrdersData();
  }, []);

  if (isLoading) return <Loader />;

  return (
    <>
      <div className="header-cont">
        <h2>Заказы</h2>
        <Button>
          <Link to={'/add-order'}>Добавить</Link>
        </Button>
      </div>
      <TableComp
        items={[
          { title: 'id' },
          { title: 'ID продукта' },
          { title: 'Название продукта' },
          { title: 'Количество' },
          { title: 'Цена' },
          { title: 'email' },
          { title: 'Телефон' },
          { title: 'Упаковка' },
          { title: 'Действия' },
        ]}
        dataSource={orders}
      />
    </>
  );
};

export default Orders;
