import React, { useEffect, useState } from 'react';
import { Button, notification } from 'antd';
import ModalUsers from '../../Components/Category/ModalCategory/Modal';
import './Category.scss';
import TableComp from '../../Components/Table/TableComp';
import ModalDelet from '../../Components/Category/ModalDeletCategory/ModalDelet';
import ButtonEdit from '../../Components/ButtonEdit/ButtonEdit';
import { Link } from 'react-router-dom';
import { getCategories } from '../../Api';
import { Loader } from '../../Components/Loader/Loader';

const Category = () => {
  const [categories, setCategories] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const openNotificationWithIcon = (type) => {
    notification[type]({
      message: type,
      description: type === 'success' ? 'Category deleted successfully' : 'Category deletion error',
    });
  };

  const getCategoryData = async () => {
    setIsLoading(true);
    const data = await getCategories();
    const newCategory = reformidUserDataForTable(data);
    setCategories(newCategory);
    setIsLoading(false);
  };

  const reformidUserDataForTable = (data) => {
    if (!data?.length) return [];
    return data.map((i, index) => ({
      ...i,
      id: index + 1,
      Название: i.name,
      Секция: i.sectionId.name,
      'Section ID': i.sectionId._id,
      Действия: (
        <>
          <ModalDelet
            key={index}
            deleteInfo={openNotificationWithIcon}
            deleteData={getCategoryData}
            rowId={i}
          />
          <ButtonEdit itemPath="/edit-category" itemId={i._id} />
          <ModalUsers category={i} />
        </>
      ),
      'post code': i.postalCode,
    }));
  };

  useEffect(() => {
    getCategoryData();
  }, []);

  if (isLoading) return <Loader />;

  return (
    <>
      <div className="header-cont">
        <h2>Разделы</h2>
        <Button>
          <Link to={'/add-category'}>Добавить</Link>
        </Button>
      </div>
      <TableComp
        items={[{ title: 'id' }, { title: 'Название' }, { title: 'Секция' }, { title: 'Действия' }]}
        dataSource={categories}
      />
    </>
  );
};

export default Category;
