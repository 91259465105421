import React, { useState, useEffect } from 'react';
import './EditCategory.scss';
import { Button, notification } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import { getCategory, editCategory, getSections } from '../../../Api';
import SelectOption from '../../SelectOption/SelectOption';

export default function EditCategory() {
  const [name, setName] = useState('');
  const [sectionId, setSectionId] = useState('');
  const [categories, setCategroies] = useState([]);
  const [value, setValue] = useState('');
  const [data, setData] = useState();
  const { id } = useParams();
  const productId = id.replaceAll(':', '');

  useEffect(() => {
    getProductData();
    getCategoryData();
  }, [productId]);

  const getProductData = async () => {
    const product = await getCategory(productId);
    addProductState(product);
  };

  const addProductState = (category) => {
    setSectionId(category.sectionId);
    setName(category.name);
  };
  const getCategoryData = async () => {
    const data = await getSections();
    reformedRoletDataForTable(data);
  };

  const reformedRoletDataForTable = (data) => {
    setCategroies(data);
  };

  useEffect(() => {
    setData({
      name: name,
      sectionId: sectionId,
    });
  }, [name, sectionId]);
  const navigate = useNavigate();

  const openNotificationWithIcon = (type) => {
    notification[type]({
      message: type,
      description: type === 'success' ? 'changes entered successfully' : 'error when making a change',
    });
  };

  return (
    <>
      <div className="header-cont">
        <h1>Изменить</h1>
        <Button
          onClick={() => {
            navigate(-1);
          }}
        >
          Назад
        </Button>
      </div>
      <div className="Product">
        <form>
          <div className="name-surname">
            <input type="text" onChange={(e) => setName(e.target.value)} value={name || ''} />
          </div>
          <div className="roles">
            <SelectOption data={categories} placeholder="Раздел" setId={setSectionId} currentId={sectionId} />
          </div>
          <div className="buttons">
            <button
              onClick={(e) => {
                e.preventDefault();
                navigate(-1);
              }}
            >
              Cancel
            </button>
            <button
              onClick={(e) => {
                e.preventDefault();
                editCategory(productId, data, openNotificationWithIcon);
              }}
            >
              Сохранить
            </button>
          </div>
        </form>
      </div>
    </>
  );
}
