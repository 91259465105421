import React, { useState, useEffect } from 'react';
import './AddThickness.scss';
import { Button, notification } from 'antd';
import { useNavigate } from 'react-router-dom';
import { addThickness } from '../../../Api';

export default function AddThickness() {
  const [size, setSize] = useState('');
  const navigate = useNavigate();
  const [data, setData] = useState('');

  const openNotificationWithIcon = (type) => {
    notification[type]({
      message: type,
      description: type === 'success' ? 'Size has added successfully' : 'error while adding Size',
    });
  };

  useEffect(() => {
    setData({
      size: size,
    });
  }, [size]);

  return (
    <>
      <div className="header-cont">
        <h1>Add Size</h1>
        <Button
          onClick={() => {
            navigate(-1);
          }}
        >
          Назад
        </Button>
      </div>
      <div className="Product">
        <form>
          <div className="name-surname">
            <input
              type="text"
              placeholder="Толщина"
              onChange={(e) => setSize(e.target.value)}
              value={size || ''}
            />
          </div>

          <div className="buttons">
            <button
              onClick={(e) => {
                e.preventDefault();
                navigate(-1);
              }}
            >
              Отмена
            </button>
            <button
              onClick={(e) => {
                e.preventDefault();
                addThickness(data, openNotificationWithIcon);
              }}
            >
              Добавить
            </button>
          </div>
        </form>
      </div>
    </>
  );
}
