import React, { useState, useEffect, useCallback, useRef } from 'react';
import './AddProduct.scss';
import { Button, notification, Image } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import {
  addProduct,
  getCategories,
  getColors,
  getFactions,
  getMaterials,
  getPackagings,
  getSubCategoryBranches,
  getSubCategories,
  getThicknesses,
} from '../../../Api';
import imginp from '../../../img/imageInp.svg';
import SelectOption from '../../SelectOption/SelectOption';
import SelectChackbox from '../../selectChackbox/SelectChackbox';

export default function AddProduct() {
  const [imgGallery, setImgGallery] = useState(['']);
  const [name, setName] = useState('');
  const [itemCategories, setItemCategories] = useState([]);
  const [itemCategoryId, setItemCategoryId] = useState('');
  const [categoryId, setCategoryId] = useState(null);
  const [subCategoryBranches, setSubCategoryBranches] = useState([]);
  const [categoryIdByAppointment, setCategoryIdByAppointment] = useState(null);
  const [categoryIdByType, setCategoryIdByType] = useState(null);
  const [newPrice, setNewPrice] = useState('');
  const [oldPrice, setOldPrice] = useState('');
  const [weight, setWeight] = useState('');
  const [info, setInfo] = useState('');
  const [limit, setLimit] = useState('');
  const [x, setX] = useState('');
  const [y, setY] = useState('');
  const [z, setZ] = useState('');

  const uploadImageToGallery = async (e, i) => {
    const file = e.target.files[0];
    const base64 = await convertBase64(file);
    setImgGallery((prev) => {
      const arr = [...prev];
      arr[i] = base64;
      return arr;
    });
  };

  const deleteGalleryFoto = (index) => {
    const arr = [...imgGallery];
    arr[index] = '';
    setImgGallery(arr);
  };

  const resetState = useCallback(() => {
    setName('');
    // setItemCategories([]);
    setNewPrice('');
    setOldPrice('');
    setWeight('');
    setInfo('');
    setLimit('');
    setX('');
    setY('');
    setZ('');
    setDiameter('');
    setTemperature('');
    // setCategroies([]);
    setComposition('');
    setIsActive(false);
    setPriceValue('');
    setWeightValue('');
    // setMaterials([]);
    setMaterialId([]);
    // setFactions([]);
    setFactionId([]);
    // setPackaging([]);
    setPackagingId([]);
    // setColor([]);
    setColorId([]);
    // setThickness([]);
    setThicknessId([]);
    setSquare('');
    setStatus('');
    setImg([]);
    setData();
    setItemCategoryId('');
    setCategoryId(null);
    // setSubCategoryBranches([]);
    setCategoryIdByAppointment(null);
    setCategoryIdByType(null);
  }, []);

  // REQUIRED:
  // info, newPrice, name, categoryId, itemCategoryId, limit, priceValue

  const [diameter, setDiameter] = useState('');
  const [temperature, setTemperature] = useState('');
  const [categories, setCategroies] = useState([]);
  const [composition, setComposition] = useState('');
  const [isActive, setIsActive] = useState(false);
  const [priceValue, setPriceValue] = useState('₽');
  const [weightValue, setWeightValue] = useState('кг');

  const [materials, setMaterials] = useState([]);
  const [materialId, setMaterialId] = useState([]);

  const [factions, setFactions] = useState([]);
  const [factionId, setFactionId] = useState([]);

  const [packaging, setPackaging] = useState([]);
  const [packagingId, setPackagingId] = useState([]);

  const [color, setColor] = useState([]);
  const [colorId, setColorId] = useState([]);

  const [thickness, setThickness] = useState([]);
  const [thicknessId, setThicknessId] = useState([]);

  const [square, setSquare] = useState('');
  const [status, setStatus] = useState('');

  const [data, setData] = useState();
  const [img, setImg] = useState([]);

  useEffect(() => {
    getCategoryData();
  }, []);

  const getCategoryData = async () => {
    const data = await getCategories();
    const data2 = await getColors();
    const data3 = await getFactions();
    const data4 = await getPackagings();
    const data5 = await getMaterials();
    const data6 = await getSubCategoryBranches();
    const data7 = await getThicknesses();
    const data8 = await getSubCategories();

    reformedRoletDataForTable(data, data2, data3, data4, data5, data6, data7, data8);
  };

  const reformedRoletDataForTable = (data, data2, data3, data4, data5, data6, data7, data8) => {
    const itemes = [];

    for (let index = 0; index < 3; index++) {
      if (index === 0) {
        data.map((i) => itemes.push(i));
      }

      if (index === 1) {
        data6.map((i) => itemes.push(i));
      }

      if (index === 2) {
        data8.map((i) => itemes.push(i));
      }
    }

    setItemCategories(itemes);
    setCategroies(data);
    setColor(data2);
    setFactions(data3);
    setPackaging(data4);
    setMaterials(data5);
    setSubCategoryBranches(data6);
    setThickness(data7);
  };

  const uploadImage = async (e) => {
    let file = e.target.files[0];
    const base64 = await convertBase64(file);
    // setImg([...img, base64]);
    setImg((prev) => prev.concat(base64));
  };

  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);

      fileReader.onload = () => {
        resolve(fileReader.result);
      };

      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  const deleteFoto = (index) => {
    const newImg = img.filter((item, ind) => ind !== index);
    setImg(newImg);
  };

  useEffect(() => {
    const obj = {
      name: name,
      itemCategoryId: itemCategoryId,
      categoryId: categoryId === '' ? null : categoryId,
      categoryIdByAppointment: !categoryIdByAppointment?.length ? null : categoryIdByAppointment.join('-'),
      // categoryIdByAppointment:
      //   categoryIdByAppointment === "" ? null : categoryIdByAppointment,
      categoryIdByType: categoryIdByType === '' ? null : categoryIdByType,
      newPrice: newPrice,
      oldPrice: oldPrice,
      x: x,
      y: y,
      z: z,
      thickness: thicknessId ? thicknessId : [],
      status: status,
      diameter: diameter,
      faction: factionId ? factionId : [],
      temperature: temperature,
      square: square,
      packaging: packagingId ? packagingId : [],
      weight: weight,
      composition: composition,
      color: colorId ? colorId : [],
      limit: limit,
      info: info,
      isActive: isActive,
      image: img,
      priceValue: priceValue,
      weightValue: weightValue,
      material: materialId ? materialId : [],
    };

    console.log('obj', obj);
    setData(obj);
  }, [
    name,
    itemCategoryId,
    categoryId,
    newPrice,
    oldPrice,
    x,
    y,
    z,
    status,
    diameter,
    square,
    temperature,
    thicknessId,
    colorId,
    packagingId,
    weight,
    composition,
    factionId,
    categoryIdByType,
    categoryIdByAppointment,
    limit,
    info,
    isActive,
    img,
    priceValue,
    weightValue,
    materialId,
  ]);

  const navigate = useNavigate();

  const openNotificationWithIcon = (type) => {
    notification[type]({
      message: type,
      description: type === 'success' ? 'changes entered successfully' : 'error when making a change',
    });

    if (type === 'success') {
      if (headerRef.current) {
        headerRef.current.scrollIntoView();
      }
      resetState();
    }
  };

  const headerRef = useRef(null);

  return (
    <>
      <div className="header-cont" ref={headerRef}>
        <h1>Добавить Продукт</h1>
        <Button onClick={() => navigate(-1)}>Назад</Button>
      </div>
      <div className="Product">
        <form
          noValidate={false}
          onSubmit={(e) => {
            e.preventDefault();
            addProduct(data, openNotificationWithIcon);
          }}
        >
          <div className="name-surname">
            <div style={{ display: 'flex', width: '100%' }}>
              <input
                type="text"
                onChange={(e) => setName(e.target.value)}
                value={name || ''}
                placeholder="Название"
                required
              />
              <>&nbsp;✱</>
            </div>

            <div className="roles">
              <SelectOption
                data={categories}
                placeholder="Раздел"
                setId={setCategoryId}
                currentId={categoryId}
                required
              />

              {/* <SelectOption
                data={subCategoryBranches}
                placeholder="Id Category By Appointment Item"
                setId={setCategoryIdByAppointment}
                currentId={categoryIdByAppointment}
              /> */}

              <SelectChackbox
                items={subCategoryBranches}
                placeholder="Типа товара (применение)"
                selecteditems={categoryIdByAppointment?.filter(Boolean)}
                setItems={setCategoryIdByAppointment}
              />

              <SelectOption
                data={subCategoryBranches}
                placeholder="Тип товара"
                setId={setCategoryIdByType}
                currentId={categoryIdByType}
              />

              {/* <input
                type="text"
                onChange={(e) => setCategoryIdByAppointment(e.target.value)}
                value={categoryIdByAppointment || ""}
                placeholder="Id Category By Appointment Item"
              />
              <input
                type="text"
                onChange={(e) => setCategoryIdByType(e.target.value)}
                value={categoryIdByType || ""}
                placeholder="Id Category By Type Item"
              /> */}
            </div>
            <SelectOption
              data={itemCategories}
              placeholder="Раздел"
              setId={setItemCategoryId}
              currentId={itemCategoryId}
              required
            />
            {/* <input
              type="text"
              onChange={(e) => setItemCategoryId(e.target.value)}
              value={itemCategoryId || ""}
              placeholder="Item Category Id"
            /> */}
          </div>

          <div className="username-password">
            <div style={{ display: 'flex', width: '100%' }}>
              <input type="text" onChange={(e) => setX(e.target.value)} placeholder="X" value={x || ''} />
              <div style={{ width: '1rem' }} />
            </div>
            <div style={{ display: 'flex', width: '100%' }}>
              <input type="text" onChange={(e) => setY(e.target.value)} value={y || ''} placeholder="Y" />
              <div style={{ width: '1rem' }} />
            </div>
            <div style={{ display: 'flex', width: '100%' }}>
              <input type="text" onChange={(e) => setZ(e.target.value)} placeholder="Z" value={z || ''} />
              <div style={{ width: '1rem' }} />
            </div>

            {/* <input
              type="text"
              onChange={(e) => setThickness(e.target.value)}
              placeholder="Thickness"
              value={thickness || ""}
            /> */}

            <SelectChackbox
              items={thickness}
              placeholder="Толщина"
              selecteditems={thicknessId}
              setItems={setThicknessId}
            />

            <div style={{ display: 'flex', width: '100%' }}>
              <input
                type="text"
                onChange={(e) => setTemperature(e.target.value)}
                value={temperature || ''}
                placeholder="Температура"
              />
              <div style={{ width: '1rem' }} />
            </div>

            <div style={{ display: 'flex', width: '100%' }}>
              <input
                type="text"
                placeholder="Диаметр"
                onChange={(e) => setDiameter(e.target.value)}
                value={diameter || ''}
              />
              <div style={{ width: '1rem' }} />
            </div>

            <div style={{ display: 'flex', width: '100%' }}>
              <input
                type="text"
                onChange={(e) => setSquare(e.target.value)}
                placeholder="Площадь"
                value={square || ''}
              />
              <div style={{ width: '1rem' }} />
            </div>
          </div>

          <div className="phoneNumber-addres">
            <div style={{ display: 'flex', width: '100%' }}>
              <input
                type="text"
                onChange={(e) => setStatus(e.target.value)}
                placeholder="Статус"
                value={status || ''}
              />
              <div style={{ width: '1rem' }} />
            </div>
          </div>
          <div className="phoneNumber-addres">
            <div style={{ display: 'flex', width: '100%' }}>
              <input
                type="text"
                onChange={(e) => setComposition(e.target.value)}
                placeholder="Состав"
                value={composition || ''}
              />
              <div style={{ width: '1rem' }} />
            </div>
            {/* <input
              type="text"
              placeholder="IsActive"
              onChange={(e) => setIsActive(e.target.value)}
              value={isActive || ""}
            /> */}
          </div>
          {/* <div className="active-wrapper"> */}
          <div
            style={{ display: 'flex', gap: '8px' }}
            title="При включении товар будет виден в каталоге товаров"
          >
            <input
              className="IsActive"
              type="checkbox"
              checked={isActive}
              onChange={(e) => setIsActive(e.target.checked)}
            />
            <p> Доступно в каталоге</p>
          </div>
          <div className="phoneNumber-addres">
            <div style={{ display: 'flex', width: '100%' }}>
              <input
                type="text"
                input
                placeholder="Новая цена"
                onChange={(e) => setNewPrice(e.target.value)}
                value={newPrice || ''}
                required
              />
              <>&nbsp;✱</>
            </div>
            <div style={{ display: 'flex', width: '100%' }}>
              <input
                type="text"
                input
                placeholder="Старая цена"
                onChange={(e) => setOldPrice(e.target.value)}
                value={oldPrice || ''}
              />
              <div style={{ width: '1rem' }} />
            </div>
            <div
              style={{ display: 'flex', width: '100%' }}
              title="Обычно указывается символ российского рубля ₽"
            >
              <input
                type="text"
                placeholder="Денежная еденица цены"
                onChange={(e) => setPriceValue(e.target.value)}
                value={priceValue || ''}
                required
              />
              <>&nbsp;✱</>
            </div>
          </div>

          <div className="phoneNumber-addres">
            <div style={{ display: 'flex', width: '100%' }}>
              <input
                type="text"
                placeholder="Вес"
                onChange={(e) => setWeight(e.target.value)}
                value={weight || ''}
              />
              <div style={{ width: '1rem' }} />
            </div>

            <div style={{ display: 'flex', width: '100%' }}>
              <input
                type="text"
                placeholder="Обозначение веса"
                onChange={(e) => setWeightValue(e.target.value)}
                value={weightValue || ''}
              />
              <div style={{ width: '1rem' }} />
            </div>

            <div style={{ display: 'flex', width: '100%' }}>
              <input
                type="text"
                placeholder="Лимит"
                onChange={(e) => setLimit(e.target.value)}
                value={limit || ''}
                required
              />
              <>&nbsp;✱</>
            </div>

            <div style={{ display: 'flex', width: '100%', paddingTop: '1rem' }}>
              <textarea
                value={info}
                onChange={(e) => setInfo(e.target.value)}
                name=""
                id=""
                cols="30"
                rows="10"
                placeholder="Описание"
                required
                style={{ padding: '1vmin' }}
              />
              <>&nbsp;✱</>
            </div>
          </div>

          <div className="roles">
            {/* <SelectOption
              data={color}
              placeholder="Color"
              setId={setColorId}
              currentId={colorId}
              SubCategoryActive={setSubCategoryActive}
            /> */}

            <SelectChackbox items={color} placeholder="Цвет" selecteditems={colorId} setItems={setColorId} />
          </div>

          <div className="roles">
            {/* <SelectOption
              data={packaging}
              placeholder="Packaging"
              setId={setPackagingId}
              currentId={packagingId}
              SubCategoryActive={setSubCategoryActive}
            /> */}

            <SelectChackbox
              items={packaging}
              placeholder="Упаковка"
              selecteditems={packagingId}
              setItems={setPackagingId}
            />
          </div>
          {/* info, newPrice, name, categoryId, itemCategoryId, limit, priceValue */}

          <div className="roles">
            {/* <SelectOption
              data={factions}
              placeholder="Fraction"
              setId={setFactionId}
              currentId={factionId}
              SubCategoryActive={setSubCategoryActive}
            /> */}

            <SelectChackbox
              items={factions}
              placeholder="Фракция"
              selecteditems={factionId}
              setItems={setFactionId}
            />
          </div>

          <div className="roles">
            {/* <SelectOption
              data={materials}
              placeholder="Material"
              setId={setMaterialId}
              currentId={materialId}
              SubCategoryActive={setSubCategoryActive}

            /> */}

            <SelectChackbox
              items={materials}
              placeholder="Материал"
              selecteditems={materialId}
              setItems={setMaterialId}
            />
          </div>

          {/* <div className="product-img">
            <h2>Product Image</h2>
            <Image src={img} alt="" />
          </div> */}

          {img.map((i, ind) => (
            <div className="product-img" key={ind}>
              <CloseOutlined
                onClick={() => {
                  deleteFoto(ind);
                }}
                className="deletFoto"
              />
              <h2>Product Image {ind + 1}</h2>
              <Image src={i} alt="" />
            </div>
          ))}
          <label className="Image" htmlFor="file">
            <img src={imginp} alt="" /> Загрузить фото
          </label>
          <input id="file" type="file" onChange={(e) => uploadImage(e)} value="" />

          {imgGallery.map((el, i, galleryArr) => (
            <div className="container" key={i}>
              <div style={{ display: 'flex', gap: '1rem' }}>
                {el ? (
                  <div
                    className="product-img"
                    style={{
                      justifyContent: 'flex-start',
                      flexDirection: 'column',
                    }}
                  >
                    <CloseOutlined
                      onClick={() => {
                        deleteGalleryFoto(i);
                      }}
                      className="deletFoto"
                    />
                    <h2>Фото #{i + 1} для "Галерея работ"</h2>
                    <Image src={el} alt="" />
                  </div>
                ) : (
                  <label className="Image" style={{ height: 'fit-content' }}>
                    <img src={imginp} alt="" /> Фото для "Галерея работ"
                    <input
                      type="file"
                      onChange={(e) => uploadImageToGallery(e, i)}
                      value=""
                      style={{ position: 'absolute', visibility: 'hidden' }}
                    />
                  </label>
                )}
              </div>

              {i === galleryArr.length - 1 && galleryArr.length < 99 && (
                <label className="Image" style={{ height: 'fit-content' }}>
                  ➕ Фото для "Галерея работ"
                  <input
                    type="button"
                    onClick={() => setImgGallery((prev) => prev.concat(''))}
                    style={{ position: 'absolute', visibility: 'hidden' }}
                  />
                </label>
              )}
            </div>
          ))}

          {(!info || !newPrice || !name || !categoryId || !itemCategoryId || !limit || !priceValue) && (
            <p style={{ color: 'red', fontWeight: 700 }}>✱ - эти поля обязательны для заполнения</p>
          )}

          <div className="buttons">
            <button
              onClick={(e) => {
                e.preventDefault();
                navigate(-1);
              }}
            >
              Отмена
            </button>
            <button
              type="submit"
              disabled={
                !info || !newPrice || !name || !categoryId || !itemCategoryId || !limit || !priceValue
              }
            >
              Сохранить
            </button>
          </div>
        </form>
      </div>
    </>
  );
}
