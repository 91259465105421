import React, { useEffect, useState } from 'react';
import { getSimilarProducts } from '../../Api';
import { Image, Button, notification } from 'antd';
import ModalSimilarProducts from '../../Components/SimilarProduct/ModalSimilarProduct/Modal';
import TableComp from '../../Components/Table/TableComp';
import ModalDelet from '../../Components/SimilarProduct/ModalDeletSimilarProduct/ModalDelet';
import { Link } from 'react-router-dom';
import ButtonEdit from '../../Components/ButtonEdit/ButtonEdit';
import './SimilarProducts.scss';

export default function SimilarProducts() {
  const [similarProducts, setSimilarProducts] = useState([]);

  // console.log('similarProducts', similarProducts);

  useEffect(() => {
    getSimilarProductsData();
  }, []);

  const getSimilarProductsData = async () => {
    const data = await getSimilarProducts();
    const NewSimilarProducts = reformedSimilarProductsDataForTable(data);
    // console.log(NewSimilarProducts);
    setSimilarProducts(NewSimilarProducts);
  };

  const openNotificationWithIcon = (type) => {
    notification[type]({
      message: type,
      description:
        type === 'success' ? 'SimilarProducts deleted successfully' : 'SimilarProducts deletion error',
    });
  };

  const reformedSimilarProductsDataForTable = (data) => {
    if (!data?.length) return [];
    return data.map((i, index) => ({
      ...i,
      id: index + 1,
      Название: (i.product = !null ? i.product.name : '-'),
      'Похожий продукт 1': (i.productOne = !null ? i.productOne.name : '-'),
      'Похожий продукт 2': (i.productTwo = !null ? i.productTwo.name : '-'),
      'Похожий продукт 3': (i.productThree = !null ? i.productThree.name : '-'),
      'Похожий продукт 4': (i.productFour = !null ? i.productFour.name : '-'),
      key: index,
      Действия: (
        <React.Fragment key={index}>
          <ModalDelet
            deleteInfo={openNotificationWithIcon}
            deleteData={getSimilarProductsData}
            rowId={i._id}
          />
          <ButtonEdit itemPath="/edit-sproduct" itemId={i._id} />
          <ModalSimilarProducts SimilarProducts={i} />
        </React.Fragment>
      ),
    }));
  };

  return (
    <div>
      <div className="header-cont">
        <h2>Похожие продукты</h2>
        <Button>
          <Link to={'/add-sproduct'}>Добавить</Link>
        </Button>
      </div>

      <TableComp
        items={[
          { title: 'Название' },
          { title: 'Похожий продукт 1' },
          { title: 'Похожий продукт 2' },
          { title: 'Похожий продукт 3' },
          { title: 'Похожий продукт 4' },
          { title: 'Действия' },
        ]}
        dataSource={similarProducts}
      />
    </div>
  );
}
