export const content = {
  '/': {
    title: 'Стройэкология',
    description: '',
    menu: {
      products: [
        {
          link: '/services/beautification/mature-trees',
          title: 'Крупномеры',
        },
        {
          link: '/natural-stone',
          title: 'Природный камень',
        },
        {
          link: '/rolled-lawn',
          title: 'Рулонный и посевной газон',
        },
        {
          link: '/maf',
          title: 'Изделия из крошки природного камня',
        },
        {
          link: '/organic',
          title: 'Органические удобрения для сада',
        },
        {
          link: '/',
          title: 'Изделия из дерева для ландшафтного проектирования',
        },
        {
          link: '/deicing-reagents',
          title: 'Противогололедные реагенты',
        },
        {
          link: '/related-products',
          title: 'Сопутствующие товары',
        },
      ],
      services: [
        {
          link: '/services',
          title: 'Посев газона',
        },
        {
          link: '/services/beautification/landscape-design',
          title: 'Облицовочные работы',
        },
        {
          link: '/',
          title: 'Мощение',
        },
        {
          link: '/services/beautification',
          title: 'Планировка территории',
        },
        {
          link: '/services/beautification',
          title: 'Монтаж системы полива и орошения',
        },
        {
          link: '/',
          title: 'Посадка деревьев и кустарников',
        },
      ],
    },

    sliderMainblock: {
      title: `Самый большой <br />
      склад камня в Черноземье`,
      circle: ['10 000 м3', 'в наличии'],
      moreLink: '/',
      active: true,
    },
    sliderLawnBlock: {
      title: 'Продажа и укладка качественного <br /> рулонного газона в воронеже',
      moreLink: '/',
      active: true,
    },
    sliderStoneBlock: {
      title: 'Бутовый камень «Гранит»',
      description: `Применяют при возведении кладки фундаментов, цоколей, стен <br />
      зданий и оград. Широко распространен в ландшафтном <br /> дизайне,
      для покрытия дворов, мостовых и откосов.`,
      moreLink: '/',
      active: true,
    },
    sliderOrganicBlock: {
      title: 'Органические удобрения <br /> для сада от 130₽ за мешок',
      moreLink: '/',
      active: false,
    },
    SectionTitle: 'Комплексное благоустройство территорий природными материалами',
    SectionDescription: 'Самый большой склад камня в черноземье',
    achievements: [
      ['&gt;20', 'лет на рынке'],
      ['&gt;1500', 'успешно завершенных проектов'],
      ['&gt;150', 'крупномеров пересажено'],
      ['10000', 'камня в наличии на складе в Воронеже'],
    ],
    UsingSection: {
      title: 'Камень по применению',
      description: 'У нас огромный выбор камней для декора и строительства',
    },
    SpecializationSection: {
      title: 'Наша специализация',
      description: 'Предоставляем большой спектр услуг в сфере ландшафтного дизайна',
      specializationCards: [
        {
          title: 'Благоустройство и озеленение',
          list: [
            'Ландшафтное проектирование',
            'Планировка территории',
            'Монтаж системы полива и орошения',
            'Строительство дорожек, площадок, подпорных стенок',
            'Посадкапересадка крупномеров',
            'Разработка, подбор и строительство пергол, беседок, садовой мебели, малых архитектурных форм и т.д.',
            'Устройство цветников, роккариев, альпинариев',
          ],
          img: '/img/specializationSection/landscaping.webp',
          link: '/services/beautification',
        },
        {
          title: 'Работа с камнем',
          list: [
            'Строительство тематических садиков',
            'Устройство декоративных огородов',
            'Облицовочные работы',
            'Мощение',
            'Бутовая кладка',
            'Водоемы, каскады',
            'Декорирование крошкой',
          ],
          img: '/img/specializationSection/work-with-stone.webp',
          link: '/services/beautification',
        },
        {
          title: 'Укладка, посев газона',
          list: ['Укладка рулонного газона', 'Посев газон'],
          description:
            'Наша фирма осуществляет весь цикл работ от предварительного этапа до ухода и обслуживания травяного покрытия. \n\n Мы производим посев газонной травы и укладку рулонного газона в Воронеже и по всей Воронежской области. Цена на услуги полностью соответствует качеству производимых работ.',
          img: '/img/specializationSection/laying-lawn.webp',
          link: '/rolled-lawn',
        },
      ],
    },
    MAFSection: {
      title: 'Малые архитектурные формы',
      description: 'У нас большой ассортимент МАФ',
      img: '/img/arch-forms.png',
      text: [
        `
                ООО «СтройЭкология» производит уличную садово-парковую мебель и
                элементы декора с фактурой из природной каменной крошки.
              `,
        `
                Благодаря поверхности из натурального камня они долговечны, в не
                пристает грязь и пыль, они не требуют ремонта и служат
                десятилетиями. Все элементы могут сочетаться между собой и
                образовывать коллекции.
              `,
        `
                Декоративная продукция смотрится изысканно, адаптирована к любым
                погодным условиям, экологически безопасна и чрезвычайно
                устойчива к механическим нагрузкам.
              `,
      ],
      moreLink: '/maf',
    },
    FinishedProjectsSection: {
      title: 'Завершенные проекты',
      description: 'За годы работы выполнили множество проектов различной сложности',
    },
    PartnersSection: {
      title: 'Наши партнеры',
      description: 'Имеем множество довольных клиентов',
      list: [
        { img: '/img/index-slider-partners/1.png' },
        { img: '/img/index-slider-partners/2.png' },
        { img: '/img/index-slider-partners/3.png' },
        { img: '/img/index-slider-partners/4.png' },
        { img: '/img/index-slider-partners/5.png' },
        { img: '/img/index-slider-partners/6.png' },
        { img: '/img/index-slider-partners/1.png' },
        { img: '/img/index-slider-partners/2.png' },
        { img: '/img/index-slider-partners/3.png' },
        { img: '/img/index-slider-partners/4.png' },
        { img: '/img/index-slider-partners/5.png' },
        { img: '/img/index-slider-partners/6.png' },
      ],
    },
    ContactForm: {
      title: 'Наш менеджер свяжется с вами',
      description: 'Нужна консультация?',
    },
  },
  '/natural-stone': {
    title: 'Природный камень',
    description: '',
    sliderMainblock: {
      title: `Самый большой <br />
      склад камня в Черноземье`,
      circle: ['10 000 м3', 'в наличии'],
      moreLink: '/',
    },
    Section1: {
      title: 'Заголовок',
      description: 'Надзаголовок',
    },
    Section1Cards: [
      {
        title: 'Надежные поставщики',
        description: 'Мы работаем с 20 проверенными поставщиками',
        icon: '/img/icons/advantage1.svg',
      },
      {
        title: 'Большой ассортимент',
        description: 'Предлагаем более 10 000 м3 природного камня в наличии',
        icon: '/img/icons/advantage2.svg',
      },
      {
        title: 'У нас большой опыт',
        description: 'Продаем камень 12 лет',
        icon: '/img/icons/advantage3.svg',
      },
      {
        title: 'Подробная информация',
        description: 'Проводим консультации нашим клиентами',
        icon: '/img/icons/advantage4.svg',
      },
    ],
    UsingSection: {
      title: 'Камень по применению',
      description: 'У нас огромный выбор камней для декора и строительства',
    },
    Section2: {
      title: 'Заголовок',
      description: 'Надзаголовок',
    },
    naturalStoneLink: {
      href: '/natural-stone/catalog',
      text: 'В каталог природного камня',
    },
    SpecializationSection: {
      title: 'Наши услуги по работе с природным камнем',
      description: 'Предоставляем большой спектр услуг',
      specializationCards: [
        {
          title: 'Облицовочные работы',
          list: [
            'Каменные элементы отделки имеют высокий предел прочности.',
            'Срок службы материала составляет десятки лет.',
            'Фасады могут отделываться разными видами, цветами и форматами каменных плит.',
          ],
          img: '/img/specializationSection/facing-works.webp',
          link: '/services/beautification',
          description:
            'Облицовка фасада дома камнем – это дорогой, трудоемкий, но очень достойный вариант отделки экстерьера здания. \n\n Наружная каменная облицовка будет служить одновременно защитой и украшением дома.',
        },
        {
          title: 'Декорирование крошкой',
          img: '/img/specializationSection/decorating-crumbs.webp',
          link: '/services/beautification',
          description:
            'Дробленый природный камень заслужил признание ландшафтных дизайнеров благодаря тому, что удивительным образом сочетает в себе все достоинства натуральных камней с широким диапазоном возможного использования. \n\n Мелкие кусочки камней, рассортированные по размеру фракций, в точности повторяют цвет натурального минерала и дарят большой простор для фантазии. \n\n К тому же декоративная крошка для ландшафтного дизайна может подвергаться дополнительной обработке – шлифовке, окрашиванию, что еще больше расширяет возможности при оформлении участка.',
        },
        {
          title: 'Мощение',
          description:
            'Садовые дорожки из камня оригинальны, практичны и долговечны. На них не будет скользко и грязно даже в мороз и слякоть. Для обустройства троп используется прочный материал: бут, щебень, плитняк, песчаник, гранит и другие. \n\n Любой вид камня подходит для кладки на разных слоях грунта с любым содержанием: глинистым, щелочным, кислотным. Они выдерживают воздействие негативной климатической среды только в том случае, когда обустраиваются с учетом всех нюансов и правил.',
          img: '/img/specializationSection/paving.webp',
          link: '/rolled-lawn',
        },
      ],
    },
    FinishedProjectsSection: {
      title: 'Завершенные проекты',
      description: 'За годы работы выполнили множество проектов различной сложности',
    },
    ContactForm: {
      title: 'Наш менеджер свяжется с вами',
      description: 'Нужна консультация?',
    },

    texts1: [
      `
      Природным камнем называют все монолитные горные породы используемые
      в строительстве. Природные камни имеют различные физические
      свойства, структуру, прочностные и декоративные качества. Природный
      камень - это один из древнейших материалов, он широко используется в
      строительстве домов и ландшафтном дизайне. Природные камни имеют
      различные физические свойства, структуру, прочностные и декоративные
      качества. Все разнообразие цветов и состава декоративных камней
      идеально подходят для облицовки фасадов (кварцит, песчаник),
      внутренней отделки помещения, для создания монументов и скульптур,
      оформления дорожек (галька,волуны) и т.д.
    `,
      `
      Природные камни классифицируются по следующим показателям:
      прочность, декоративность, долговечность. В зависимости от этих
      свойств и определяются сферы использования природного камня.
    `,
      `Бут – один из самых древних видов каменной кладки.`,
      `
      Бут бывает рваный,плитчатый и постелистый. Его получают путем
      обработки местных осадочных и известковых породы с пределом
      прочности на сжатие до 100 Мн/м2 (1000 кгс/см2). В строительстве
      используется чистый, без трещин и расслоений камень.
    `,
      `
      Бутовый камень применяют при возведении кладки фундаментов, цоколей,
      стен зданий и оград. Широко распространен в ландшафтном дизайне, для
      покрытия дворов, мостовых и откосов.
    `,
      `
      Сланец - горная порода, которая образовалась примерно 450 миллионов
      лет тому назад на дне моря из растительных и животных остатков.
      Характеризуется практически параллельным расположением входящих в
      его состав вытянутых или пластинчатых минералов и обладает
      способностью раскалываться на тонкие пластинки. Он идеально подходит
      для облицовки, мощения и ландшафтных композиций.
    `,
      `
      Данный вид облицовочного камня пользуется большим спросом в России.
      При укладке, его пластины ровно прилегают к друг другу, остается
      виден только торец, что в конечном результате выглядит очень
      эффектно.
    `,
    ],

    texts2: [
      `
                Известняк – осадочная горная порода, преимущественно состоящая из
                кальцита или в редких случаях из арагонита. Известняк в натуральном
                виде - это отличный материал для резки по камню, именно поэтому,
                помимо строительства его широко используют для облицовочных и
                отделочных работ и также для декоративного украшения зданий или
                ландшафтов. Цветовая палитра известняка очень разнообразна - от
                кремовато-белого до светло-коричневого.
              `,
      `
                С давних пор известняк, доломит, мрамор - это наиболее излюбленные
                человеком строительные материалы. Несмотря на низкую долговечность
                этих пород, все это с лихвой перекрывается их легкостью в обработке
                и качественными декоративными свойствами. Цветовая гамма довольно
                обширна, но зачастую это теплые и мягкие тона. Кладка из таких
                камней смотрится очень притягательно и свежо.
              `,
      `
                Природный камень, натуральный камень, дикий камень, камень для
                ландшафтного дизайна Природный камень, натуральный камень, дикий
                камень, камень для ландшафтного дизайна.
              `,
      `
                Классический известняк это идеальный вариант для укладки и
                декорирования стен. Это по истине его стихия. Как только камень
                оказывается уложенным на стену все мелкие детали становятся
                незаметны и раскрываются его главные особенности: притягательный
                классический рисунок пластовой кладки с уступчатым расположением
                камней.
              `,
      `
                Разноразмерный песчаник– один из видов каменной кладки,
                преимущественно состоящий из элементов прямоугольной формы и
                рзличных по величине с невысоким, но довольно выразительным
                рельефом. Достаточно широкое разнообразие рисунков: большие и
                маленькие прямоугольные элементы, квадратные, узкие пластины и
                вставки. Он довольно симпатично смотрится в декорировании помещения,
                но также не теряет своей привлекательности во внешней отделке.
              `,
      `
                Чаще всего применяется для сплошной облицовки или обрамлений. При
                облицовке фасадов обычно используется песчаник темной окраски. На
                поверхности камня сохранены следы старения, которые приходятся по
                вкусу истинными ценителями декора из камня.
              `,
    ],
  },
  '/rolled-lawn': {
    title: 'Продажа и укладка качественного рулонного газона в Воронеже',
    description: '',
    sliderLawnBlock: {
      title: 'Продажа и укладка качественного <br /> рулонного газона в воронеже',
      moreLink: '/',
    },
    SectionTitle: {
      description: 'Надзаголовок',
      title: 'Рулонные газоны',
    },
    SectionTitle2: {
      description: 'Не знаете как правильно выбрать газон?',
      title: 'Признаки качественного рулонного газона ',
    },
    SpecializationSection: {
      title: 'Укладка рулонного газона',
      description: '',
      specializationCards: [
        {
          title: 'Укладка рулонного газона',
          description:
            'Производство озеленительных мероприятий начинается в конце весны (в первых числах мая) и продолжается практически до конца октября. В этот период травяное покрытие отлично приживется на Вашем участке и будет долго радовать глаз. Срок укладки натурального газона под ключ зависит от рельефа и размера покрываемого участка. В среднем, требуется 5-7 дней для проведения всех необходимых работ. \n\n Обратившись к нам, Вы получите профессиональную консультацию по благоустройству придомовой площади. Специалисты приедут на объект и в короткие сроки предложат оптимальный вариант по озеленению Вашей территории.',
          img: '/img/specializationSection/rolled-lawn.webp',
          link: '/services/beautification',
        },
        {
          title: 'Посев газона',
          description:
            'Технология устройства посевного газона предполагает предварительную подготовку земли для посева: необходимо избавиться от сорняков, провести культивацию почвы, выполнить выравнивание поверхности, чередуя боронование и прикатывание водоналивными катками. В большинстве случаев необходимо внесение доломитовой муки, для раскисления почвы и комплексных минеральных удобрений для улучшения агрофона. \n\n После проведения подготовительных работ приступают непосредственно к посеву. ',
          img: '/img/specializationSection/lawn-sowing.webp',
          link: '/services/beautification',
        },
      ],
    },
    SectionTitle3: {
      description: 'Наши работы по благоустройству',
      title: `“До” и “ПОСЛЕ”`,
    },
    SectionTitle4: { description: 'Надзаголовок', title: 'Сроки посадки газона' },
    growPeriods: `Работы по устройству газона можно проводить с апреля по ноябрь,
    когда устанавливается стабильная плюсовая температура. Устройство
    рулонного газоназаймет всего 2-4 дня и уже на следующий день после
    укладки газон будет радовать вас.`,
    optimalTime: 'Оптимальное время для проведения работ',
    temperature: `Работы проводятся при температуре +5 °С`,
    noJobs: 'Работы не проводятся',
    FinishedProjectsSection: {
      title: 'Завершенные проекты',
      description: 'За годы работы выполнили множество проектов различной сложности',
    },
    SectionTitle5: {
      description: 'Надзаголовок',
      title: 'Заголовок',
    },
    cards: [
      {
        title: 'Надежные поставщики',
        description: 'Мы работаем с 20 проверенными поставщиками',
        icon: '/img/icons/advantage1.svg',
      },
      {
        title: 'Большой ассортимент',
        description: 'Предлагаем более 10 000 м3 природного камня в наличии',
        icon: '/img/icons/advantage2.svg',
      },
      {
        title: 'У нас большой опыт',
        description: 'Продаем камень 12 лет',
        icon: '/img/icons/advantage3.svg',
      },
      {
        title: 'Подробная информация',
        description: 'Проводим консультации нашим клиентами',
        icon: '/img/icons/advantage4.svg',
      },
    ],
    ContactForm: {
      title: 'Наш менеджер свяжется с вами',
      description: 'Нужна консультация?',
    },
    texts1: [
      `Современный метод создания качественного газона с использованием
              его уже в готовом виде, является самым популярным в мировой
              практике. С таким газоном вы за считанные часы получите идеально
              ровное, густое и насыщенное яркими зелеными красками травянистое
              покрытие, которое не содержит сорняков и довольно быстро и легко
              приживается.`,
      `Рулонный газон выращивается в течении нескольких лет по специально
              разработанной технологии. В России для осуществления этого метода
              отбирается необходимый состав трав, который подходит именно под
              наш климат. В основном это узколистные сорта мятлика лугового или
              овсяницы красной. Также используются высококачественные
              голландские семена последней селекции, которые легко переносят
              заморозки и устойчивы к болезням и засухе.`,
      `Наши специалисты с радостью помогут вам определиться в выборе
              интересующего вас газона прямо на поле: парковый, универсальный
              или игровой с учетом ваших запросов по засухоустойчивости,
              теневыносливости, устойчивости к вытаптыванию. Вам остается лишь
              расстелить его на подготовленном участке, что не отнимет у вас
              много времени и не потребует особых навыков. Газон моментально
              преобразит ваш ландшафт и наполнит его яркими красками. В первые
              годы он не нуждается в особом уходе и прополке и быстро
              приживается.`,
    ],
    texts2: [
      `И уже меньше чем через месяц вы получите газон, который станет
      прекрасным местом отдыха, украсит ваш участок и в то же время
      будет просто радовать глаз. Такой газон уже в первый сезон
      "взрослый" и хорошо адаптирован к переменам погодных условий,
      весной он потребует меньшего ремонта, а зимой он станет сильным и
      зрелым. Независимо от производителя все эти качества характерны
      для всех рулонных газонов.`,
      `На склонах укладка газона просто незаменима и она имеет сои
      особенности. С помощью рулонного газона у вас не составит труда
      оживить и украсит участок с рельефом любой сложности, без угрозы
      сползания и размывания грунтов. Грунт для укладки рулонного газона
      готовят также как и для посевного. Чем быстрее он будет уложен на
      подготовленный участок, тем лучше. В тех местах, где требуются
      специальные травосмеси рулонный газон не используют. Это к
      примеру, теневые уголки сада, места с особыми почвенными
      условиями, большие парки или лесные зоны.`,
    ],
  },
  '/mature-trees': {
    title: 'Посадка и пересадка крупномеров',
    description: '',
    infoText: `Разнообразный и богатый опыт рамки и место обучения кадров требуют от
    нас анализа позиций, занимаемых участниками в отношении поставленных
    задач. Идейные соображения высшего порядка, а также дальнейшее
    развитие различных форм деятельности требуют от нас анализа позиций,
    занимаемых участниками в отношении поставленных задач. Задача
    организации, в особенности же дальнейшее развитие различных форм
    деятельности в значительной степени обуславливает создание направлений
    прогрессивного развития.`,
    SectionTitle: { description: 'Надзаголовок', title: 'Заголовок' },
    stages: [
      {
        title: 'Название этапа',
        description:
          'Текст описание акции на несколько строк. Далее рыба. Разнообразный и богатый опыт рамки и место обучения кадров требуют от нас анализа позиций, занимаемых участниками в отношении поставленных задач.',
      },
      {
        title: 'Название этапа',
        description:
          'Текст описание акции на несколько строк. Далее рыба. Разнообразный и богатый опыт рамки и место обучения кадров требуют от нас анализа позиций, занимаемых участниками в отношении поставленных задач.',
      },
      {
        title: 'Название этапа',
        description:
          'Текст описание акции на несколько строк. Далее рыба. Разнообразный и богатый опыт рамки и место обучения кадров требуют от нас анализа позиций, занимаемых участниками в отношении поставленных задач.',
      },
      {
        title: 'Название этапа',
        description:
          'Текст описание акции на несколько строк. Далее рыба. Разнообразный и богатый опыт рамки и место обучения кадров требуют от нас анализа позиций, занимаемых участниками в отношении поставленных задач.',
      },
      {
        title: 'Название этапа',
        description:
          'Текст описание акции на несколько строк. Далее рыба. Разнообразный и богатый опыт рамки и место обучения кадров требуют от нас анализа позиций, занимаемых участниками в отношении поставленных задач.',
      },
      {
        title: 'Название этапа',
        description:
          'Текст описание акции на несколько строк. Далее рыба. Разнообразный и богатый опыт рамки и место обучения кадров требуют от нас анализа позиций, занимаемых участниками в отношении поставленных задач.',
      },
    ],
    FinishedProjectsSection: {
      title: 'Завершенные проекты',
      description: 'За годы работы выполнили множество проектов различной сложности',
    },
    ContactForm: {
      title: 'Наш менеджер свяжется с вами',
      description: 'Нужна консультация?',
    },
  },
  '/maf': {
    title: 'Малые архитектурные формы',
    description: '',
    text: `Изготовим изделия по индивидуальному эскизу - заборные блоки и
    навершия, фонтаны, элементы мощения и облицовки, ступени,
    столешницы, барбекю, очаги и т.д.`,
    buttonLink: '/',
    infoText: `Люди придумали бетон, так как хотели получить прочную и идеально
    гладкую поверхность. Но в природе стандартных форм не существует.
    В стремлении сделать свою жизнь более естественной человек начал
    отказываться от искусственных поверхностей в пользу натурального
    камня. Экологичность и неповторимая цветовая гамма златолита,
    серебрита, яшмы, серпентита, мрамора различных цветов и других
    пород природного камня, из которых мы производим свои изделия,
    делает нашу продукцию в своем роде уникальной.
    <br />
    <br />В соответствии с пожеланиями клиентов и современными
    трендами в сфере ландшафтного дизайна «Стройэкология» создает
    линейку уникальной продукции ручной формовки. Наши изделия из
    крошки натурального камня станут настоящим украшением любого
    пространства - как в интерьере вашего дома, офиса, где можно
    использовать вазоны и декоративные элементы, так и в садах,
    парках, торгово-развлекательных центрах, где отлично будут
    смотреться наши урны, скамейки и парковочные элементы.
    <br />
    <br />
    Вазоны, урны, традиционные японские и китайские фонари, шары,
    опоры для лавочек - все они в исполнении «Стройэкологии»
    уникальны, не похожи друг на друга. изделия изготавливаются
    вручную, поэтому вы никогда не найдете среди них двух одинаковых
    даже при изначально стандартной форме. Они отличаются и
    пористостью структуры, и рисунком поверхности, и глубиной оттенка.
    Это обусловлено тем, что вся продукция на 70% состоит из
    натурального камня естественного цвета, который может отличаться в
    зависимости от разрабатываемого пласта породы. Наши изделия -
    прочные, элегантные, долговечные, они подходят для любого типа
    ландшафта. Цена вас тоже приятно удивит - она в 2-3 раза ниже, чем
    у штампованных изделий из бетона и крашенного песка стандартной
    формовки.`,
    ContactForm: {
      title: 'Наш менеджер свяжется с вами',
      description: 'Нужна консультация?',
    },
  },
  '/certificates': {
    title: 'Гарантии и секртификаты',
    description: '',
    texts1: [
      `Суммарный гарантийный срок (гарантия производителя + гарантия
            продавца) на продукцию составляет 1 год.`,
      `Гарантия действует со дня передачи товара потребителю.`,
      `Просим вас сохранять оригиналы платежных документов, это является
            подтверждением покупки товара и позволяет определить срок начала
            действия гарантии.`,
      `Гарантия распространяется на дефекты товара, обусловленные дефектами
            конструкции или материалов, которые проявились с момента
            приобретения товара в интернет-магазине.`,
      `Гарантия действует только в случае использования товара по его
            непосредственному назначению.`,
      `Гарантия не действует, если товар хранился и эксплуатировался
            ненадлежащим образом или был неправильно собран. Также гарантийным
            случаем не является естественный износ, порезы и царапины, возникшие
            в процессе эксплуатации.`,
      `При возникновении гарантийного случая вопрос решается в каждом
            случае индивидуально, исходя из параметров заказа, вашего
            местонахождения и сути проблемы.`,
      `Вам необходимо отправить письмо на электронную почту
            hello@designboom.ru c пометкой «Гарантия» в теме письма или
            позвонить по указанному ниже телефону: +7 (800) 550-74-95 (без
            выходных: 09:00-20:00 пн-пт, 11:00-17:00 сб-вс).`,
      `Ответ на ваше обращение будет дан в течение 1 рабочего дня.`,
    ],
    subtitle1: 'Отказ от покупки',
    texts2: [
      `При самовывозе или доставке по Москве и Московской области вы можете
            отказаться от заказанного товара в любой момент до его оплаты и
            получения.`,
      `Вы можете отказаться от покупки полностью или частично при доставке,
            но согласно п.3 ст.497 ГК РФ, вы обязаны возместить транспортные
            расходы продавца (курьеру, ПВЗ, почта).`,
      `При самовывозе или доставке по Москве и Московской области вы можете
            отказаться от заказанного товара в любой момент до его оплаты и
            получения.`,
    ],
    subtitle2: 'Обмен',
    texts3: [
      `Если после покупки вы решили товар обменять, то данная процедура
      происходит через возврат нам товара в надлежащем виде, а вам -
      денежных средств. Процедура по возврату подробно изложена в блоке
      Возврат.`,
    ],
    subtitle3: 'Возврат',
    texts4: [
      `При самовывозе или доставке по Москве и МО вы можете отказаться от
      заказанного товара в любой момент до его оплаты и получения.`,
      `Возврат товара ненадлежащего качества.`,
      `Если вы получили товар ненадлежащего качества (скрытый брак) –
      пришлите нам на электронную почту hello@designboom.ru c пометкой
      «Возврат» - фотографию товара, на которой виден брак/дефект.`,
      `После этого с Вами свяжется наш менеджер и согласует процесс
      возврата/обмена данного товара.`,
    ],
    subtitle4: 'Сертификаты',
    certificates: [
      '/img/certificates/page-1.webp',
      '/img/certificates/page-1.webp',
      '/img/certificates/page-1.webp',
      '/img/certificates/page-1.webp',
    ],
  },
  '/contacts': {
    title: 'Контакты',
    description: '',
    subtitle: `ООО «СтройЭкология»`,
    adress: `394002, г. Воронеж, ул. Витебская д.2 кв.11`,
    tel: `+7 987 654-32-10`,
    email: `stroyecologi@mail.ru`,
    VKlink: '/',
    OKlink: '/',
    FacebookLink: '/',
    InstaLink: '/',
    subtitle2: 'Реквизиты',
    requisites: `ИНН <b>3666124879</b>
              <br />
              КПП <b>366301001</b>
              <br />
              ОГРН <b>1053600279370</b>
              <br />
              ОКВД <b>74.20.11, 45.11.2</b>
              <br />
              ОКПО <b>77873359</b>
              <br />
              Р/с <b>40702810013380115157</b>
              <br />
              <b>Центрально-Чернозёмный Банк ПАО Сбербанк, г. Воронеж</b>
              <br />
              Кор/сч. <b>30101810600000000681</b>
              <br />
              БИК <b>04200768</b>`,
    subtitle3: 'Розничные склады',
    maps: [
      {
        coordinate: [51.639541526857236, 39.291698713141514],
        adress: 'Воронеж, Монтажный проезд, 7 (заезд с улицы Ильюшина 8в)',
        tel: '(473) 2222-872, 2222-871',
        time: 'Без выходных с 9:00 до 18:00',
      },
      {
        coordinate: [51.677672, 39.317688],
        adress: '​Рубежная, 58​с. Нечаевка, Новоусманский район, Воронежская область',
        tel: '(473) 2222-874',
        time: 'Пн-Сб с 9:00 до 18:00, выходной Воскресенье',
      },
      {
        coordinate: [51.633156, 39.334864],
        adress: '​Придорожная, 28а/8​пос. Отрадное, Новоусманский район, Воронежская область',
        tel: '(473) 2222-876',
        time: 'Пн-Сб с 9:00 до 18:00, выходной Воскресенье',
      },
    ],
  },
  '/partners': {
    title: 'Для партнеров',
    description: '',
    text: `Сотрудничество с дизайнерами,
    <br />
    архитекторами и поставщиками`,
    buttonText: 'Узнать подробнее',
    linkMore: '/',
    infoText: [
      `Команда DesignBoom всегда рада творческим, креативным людям.`,
      `Именно поэтому мы предлагаем особые условия сотрудничества
            дизайнерам, архитекторам, декораторам и дизайн-студиям.`,
      `Разнообразный и богатый опыт рамки и место обучения кадров требуют
            от нас анализа позиций, занимаемых участниками в отношении
            поставленных задач. Идейные соображения высшего порядка, а также
            дальнейшее развитие различных форм деятельности требуют от нас
            анализа позиций, занимаемых участниками в отношении поставленных
            задач. Задача организации, в особенности же дальнейшее развитие
            различных форм деятельности в значительной степени обуславливает
            создание направлений прогрессивного развития.`,
    ],
    SectionTitle: { description: 'Надзаголовок', title: 'Заголовок' },
    cards: [
      {
        title: 'Надежные поставщики',
        description: 'Мы работаем с 20 проверенными поставщиками',
        icon: '/img/icons/advantage1.svg',
      },
      {
        title: 'Большой ассортимент',
        description: 'Предлагаем более 10 000 м3 природного камня в наличии',
        icon: '/img/icons/advantage2.svg',
      },
      {
        title: 'У нас большой опыт',
        description: 'Продаем камень 12 лет',
        icon: '/img/icons/advantage3.svg',
      },
      {
        title: 'Подробная информация',
        description: 'Проводим консультации нашим клиентами',
        icon: '/img/icons/advantage4.svg',
      },
    ],
    FinishedProjectsSection: {
      title: 'Завершенные проекты',
      description: 'За годы работы выполнили множество проектов различной сложности',
    },
    SectionTitle2: {
      description: 'Надзаголовок',
      title: 'Отзывы',
    },
    ContantsForPartners: {
      title: 'Стать партнером',
      description: 'Надзаголовок',
      buttonText: 'Стать партнером',
      checkboxText: 'Принимаю условия политики конфиденциальности',
    },
  },
  '/delivery': {
    title: 'Доставка и оплата',
    description: '',
    infoText: `Привезенный курьером букет цветов – это красивая давняя традиция.
    Лучшего подарка для женщины не существует, да и не только для
    женщины. При всей кажущейся практичности чувство прекрасного у
    мужчин тоже присутствует.
    <br />
    <br />
    Поэтому доставка цветов в дни торжеств – одна из самых популярных
    услуг. Магазин цветов Valentа Flowers всегда готов помочь вам и
    доставить радость нашим заказчикам.`,
    subtitle: 'Стоимость доставки',
    prices: [
      {
        price: '250 ₽',
        text: `Доставка цветов по Воронежу при стоимости заказа до 3 000 ₽,
    после доставка осуществляется бесплатно`,
      },
      {
        price: '550 ₽',
        text: `Доставка цветов по Воронежу в ночное время с 22:00 до 8:00`,
      },
      {
        price: '850 ₽',
        text: `Доставка цветов в отдельные районы:
        <br />
        Шилово, Новая Усмань, 1 Мая, Боровое, Отрадное, Отрожка,
        Тепличный`,
      },
      {
        price: '10₽/1км',
        text: `Доставка цветов по Воронежской области - по согласованию`,
      },
    ],
    subtitlePay: 'Способы оплаты',
    paymentList: [
      {
        title: 'Наличный расчёт',
        description:
          'Доставка цветов по Воронежу при стоимости заказа до 3 000 ₽, после доставка осуществляется бесплатно',
      },
      {
        title: 'Оплата банковской картой',
        description:
          'Если товар доставляется курьером, то оплата осуществляется наличными курьеру в руки. При получении товара обязательно проверьте комплектацию товара, наличие гарантийного талона и чека.',
      },
      {
        title: 'Оплата по терминалу при получении',
        description:
          'Если товар доставляется курьером, то оплата осуществляется наличными курьеру в руки. При получении товара обязательно проверьте комплектацию товара, наличие гарантийного талона и чека.',
      },
      {
        title: '',
        description: '',
      },
    ],
  },
  '/organic': {
    pageTitle: 'Органические удобрения для сада',
    title: 'Органические удобрения',
    description: '',
    sliderOrganicBlock: {
      title: `Органические удобрения <br />
                для сада от 130₽ за мешок`,
      moreLink: '/',
    },
    SectionTitle: { description: 'Надзаголовок', title: 'Заголовок' },
    cards: [
      {
        title: 'Надежные поставщики',
        description: 'Мы работаем с 20 проверенными поставщиками',
        icon: '/img/icons/advantage1.svg',
      },
      {
        title: 'Большой ассортимент',
        description: 'Предлагаем более 10 000 м3 природного камня в наличии',
        icon: '/img/icons/advantage2.svg',
      },
      {
        title: 'У нас большой опыт',
        description: 'Продаем камень 12 лет',
        icon: '/img/icons/advantage3.svg',
      },
      {
        title: 'Подробная информация',
        description: 'Проводим консультации нашим клиентами',
        icon: '/img/icons/advantage4.svg',
      },
    ],
    SectionTitle2: {
      description: 'Натуральные органические удобрения',
      title: 'Почему стоит заказать удобрения у нас',
    },
    text: `ООО «СтройЭкология» предлагает высококачественное удобрение
    «Перегной конского навоза». Навоз выработан лошадьми орловской
    породы, вскормленных исключительно овсом, без добавления в корм
    других компонентов.
    <br />
    <br />В среднем навоз различных животных содержит 75% воды, 21%
    органических веществ, 0,5% азота, 0,25% фосфора и т.д., при этом
    выделяется углекислый газ, насыщая почвенный воздух и приземный слой
    атмосферы улучшая углеродное питание растений.
    <br />
    <br />
    Конский навоз как удобрение можно применять для большинства
    растений. В его состав входят выделения животных и их подстилка. Чем
    ценнее корм у животных, тем лучше состав навоза.`,
    text2: `Конский навоз лучше всего применяется для закладки ранних весенних
            парников, в которых обычно выращивается ранняя рассада. Потому что
            конский навоз менее влажный и более рыхлый по сравнению с другим
            видом навоза и в кратчайшие сроки способен нагреть почву. Именно по
            этой причине его использование особенно оправдано на тяжелых и
            холодных почвах, требующих быстрого и интенсивного прогрева.
            <br />
            <br />
            Предлагаемый нами навоз содержит подстилку из овса без добавления
            опилок и других несоответствующих ГОСТу примесей. Подстилка ценна
            тем, что в ней задерживаются жидкие выделения животных,
            следовательно, азот (в виде аммиака) и калий.
            <br />
            <br />
            При использовании конского навоза увеличивается урожайность садовых
            и огородных культур, усиливается сопротивляемость к болезням и
            негативным внешним факторам. Удобряя им почву, можно добиться
            повышения ее плодородия на несколько лет вперед.`,
    text3: `Комплексные органические удобрения обладают рядом фундаментальных
            преимуществ, при использовании, связанных с фиксацией азота, фосфора
            и калия в органических формах. При внесении этих удобрений исключены
            вымывание и иные виды потерь макроэлементов, которые растения
            не успевают усвоить. Тем самым при внесении органических удобрений
            не требуется завышать норму внесения на 25-30% гарантированного
            вымывания, значительно улучшается экологическое состояние пашни,
            устраняются токсические эффекты для почвенной фауны.
            <br />
            <br />
            Большинство людей, имеющих подсобное хозяйство удобряют почву
            удобрениями на основе конского навоза и очень довольны полученным
            результатом!`,
    SectionTitle3: {
      description: 'Товар имеет несколько вариантов упаковки',
      title: 'хотите заказать Удобрения для вашего участка?',
    },
    items: [
      { image: '/img/fertilizer-1.png', title: 'Мешок 60 л', description: '(На поддоне по 30 шт.)' },
      { image: '/img/fertilizer-2.png', title: 'Биг-бэг - 0,5 м3', description: '(На поддоне по 30 шт.)' },
      { image: '/img/fertilizer-3.png', title: 'Биг-бэг - 3,0 м3', description: '(На поддоне по 30 шт.)' },
      { image: '', title: '', description: '(На поддоне по 30 шт.)' },
    ],
    contact: {
      title: 'Заполните форму справа, чтобы сделать заказ',
      description: 'Наш менеджер свяжется с вами в течении 10 минут',
    },
    order: {
      title: 'Введите необходимое колличество:',
      description: 'Выберите упаковку:',
      placeholder: 'Введите количество',
    },
    buttonText: 'Заказать',
    ContactForm: {
      title: 'Наш менеджер свяжется с вами',
      description: 'Нужна консультация?',
    },
  },
  '/deicing-reagents': {
    title: 'Противогололедные реагенты',
    description: '',
    downloadLink: '/',
    downloadText: 'Скачать каталог',
    infoText: `Противогололедные реагенты (ПГР) – твердые (сыпучие) или жидкие
              (растворы) химические искусственные средства, распределяемые по
              поверхности дорожного покрытия для борьбы с зимней скользкостью и
              направленные на поддержание в допустимом состоянии элементов
              объектов дорожного хозяйства в процессе их эксплуатации в зимний
              период.
              <br />
              <br />
              Вещества (соли или растворы), использующиеся в качестве
              противогололедных реагентов имеют сложный химический состав,
              зависящий от технологии производства и сырьевой базы. По условиям
              водоотведения, в городе номенклатура ПГР ограничивается только ее
              хлоридной группой.
              <br />
              <br />
              Принцип использования противогололедных реагентов основан на том,
              что температура замерзания воды, с разведенными в ней хлоридами
              солей ниже, чем температура замерзания естественных осадков. По
              этому принципу работает большинство реагентов, в основе которых
              присутствуют солевые растворы химических элементов, в том или ином
              процентном соотношении, подобранные таким образом, чтобы
              воздействие на окружающую среду не причиняло вреда.
              <br />
              <br />
              Средства против обледенения можно подразделить на естественные
              природные (обычный строительный песок, мелкий гранитный щебень,
              или как его еще называют – гранитная крошка) и искусственные
              (противогололедные реагенты, полученные химическим путем в
              лабораторных условиях хлорид кальция, хлорид натрия, хлорид магния
              и др.).`,
    SectionTitle: { description: 'Надзаголовок', title: 'Заголовок' },
    cards: [
      {
        title: 'Надежные поставщики',
        description: 'Мы работаем с 20 проверенными поставщиками',
        icon: '/img/icons/advantage1.svg',
      },
      {
        title: 'Большой ассортимент',
        description: 'Предлагаем более 10 000 м3 природного камня в наличии',
        icon: '/img/icons/advantage2.svg',
      },
      {
        title: 'У нас большой опыт',
        description: 'Продаем камень 12 лет',
        icon: '/img/icons/advantage3.svg',
      },
      {
        title: 'Подробная информация',
        description: 'Проводим консультации нашим клиентами',
        icon: '/img/icons/advantage4.svg',
      },
    ],
    ContactForm: {
      title: 'Наш менеджер свяжется с вами',
      description: 'Нужна консультация?',
    },
  },
  '/services': {
    title: 'Услуги по ландшафтному дизайну и благоустройству территорий',
    description: '',
    title1: `Услуги по ландшафтному дизайну <br /> и благоустройству территорий`,
    infoText: `Разнообразный и богатый опыт рамки и место обучения кадров требуют от
          нас анализа позиций, занимаемых участниками в отношении поставленных
          задач. Идейные соображения высшего порядка, а также дальнейшее
          развитие различных форм деятельности требуют от нас анализа позиций,
          занимаемых участниками в отношении поставленных задач. Задача
          организации, в особенности же дальнейшее развитие различных форм
          деятельности в значительной степени обуславливает создание направлений
          прогрессивного развития.`,
    SectionTitle: { description: 'Надзаголовок', title: 'Заголовок' },
    cards: [
      {
        title: 'Надежные поставщики',
        description: 'Мы работаем с 20 проверенными поставщиками',
        icon: '/img/icons/advantage1.svg',
      },
      {
        title: 'Большой ассортимент',
        description: 'Предлагаем более 10 000 м3 природного камня в наличии',
        icon: '/img/icons/advantage2.svg',
      },
      {
        title: 'У нас большой опыт',
        description: 'Продаем камень 12 лет',
        icon: '/img/icons/advantage3.svg',
      },
      {
        title: 'Подробная информация',
        description: 'Проводим консультации нашим клиентами',
        icon: '/img/icons/advantage4.svg',
      },
    ],

    SectionTitle2: { description: 'Надзаголовок', title: 'Заголовок' },
    services: [
      {
        img: '/img/services-preview-1.webp',
        name: 'Укладка и посев газона',
        link: '/services/sowing-the-lawn',
      },
      {
        img: '/img/services-preview-2.webp',
        name: 'Работа с камнем',
        link: '/services/working-with-stone',
      },
      {
        img: '/img/services-preview-3.webp',
        name: 'Благоустройство и озеленение',
        link: '/services/beautification',
      },
    ],
    FinishedProjectsSection: {
      title: 'Завершенные проекты',
      description: 'За годы работы выполнили множество проектов различной сложности',
    },
    ContactForm: {
      title: 'Наш менеджер свяжется с вами',
      description: 'Нужна консультация?',
    },
  },
};
