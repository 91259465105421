import React, { useState, useRef, useEffect, useCallback } from 'react';
import { Row, Button, notification, Typography, Input } from 'antd';
import { useNavigate } from 'react-router-dom';
import { saveContent, getContent } from '../../Api';
import './PagesContent.scss';
import { content as mockContent } from './mockdata';

const { Title } = Typography;

export default function PagesContent() {
  // console.log(JSON.stringify(mockContent));
  const [content, setContent] = useState({});
  // const [content, setContent] = useState(mockContent);

  const getData = useCallback(async () => {
    const data = await getContent();
    if (data) setContent(data);
  }, []);

  useEffect(() => {
    getData();
  }, [getData]);

  const navigate = useNavigate();

  const openNotificationWithIcon = useCallback((type) => {
    notification[type]({
      message: type,
      description: type === 'success' ? 'Everything OK' : 'Error when making a change',
    });

    if (type === 'success' && headerRef?.current) {
      headerRef.current.scrollIntoView();
    }
  }, []);

  const headerRef = useRef(null);

  const [selected, setSelected] = useState('');

  return (
    <>
      <div className="header-cont" ref={headerRef}>
        <h1>СОДЕРЖИМОЕ СТРАНИЦ</h1>
        <Button onClick={() => navigate(-1)}>Назад</Button>
      </div>
      <div className="Product" style={{ width: 'auto', margin: 0, padding: '20px 0' }}>
        <form
          noValidate={false}
          onSubmit={(e) => {
            e.preventDefault();
            const data = { ...content };
            saveContent(data, openNotificationWithIcon);
          }}
        >
          <div className="container">
            <Row style={{ gap: '6px' }}>
              {Object.keys(content).map((key, i) => (
                <Button
                  type={selected === key ? 'primary' : 'default'}
                  key={i}
                  onClick={() => setSelected(key)}
                >
                  {key}
                </Button>
              ))}
            </Row>

            <div>
              {content[selected] &&
                Object.keys(content[selected]).map((nestedKey) => {
                  if (typeof content[selected][nestedKey] === 'object') {
                    return (
                      <div key={nestedKey} className="row">
                        <Title level={3}>{nestedKey}</Title>
                        {Object.keys(content[selected][nestedKey]).map((key) => {
                          const el = content[selected][nestedKey][key];
                          return (
                            <React.Fragment key={key}>
                              <div
                                style={{
                                  display: 'flex',
                                  alignItems: 'center',
                                  gap: '1rem',
                                }}
                              >
                                {key}:
                                {typeof el === 'string' && (
                                  <Input
                                    aria-label={`${key} input`}
                                    value={el}
                                    onChange={(e) =>
                                      setContent((prev) => {
                                        const newState = { ...prev };
                                        newState[selected][nestedKey][key] = e.target.value;
                                        return newState;
                                      })
                                    }
                                  />
                                )}
                                {typeof el === 'boolean' && (
                                  <input
                                    type="checkbox"
                                    checked={el}
                                    className="checkbox1"
                                    onChange={(e) =>
                                      setContent((prev) => {
                                        const newState = { ...prev };
                                        newState[selected][nestedKey][key] = e.target.checked;
                                        return newState;
                                      })
                                    }
                                    readOnly
                                  />
                                )}
                                {typeof el === 'object' && Array.isArray(el) && (
                                  <div
                                    style={{
                                      width: '100%',
                                      border: '1px dotted black',
                                      padding: '0 0.5rem',
                                    }}
                                  >
                                    {el.map((item, i) => {
                                      if (typeof item === 'string')
                                        return (
                                          <Input
                                            key={i}
                                            aria-label=""
                                            value={item}
                                            onChange={(e) =>
                                              setContent((prev) => {
                                                const newState = { ...prev };
                                                newState[selected][nestedKey][key][i] = e.target.value;
                                                return newState;
                                              })
                                            }
                                          />
                                        );

                                      if (typeof item === 'object' && !Array.isArray(item)) {
                                        return (
                                          <div style={{ borderBottom: '1px dotted grey' }}>
                                            {Object.keys(item).map((nestedKey2, j) => (
                                              <div key={j}>
                                                <div
                                                  style={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    gap: '1rem',
                                                  }}
                                                >
                                                  {nestedKey2}:
                                                  <Input
                                                    aria-label=""
                                                    onChange={(e) =>
                                                      setContent((prev) => {
                                                        const newState = { ...prev };
                                                        newState[selected][nestedKey][key][i][nestedKey2] =
                                                          e.target.value;
                                                        return newState;
                                                      })
                                                    }
                                                    value={item[nestedKey2]}
                                                  />
                                                </div>
                                              </div>
                                            ))}
                                          </div>
                                        );
                                      }

                                      return '??';
                                    })}
                                  </div>
                                )}
                                {typeof el === 'object' &&
                                  !Array.isArray(el) &&
                                  Object.keys(el).map((nk, j) =>
                                    typeof el[nk] === 'string' ? (
                                      <label>
                                        {nk}:
                                        <Input
                                          key={j}
                                          aria-label=""
                                          value={el[nk]}
                                          onChange={(e) =>
                                            setContent((prev) => {
                                              const newState = { ...prev };
                                              newState[selected][nestedKey][key][nk] = e.target.value;
                                              return newState;
                                            })
                                          }
                                        />
                                      </label>
                                    ) : Array.isArray(el[nk]) ? (
                                      <label>
                                        {nk}:
                                        {el[nk].map((item, k) => (
                                          <Input
                                            key={k}
                                            aria-label=""
                                            value={item}
                                            onChange={(e) =>
                                              setContent((prev) => {
                                                const newState = { ...prev };
                                                newState[selected][nestedKey][key][nk][k] = e.target.value;
                                                return newState;
                                              })
                                            }
                                          />
                                        ))}
                                      </label>
                                    ) : (
                                      '????'
                                    ),
                                  )}
                              </div>
                            </React.Fragment>
                          );
                        })}
                      </div>
                    );
                  }
                  if (typeof content[selected][nestedKey] === 'string') {
                    return (
                      <React.Fragment key={nestedKey}>
                        <div style={{ display: 'flex', alignItems: 'center', gap: '1rem' }}>
                          {nestedKey}:
                          <Input
                            aria-label=""
                            value={content[selected][nestedKey]}
                            onChange={(e) =>
                              setContent((prev) => {
                                const newState = { ...prev };
                                newState[selected][nestedKey] = e.target.value;
                                return newState;
                              })
                            }
                          />
                        </div>
                      </React.Fragment>
                    );
                  }
                  return '???';
                })}
            </div>
          </div>

          <div className="buttons" style={{ marginTop: '1em', gap: '1vmax' }}>
            <button
              onClick={(e) => {
                e.preventDefault();
                navigate(-1);
              }}
            >
              Отмена
            </button>
            <button
              onClick={() => {
                const isReset = window.confirm('Сбросить все данные на значения по умолчанию?');
                if (isReset) setContent(mockContent);
              }}
            >
              Сбросить
            </button>

            <button type="submit" disabled={false}>
              Сохранить
            </button>
          </div>
        </form>
      </div>
    </>
  );
}
