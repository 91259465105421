import React, { useState, useEffect } from 'react';
import imginp from '../../../img/imageInp.svg';
import './AddColor.scss';
import { addColor } from '../../../Api';
import { useNavigate } from 'react-router-dom';
import { CloseOutlined } from '@ant-design/icons';

import { Button, Image, notification } from 'antd';

export default function AddColor() {
  const [name, setName] = useState('');
  const [nameDirty, setNameDirty] = useState(false);
  const [nameError, setNameError] = useState('fill in the field');
  const [formValid, setFormValid] = useState(false);
  const [img, setImg] = useState('');
  const [data, setData] = useState('');
  const navigate = useNavigate();

  const uploadImage = async (e) => {
    const file = e.target.files[0];
    const base64 = await convertBase64(file);
    setImg(base64);
  };

  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);

      fileReader.onload = () => {
        resolve(fileReader.result);
      };

      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  useEffect(() => {
    if (nameError) {
      setFormValid(false);
    } else {
      setFormValid(true);
    }
  }, [nameError]);

  const BlurHandler = (e) => {
    switch (e.target.name) {
      case 'name':
        setNameDirty(true);
        break;
    }
  };

  const nameHandler = (e) => {
    setName(e.target.value);
    if (e.target.value.length === 0) {
      setNameError('fill in the field');
    } else {
      setNameError('');
    }
  };

  useEffect(() => {
    setData({ name, image: img });
  }, [name, img]);

  const openNotificationWithIcon = (type) => {
    notification[type]({
      message: type,
      description: type === 'success' ? 'Color has added successfully' : 'error while adding Color',
    });
  };

  return (
    <>
      <div className="header-cont">
        <h1>Create Color</h1>
        <Button
          onClick={() => {
            navigate(-1);
          }}
        >
          Назад
        </Button>
      </div>
      <div className="Product">
        <form>
          <div className="err-wrap">
            {nameDirty && nameError && <p style={{ color: 'red' }}>{nameError}</p>}
          </div>
          <input
            className="Name"
            placeholder="Цвет"
            value={name}
            onBlur={(e) => BlurHandler(e)}
            name="name"
            onChange={(e) => nameHandler(e)}
            type={'text'}
          />
          <div className="product-img">
            <h2>Фото цвета</h2>
            {img ? (
              <CloseOutlined
                onClick={() => {
                  setImg('');
                }}
                className="deletFoto"
              />
            ) : (
              ''
            )}
            <Image src={img} alt="" />
          </div>
          <label className="Image" htmlFor="file">
            <img src={imginp} alt="" /> Загрузить фото
          </label>
          <input
            id="file"
            type="file"
            onChange={(e) => {
              uploadImage(e);
            }}
          />
          <input
            onClick={(e) => {
              e.preventDefault();
              addColor(data, openNotificationWithIcon);
            }}
            disabled={!formValid}
            className="Submit"
            value={'Добавить'}
            type={'submit'}
          />
        </form>
      </div>
    </>
  );
}
