import React, { useEffect, useState } from 'react';
import { Button, notification } from 'antd';
import ModalUsers from '../../Components/SubCategoryBranches/ModalSubCategoryBranches/Modal';
import './SubCategoryBranches.scss';
import TableComp from '../../Components/Table/TableComp';
import ModalDelet from '../../Components/SubCategoryBranches/ModalDeletSubBranchesCategory/ModalDelet';
import ButtonEdit from '../../Components/ButtonEdit/ButtonEdit';
import { Link } from 'react-router-dom';
import { getSubCategoryBranches } from '../../Api';
import { Loader } from '../../Components/Loader/Loader';

const SubCategoryBranches = () => {
  const [subCategoriesBranches, setSubCategoriesBranches] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const openNotificationWithIcon = (type) => {
    notification[type]({
      message: type,
      description:
        type === 'success'
          ? 'SubCategoryBranches deleted successfully'
          : 'SubCategoryBranches deletion error',
    });
  };

  const getSubCategoryBranchesData = async () => {
    setIsLoading(true);
    const data = await getSubCategoryBranches();
    const newCategory = reformidUserDataForTable(data);
    setSubCategoriesBranches(newCategory);
    setIsLoading(false);
  };

  const reformidUserDataForTable = (data) => {
    if (!data?.length) return [];
    return data.map((i, index) => ({
      ...i,
      id: index + 1,
      Название: i.name,
      'Sub Category ID': i.subCategoryId._id,
      'Товары и услуги': i.subCategoryId.name,
      Действия: (
        <React.Fragment key={index}>
          <ModalDelet
            deleteInfo={openNotificationWithIcon}
            deleteData={getSubCategoryBranchesData}
            rowId={i._id}
          />
          <ButtonEdit itemPath="/edit-subCategoryBranches" itemId={i._id} />
          <ModalUsers subCategoryBranches={i} />
        </React.Fragment>
      ),
    }));
  };

  useEffect(() => {
    getSubCategoryBranchesData();
  }, []);

  if (isLoading) return <Loader />;

  return (
    <>
      <div className="header-cont">
        <h2>Типы товара (применение)</h2>
        <Button>
          <Link to={'/add-subCategoryBranches'}>Добавить</Link>
        </Button>
      </div>
      <TableComp
        items={[{ title: 'id' }, { title: 'Название' }, { title: 'Товары и услуги' }, { title: 'Действия' }]}
        dataSource={subCategoriesBranches}
      />
    </>
  );
};

export default SubCategoryBranches;
