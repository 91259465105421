import React, { useState, useEffect } from 'react';
import imginp from '../../../img/imageInp.svg';
import { CloseOutlined } from '@ant-design/icons';

import './AddMaterials.scss';
import { addMaterial } from '../../../Api';
import { useNavigate } from 'react-router-dom';
import { Button, Image, notification } from 'antd';

export default function AddMaterials() {
  const [name, setName] = useState('');
  const [img, setImg] = useState('');
  const [data, setData] = useState('');
  const navigate = useNavigate();

  const uploadImage = async (e) => {
    const file = e.target.files[0];
    const base64 = await convertBase64(file);
    setImg(base64);
  };

  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);

      fileReader.onload = () => {
        resolve(fileReader.result);
      };

      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  useEffect(() => {
    setData({ name, image: img });
  }, [name, img]);

  const openNotificationWithIcon = (type) => {
    notification[type]({
      message: type,
      description: type === 'success' ? 'Materials has added successfully' : 'error while adding Materials',
    });
  };

  return (
    <>
      <div className="header-cont">
        <h1>Добавить материал</h1>
        <Button onClick={() => navigate(-1)}>Назад</Button>
      </div>
      <div className="Product">
        <form>
          <input
            type="text"
            placeholder="Название"
            onChange={(e) => setName(e.target.value)}
            value={name || ''}
          />
          <div className="product-img" style={{ marginTop: '1rem' }}>
            <h2>Фото</h2>
            {img ? (
              <CloseOutlined
                onClick={() => {
                  setImg('');
                }}
                className="deletFoto"
              />
            ) : (
              ''
            )}
            <Image src={img} alt="" />
          </div>
          <label className="Image" htmlFor="file">
            <img src={imginp} alt="" /> Загрузить фото
          </label>
          <input
            id="file"
            type="file"
            onChange={(e) => {
              uploadImage(e);
            }}
          />
          <input
            onClick={(e) => {
              e.preventDefault();
              addMaterial(data, openNotificationWithIcon);
            }}
            className="Submit"
            value={'Добавить'}
            type={'submit'}
          />
        </form>
      </div>
    </>
  );
}
