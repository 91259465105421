import React, { useState, useEffect } from 'react';
import { CloseOutlined } from '@ant-design/icons';
import './EditCompletedProject.scss';
import { Button, notification, Image } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import {
  getCompletedProject,
  editCompletedProject,
  getCategory,
  getCategories,
  getSections,
} from '../../../Api';
import imginp from '../../../img/imageInp.svg';
import SelectOption from '../../SelectOption/SelectOption';

export default function EditCompletedProject() {
  const [name, setName] = useState('');
  const [sectionId, setSectionId] = useState('');
  const [categoryId, setCategoryId] = useState('');
  const [categories, setCategroies] = useState([]);
  const [sections, setSections] = useState([]);
  const [parametr1, setParam1] = useState('');
  const [parametr2, setParam2] = useState('');
  const [info, setInfo] = useState('');
  const [data, setData] = useState();
  const [img, setImg] = useState('');
  const { id } = useParams();
  const completedProjectId = id.replaceAll(':', '');

  useEffect(() => {
    getCompletedProjectData();
    getCategoryData();
  }, [completedProjectId]);

  const getCompletedProjectData = async () => {
    const completedProjects = await getCompletedProject(completedProjectId);
    addCompletedProjectState(completedProjects);
  };

  const addCompletedProjectState = (completedProjects) => {
    setSectionId(completedProjects.sectionId);
    setCategoryId(completedProjects.categoryId);
    setName(completedProjects.name);
    setParam1(completedProjects.parametr1);
    setParam2(completedProjects.parametr2);
    setInfo(completedProjects.info);
    setImg(completedProjects.image);
  };

  const getCategoryData = async () => {
    const data = await getCategories();
    const data2 = await getSections();
    reformedRoletDataForTable(data, data2);
  };
  const reformedRoletDataForTable = (data, data2) => {
    setCategroies(data);
    setSections(data2);
  };

  const uploadImage = async (e) => {
    const file = e.target.files[0];
    const base64 = await convertBase64(file);
    setImg(base64);
  };

  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);

      fileReader.onload = () => {
        resolve(fileReader.result);
      };

      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  useEffect(() => {
    setData({
      name: name,
      sectionId: sectionId,
      categoryId: categoryId,
      parametr1: parametr1,
      parametr2: parametr2,
      info: info,
      image: img,
    });
  }, [name, sectionId, categoryId, parametr1, parametr2, info, img]);
  const navigate = useNavigate();
  const openNotificationWithIcon = (type) => {
    notification[type]({
      message: type,
      description: type === 'success' ? 'changes entered successfully' : 'error when making a change',
    });
  };

  return (
    <>
      <div className="header-cont">
        <h1>Изменить</h1>
        <Button
          onClick={() => {
            navigate(-1);
          }}
        >
          Назад
        </Button>
      </div>
      <div className="Product">
        <form>
          <div className="name-surname">
            <input
              type="text"
              placeholder="Название"
              onChange={(e) => setName(e.target.value)}
              value={name || ''}
            />
            <div className="roles">
              <SelectOption
                data={categories}
                placeholder="Раздел"
                setId={setCategoryId}
                currentId={categoryId}
              />

              <SelectOption
                data={sections}
                placeholder="Section"
                setId={setSectionId}
                currentId={sectionId}
              />
            </div>
            <input
              type="text"
              placeholder="Параметр 1"
              onChange={(e) => setParam1(e.target.value)}
              value={parametr1 || ''}
            />
            <input
              type="text"
              placeholder="Параметр 2"
              onChange={(e) => setParam2(e.target.value)}
              value={parametr2 || ''}
            />
          </div>
          <div className="product-img">
            <h2>Фото</h2>
            {img ? (
              <CloseOutlined
                onClick={() => {
                  setImg('');
                }}
                className="deletFoto"
              />
            ) : (
              ''
            )}
            <Image src={img.replace('188.225.36.154:3500', 'апи.камень-газон.рф')} alt="" />
          </div>
          <label className="Image" htmlFor="file">
            <img src={imginp} alt="" /> Загрузить фото
          </label>
          <input
            id="file"
            type="file"
            onChange={(e) => {
              uploadImage(e);
            }}
          />

          <div className="buttons">
            <button
              onClick={(e) => {
                e.preventDefault();
                navigate(-1);
              }}
            >
              Отмена
            </button>
            <button
              onClick={(e) => {
                e.preventDefault();
                editCompletedProject(completedProjectId, data, openNotificationWithIcon);
              }}
            >
              Сохранить
            </button>
          </div>
        </form>
      </div>
    </>
  );
}
